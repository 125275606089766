import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {getCopyAPi} from 'services/PageCopy';

function TermsOfServices({discard}) {
  const [terms, setTerms] = useState('');
  const [initial, setInitial] = useState('');

  React.useEffect(() => {
    const dat = new Date();
    axios
      .get('https://shotwot-test.b-cdn.net/app/Terms.txt?time=' + dat)
      .then(res => {
        setTerms(res);
        console.log(res);
        setInitial(res);
      });
  }, []);

  useEffect(() => {
    setTerms(initial);
  }, [discard]);

  return (
    <div
      style={{
        width: '985px',
        display: 'flex',
      }}>
      <textarea
        value={terms}
        id="terms"
        style={{
          height: '100%',
          width: '100%',
          borderWidth: 0,
          minHeight: '640px',
          backgroundColor: 'rgba(200, 200, 200, 0.33)',
          color: '#736B80',
          fontSize: 14,
          padding: '5px 38px',
        }}
        onChange={e => setTerms(e.target.value)}
      />
    </div>
  );
}

export default TermsOfServices;
