import {ArrowCircleLeftOutlined} from '@mui/icons-material';
import {Action} from 'component/Action';
import {HeadLabel} from 'component/HeadLabel';
import AssetForm from 'component/forms/comman/AssetForm';
import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {NavConstant} from 'routes/NavConstant';
import {updateActiveBriefAPI} from 'services/Brief';
import {fetchAssets, fetchBriefAssets} from 'store/slice/assetSlice';
import {clearBriefs, updateBrief} from 'store/slice/briefSlice';
import {dispatch, useSelector} from 'store/store';
import {IconButton, Input, InputAdornment, Pagination} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import {GridSearchIcon} from '@mui/x-data-grid';
import {Edit} from 'component/Edit';
import {countAllAssetsApi} from 'services/Asset';
import {
  exportWithdrawsApi,
  getWithdrawsApi,
  postWithdrawsApi,
} from 'services/WithDraws';
import ReactModal from 'react-modal';
import HeadingComp from 'component/HeadingComp';
import {RHFInputField} from 'component/forms';
import {FormProvider, useForm} from 'react-hook-form';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {toast} from 'react-toastify';
import {dateComparator} from 'helper/comparator';
import {fetchWithdraws, updateWithdraw} from 'store/slice/withdrawSlice';

const WithDrawal = () => {
  const [isLoading, setIsLoading] = useState({});
  const [current, setCurrent] = useState();

  const methods = useForm({});
  const [modalIsOpen, setIsOpen] = React.useState(false);

  // Column Definitions: Defines & controls grid columns.
  // @ts-ignore
  const [colDefs, setColDefs] = useState([
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
      // renderCell: params =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      field: 'Date',
      headerClass: 'super-app-theme--header',
      headerName: 'Date',
      // cellRenderer: LinkComponent,
      width: 120,
      valueGetter: row => {
        const date = new Date(row?.data?.created);
        return date?.toLocaleDateString('en-GB');
      },
      comparator: dateComparator,
    },
    {
      field: 'Date',
      headerClass: 'super-app-theme--header',
      headerName: 'Time',
      // cellRenderer: LinkComponent,
      width: 120,
      valueGetter: row => {
        const date = new Date(row?.data?.created);
        return date?.toLocaleTimeString('en-GB');
      },
    },
    {
      field: 'Date',
      headerClass: 'super-app-theme--header',
      headerName: 'ShooterName',
      // cellRenderer: LinkComponent,
      width: 150,
      valueGetter: row => {
        return row?.data?.user?.firstname + row?.data?.user?.lastname;
      },
    },
    {
      field: 'Date',
      headerClass: 'super-app-theme--header',
      headerName: 'EmailId Or Phone',
      // cellRenderer: LinkComponent,
      width: 200,
      valueGetter: row => {
        return row?.data?.user?.email || row?.data?.user?.mobile;
      },
    },
    {
      field: 'Date',
      headerClass: 'super-app-theme--header',
      headerName: 'Payment Mode',
      // cellRenderer: LinkComponent,
      width: 120,
      valueGetter: row => {
        return row?.data?.mode;
      },
    },
    {
      field: 'Date',
      headerClass: 'super-app-theme--header',
      headerName: 'Current Balance',
      // cellRenderer: LinkComponent,
      width: 120,
      valueGetter: row => {
        return row?.data?.total?.coins;
      },
    },
    {
      field: 'amount',
      headerClass: 'super-app-theme--header',
      headerName: 'Amount Requested',
      // cellRenderer: LinkComponent,
      width: 120,
    },
    {
      field: 'Date',
      headerClass: 'super-app-theme--header',
      headerName: 'Status',
      // cellRenderer: LinkComponent,
      width: 140,
      valueGetter: row => {
        switch (row?.data?.status) {
          case 0:
            return 'Requested';
          case 1:
            console.log(12);
            return 'Rejected';
          case 2:
            return 'Approved';
          default:
            break;
        }
        return row?.data?.status;
      },
    },
    {
      field: 'requested',
      headerClass: 'super-app-theme--header',
      headerName: 'Action',
      cellRenderer: Action,
      width: 200,
      onCellClicked: event => {
        setIsOpen(true);
        setCurrent(event?.data);
        methods?.reset(event?.data);
      },
    },
  ]);

  // @ts-ignore
  const [requests, setRequests] = useState([]);
  const {withdraws} = useSelector(state => state.withdraw);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchWithdraws());
    return () => {};
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const gridRef = useRef();
  const navigate = useNavigate();

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: '45%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: '0px',
      padding: -20,
      // backdropFilter: 'blur(1000px)',

      overlay: {
        backdropFilter: 'blur(10000px)',
      },
    },
  };

  const Helper = ({i, j}) => {
    return (
      <div
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          display: 'flex',
        }}>
        <p
          style={{
            width: 250,
          }}>
          {i}
        </p>
        <RHFInputField
          name={j}
          // @ts-ignore
          register={methods.register}
          label="assetTitle"
          width="380px"
          placeholder="Enter Title here..."
        />
      </div>
    );
  };

  const Helper2 = ({i, j}) => {
    console.log(methods?.getValues('user'));
    return (
      <div
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          display: 'flex',
        }}>
        <p
          style={{
            width: 250,
          }}>
          {i}
        </p>
        <RHFInputField
          name={j}
          // @ts-ignore
          register={methods.register}
          label="assetTitle"
          width="320px"
          placeholder="Enter Title here..."
        />
      </div>
    );
  };

  const downloadFile = async () => {
    var res;
    try {
      res = await exportWithdrawsApi({});
      console.log(res);
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Shotwot_Withdraws.csv');
      // document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.log(error);
    }
  };

  console.log(current);

  return (
    <DashboardLayout>
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}>
        <div
          style={{
            display: 'flex',
            borderRadius: '6px',
            minHeight: 681,
            padding: '24px',
            backgroundColor: 'rgba(200, 200, 200, 0.33)',
            boxShadow: '1px 2px 3px #00000040',
            marginTop: 20,
            width: 1202,
            flexDirection: 'column',
          }}>
          <FormProvider {...methods}>
            <form
              onSubmit={() => {}}
              style={{
                flexDirection: 'row',
                display: 'flex',
                gap: 20,
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') e.preventDefault();
              }}>
              <div
                style={{
                  width: 600,
                  justifyContent: 'center',
                  //    backgroundColor:"red"
                }}>
                <HeadingComp label={'Withdrawal Details'} fontSize="20px" />
                <div
                  style={{
                    width: 600,
                    justifyContent: 'center',
                    // backgroundColor: 'red',
                    gap: 10,
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: 500,
                      color: 'black',
                      padding: '10px 0',
                    }}>
                    Transaction Details
                  </p>
                  {current?.mode?.includes('Bank') ? (
                    <Helper i={'Account Holder Name'} j={'name'} />
                  ) : null}
                  {current?.mode?.includes('Bank') ? (
                    <Helper i={'Account Number'} j={'account'} />
                  ) : null}
                  {current?.mode?.includes('Bank') ? (
                    <Helper i={'IFSC'} j={'ifsc'} />
                  ) : null}

                  <Helper i={'PAN'} j={'pan'} />
                  <Helper i={'Mobile Number'} j={'user.mobile'} />
                  {current?.mode?.includes('Bank') ? (
                    <Helper i={'Payment Mode'} j={'paymentmode'} />
                  ) : (
                    <Helper i={'Payment Mode'} j={'mode'} />
                  )}
                  {current?.mode?.includes('Bank') ? null : (
                    <Helper i={'UPI ID'} j={'upi'} />
                  )}
                  <Helper i={'Transaction ID'} j={'id'} />
                  <Helper i={'GSTN'} j={'gst'} />
                  <Helper i={'Withdrawal Amount'} j={'amount'} />
                </div>
              </div>
              <div
                style={{
                  width: 600,
                  justifyContent: 'center',
                  //    backgroundColor:"red"
                }}>
                <HeadingComp label={''} fontSize="20px" />
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    gap: 10,
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: 500,
                      color: 'black',
                      padding: '10px 0',
                    }}>
                    Shooter Details
                  </p>
                  <Helper2
                    i={'Shooter Name'}
                    j={current?.mode?.includes('Bank') ? 'name' : 'full_name'}
                  />
                  <Helper2 i={'Shooter Address'} j={'location'} />
                  <Helper2 i={'Shooter Email'} j={'user.email'} />
                  <Helper2 i={'Current Wallet Balance'} j={'total.coins'} />
                </div>
              </div>
            </form>
          </FormProvider>
          {current?.status > 0 ? (
            <p
              style={{
                position: 'absolute',
                bottom: '10%',
                fontSize: 16,
                display: 'flex',
                right: '10%',
                color: current?.status == 1 ? '#B50000' : 'Green',
                fontWeight: '600',
              }}>
              {current?.status == 1
                ? 'This withdrawal request was Rejected'
                : 'This withdrawal request was Accepted'}
            </p>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '30px',
                flexDirection: 'row',
                gap: 12,
              }}>
              <SecondaryButton
                buttonLabel={'Reject'}
                onClick={e => {
                  setCurrent({...current, status: 1});

                  postWithdrawsApi({...current, status: 1}).then(r => {
                    closeModal();
                    dispatch(updateWithdraw({...current, status: 1}));
                    toast.success('updated successfully');
                  });
                }}
                // @ts-ignore
                type="button"
                color="white"
                labelColor="red"
              />
              <SecondaryButton
                // isLoading={loading}
                buttonLabel={'Approve'}
                onClick={e => {
                  setCurrent({...current, status: 2});

                  postWithdrawsApi({
                    ...current,
                    status: 2,
                    amount: parseInt(methods.getValues('amount')),
                  }).then(r => {
                    closeModal();
                    dispatch(
                      updateWithdraw({
                        ...current,
                        status: 2,
                        amount: parseInt(methods.getValues('amount')),
                      }),
                    );
                    toast.success('updated successfully');
                  });
                }}
                color="#7729E5"
                labelColor="white"
                // @ts-ignore
                type="submit"
              />
            </div>
          )}
        </div>
      </ReactModal>
      <div>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 15,
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
            width: 1419,
          }}>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              gap: 15,
              alignItems: 'center',
            }}>
            <ArrowCircleLeftOutlined
              onClick={() => navigate(-1)}
              style={{
                // marginLeft: 20,
                cursor: 'pointer',
              }}
            />
            <HeadLabel label={'Withdrawal Requests'} />
          </div>
          <SecondaryButton
            buttonLabel={'Export'}
            onClick={downloadFile}
            // @ts-ignore
            type="submit"
          />

          {/* <ViewAllButton /> */}
        </div>

        <div
          style={{
            marginTop: '10px',
            width: 1419,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: 10,
              width: 1419,
            }}>
            {/* <p>{(page - 1) * 5 + ' to ' + page * 5 + ' of ' + total}</p> */}
          </div>
          <div
            className={'ag-theme-quartz'}
            style={{width: '1419px', height: '150vh'}}>
            <AgGridReact
              ref={gridRef}
              defaultColDef={{...defaultColDef}}
              //   suppressPaginationPanel={true}
              readOnlyEdit={true}
              getRowId={params => params.data.id}
              //   onCellEditRequest={onCellEditRequest}
              rowData={withdraws}
              columnDefs={colDefs}
              pagination={true}
              paginationPageSize={50}
              rowSelection="multiple"
              scrollbarWidth={9}
              alwaysShowHorizontalScroll={true}
              alwaysShowVerticalScroll={true}
            />
          </div>
          {/* <Pagination
            count={parseInt(total / 5 + '') + 1}
            shape="rounded"
            onChange={(event, p) => {
              console.log(p);
              setPage(p);
            }}
          /> */}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default WithDrawal;
