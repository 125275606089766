import * as React from 'react';
import {useInput} from '@mui/base/useInput';
import {styled} from '@mui/system';
import {unstable_useForkRef as useForkRef} from '@mui/utils';

// @ts-ignore
export const CustomInput = React.forwardRef(function CustomInput(
  {errorMessage, width = '320px', ...props},
  ref,
) {
  // @ts-ignore,
  const {getRootProps, getInputProps} = useInput(props);

  const inputProps = getInputProps();

  // Make sure that both the forwarded ref and the ref returned from the getInputProps are applied on the input element
  inputProps.ref = useForkRef(inputProps.ref, ref);

  return (
    <div {...getRootProps()}>
      {errorMessage && (
        <p style={{color: '#FC3CB2', marginLeft: 4, fontSize: '12px'}}>
          {errorMessage}
        </p>
      )}
      <StyledInputElement
        {...props}
        {...inputProps}
        style={{
          marginBottom: 20,
          width,
          color: 'black',
        }}
      />
    </div>
  );
});

const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const StyledInputElement = styled('input')(
  ({theme}) => `
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? 'black' : '#B1B1B1'};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? '#B1B1B1' : '#B1B1B1'};
    box-shadow: 0px 2px 4px ${
      theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
    };
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 1px ${
        theme.palette.mode === 'dark' ? blue[600] : blue[200]
      };
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);
