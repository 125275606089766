import React, {useState, useRef, useEffect} from 'react';
import Upload from '../../assets/upload';
import Terms from '../../component/terms';
import Logo from '../../component/logo/logo';
import './temp.scss';
import FileUpload from './FileUpload';
import ThumbsUp from '../../assets/thumbsUp';
import TrackProgress from './TrackProgress';

import Button from '@mui/material/Button';

const Temp = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [upload, setUpload] = useState(false);
  const inputFile = useRef(null);
  const [track, setTrack] = useState(0);
  const [size, setSize] = useState(0);

  const onChangeFile = async event => {
    event.stopPropagation();
    event.preventDefault();
    var files = event.target.files;
    if (files) setSelectedFiles(Array.from(files));
    setTrack(0);
    setUpload(false);
  };

  const onClick = () => {
    if (selectedFiles != null) setUpload(true);
  };

  useEffect(() => {
    let fileSize = 0;
    for (let i of selectedFiles) {
      fileSize += i?.size;
    }
    setSize(fileSize);
    return () => {};
  }, [selectedFiles, track]);

  return !upload ? (
    <div style={{marginTop: 200}}>
      <div className="temp">
        <Logo />
        <div className="buttonContainer">
          <Button
            component="label"
            variant="contained"
            endIcon={<Upload />}
            sx={{boxShadow: 24, justifyContent: 'space-between'}}
            style={{
              backgroundColor: '#D9D9D9',
              color: '#949494',
              width: 800,
              fontFamily: 'Poppins',
            }}>
            {selectedFiles?.length > 0
              ? `${selectedFiles?.length} files added`
              : 'Select files to upload'}
            <input
              type="file"
              className="uploadFile"
              id="upload"
              ref={inputFile}
              onChange={onChangeFile}
              multiple="multiple"
            />
          </Button>
          <div style={{flexDirection: 'row', display: 'flex'}}>
            <Button
              component="label"
              variant="contained"
              sx={{boxShadow: 24}}
              style={{
                backgroundColor: '#FC3CB2',
                color: 'white',
                marginLeft: '80%',
                marginTop: '30px',
                paddingLeft: 50,
                paddingRight: 50,
                fontFamily: 'Poppins',
                fontSize: 18,
              }}
              onClick={onClick}
              disabled={upload || selectedFiles == null}>
              Upload
            </Button>
          </div>
        </div>
        <div
          style={{
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginTop: 150,
          }}>
          <Terms style={{justifyContent: 'flex-end'}} />
        </div>
      </div>
      {/* {track === selectedFiles?.length && track !== 0 && (
        <div className="modal">
          <div style={{marginBottom: 50}}>
            <ThumbsUp />
          </div>
          <p>Wohoo !!</p>
          <p>All files Uploaded Successfully</p>
          <Button
            component="label"
            variant="contained"
            sx={{boxShadow: 24}}
            style={{
              backgroundColor: '#D9D9D9',
              color: '#014155',
              fontFamily: 'Poppins',
              fontSize: 18,
              marginTop: 50,
            }}
            onClick={() => {
              window.location.reload(false);
            }}>
            {'Upload More'}
          </Button>
        </div>
      )} */}
    </div>
  ) : (
    <TrackProgress files={selectedFiles} totalSize={size} />
  );
};

export default Temp;
