import {AgGridReact} from 'ag-grid-react';
import {Action} from 'component/Action';
import {dateComparator} from 'helper/comparator';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {NavConstant} from 'routes/NavConstant';
import {getAllBriefApplicationsApi} from 'services/Brief';

function AllBriefApplication() {
  const navigate = useNavigate();
  const [colDefs, setColDefs] = useState([
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
    },
    {
      field: 'created',
      headerName: 'Date',
      width: 100,
      valueGetter: p => {
        const date = new Date(p?.data?.created);
        return date?.toLocaleDateString('en-GB');
      },
      comparator: dateComparator,

      // cellRenderer: LinkComponentType,
    },
    {
      field: 'Time',
      width: 100,
      valueGetter: p => {
        const time = new Date(p?.data?.created);
        return time?.toLocaleTimeString();
      },
      // cellRenderer: LinkComponentType,
    },
    {
      field: 'type',
      width: 100,
      valueGetter: p => p?.data?.brief?.type,
      // cellRenderer: LinkComponentType,
    },
    {
      field: 'Brief Title',
      width: 120,
      valueGetter: p => p?.data?.brief?.title,
      // cellRenderer: LinkComponentType,
    },
    {
      field: 'User Name',
      width: 120,
      valueGetter: p => p?.data?.user?.username,
      // cellRenderer: LinkComponentType,
    },
    {
      field: 'User (Email Or Mobile)',
      width: 180,
      valueGetter: p => p?.data?.user?.email || p?.data?.user?.mobile,
      // cellRenderer: LinkComponentType,
    },
    {
      field: 'expiry',
      width: 110,
      valueGetter: p => {
        const date = new Date(p?.data?.brief?.expiry);
        return date?.toLocaleDateString('en-GB');
      },
    },
    {
      field: 'Status',
      width: 150,
      valueGetter: p => {
        switch (p?.data?.status) {
          case 1:
            if (p?.data?.opened) {
              return 'Read Application';
            }
            return 'Unread Application';
          case 2:
            return 'Shortlisted';
          case 3:
            return 'Rejected';
          case 4:
            return 'Accepted';
          case 5:
            if (p?.data?.submission_opened) return 'Unread Submission';
            return 'Read Submission';
          case 6:
            return 'Content Shortlisted';
          case 7:
            return 'Content Rejected';
          case 8:
            return 'Content Approved';
          default:
            return '';
        }
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 80,
      cellRenderer: Action,
      onCellClicked: event => {
        navigate(`${NavConstant.BRIEF_APPLICATION}/${event?.data?.id}`);
      },
      valueGetter: params => {
        return params?.data?.user?.pro;
      },
    },
  ]);

  const gridRef = useRef();

  const [data, setData] = useState([]);

  useEffect(() => {
    getAllBriefApplicationsApi().then(res => {
      setData(res?.data);
    });
  }, []);

  return (
    <div className={'ag-theme-quartz'} style={{width: '100%', height: '200vh'}}>
      <AgGridReact
        ref={gridRef}
        defaultColDef={{resizable: true, sortable: true, filter: true}}
        readOnlyEdit={true}
        getRowId={params => params.data.id}
        // onCellEditRequest={onCellEditRequest}
        onRowClicked={p => {
          // window.open(
          //   `${window.location.origin}/brief-application/${p?.data?.id}`,
          // );
        }}
        rowData={data || []}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={100}
        rowSelection="multiple"
        scrollbarWidth={9}
        alwaysShowHorizontalScroll={true}
        alwaysShowVerticalScroll={true}
      />
    </div>
  );
}

export default AllBriefApplication;
