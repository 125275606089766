import React from 'react';

export default function LogoImage({fillColor, width = '81', height = '70'}) {
  return (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 32.5C0 14.5507 14.5507 0 32.5 0C50.4493 0 65 14.5507 65 32.5C65 50.4493 50.4493 65 32.5 65C14.5507 65 0 50.4493 0 32.5Z"
        fill="#7729E5"
      />
      <path
        d="M53.4046 43.4811V46.1113H51.8265C50.7019 46.1113 49.8251 45.8392 49.1963 45.295C48.5674 44.7387 48.253 43.8378 48.253 42.5922V38.5653H47.0195V35.9894H48.253V33.5225H51.3549V35.9894H53.3865V38.5653H51.3549V42.6285C51.3549 42.9308 51.4274 43.1485 51.5726 43.2815C51.7177 43.4146 51.9595 43.4811 52.2981 43.4811H53.4046Z"
        fill="white"
      />
      <path
        d="M40.2769 42.4775C39.2853 42.4775 38.3904 42.2659 37.5923 41.8426C36.8062 41.4194 36.1834 40.8147 35.7239 40.0287C35.2765 39.2426 35.0527 38.3236 35.0527 37.2715C35.0527 36.2315 35.2825 35.3185 35.742 34.5324C36.2016 33.7343 36.8304 33.1236 37.6285 32.7003C38.4267 32.2771 39.3216 32.0654 40.3132 32.0654C41.3048 32.0654 42.1997 32.2771 42.9978 32.7003C43.796 33.1236 44.4248 33.7343 44.8844 34.5324C45.3439 35.3185 45.5737 36.2315 45.5737 37.2715C45.5737 38.3115 45.3379 39.2305 44.8662 40.0287C44.4067 40.8147 43.7718 41.4194 42.9616 41.8426C42.1634 42.2659 41.2685 42.4775 40.2769 42.4775ZM40.2769 39.7929C40.8695 39.7929 41.3713 39.5752 41.7825 39.1398C42.2058 38.7045 42.4174 38.0817 42.4174 37.2715C42.4174 36.4612 42.2118 35.8385 41.8006 35.4031C41.4016 34.9678 40.9058 34.7501 40.3132 34.7501C39.7085 34.7501 39.2067 34.9678 38.8076 35.4031C38.4085 35.8264 38.209 36.4492 38.209 37.2715C38.209 38.0817 38.4025 38.7045 38.7895 39.1398C39.1885 39.5752 39.6844 39.7929 40.2769 39.7929Z"
        fill="#FF7200"
      />
      <path
        d="M33.569 33.3779L30.2494 46.1119H26.4945L24.4629 37.7314L22.3587 46.1119H18.6038L15.375 33.3779H18.6945L20.5266 42.6472L22.7941 33.3779H26.2043L28.381 42.6472L30.2313 33.3779H33.569Z"
        fill="white"
      />
      <path
        d="M52.6527 29.4986V32.1289H51.0746C49.9499 32.1289 49.0732 31.8568 48.4443 31.3126C47.8155 30.7563 47.5011 29.8554 47.5011 28.6098V24.5828H46.2676V22.007H47.5011V19.54H50.6029V22.007H52.6346V24.5828H50.6029V28.6461C50.6029 28.9484 50.6755 29.1661 50.8206 29.2991C50.9657 29.4321 51.2076 29.4986 51.5462 29.4986H52.6527Z"
        fill="white"
      />
      <path
        d="M29.4601 21.8986C30.6211 21.8986 31.5522 22.2856 32.2536 23.0595C32.955 23.8214 33.3057 24.8735 33.3057 26.2158V32.1293H30.222V26.633C30.222 25.9558 30.0467 25.4298 29.696 25.0549C29.3453 24.68 28.8736 24.4926 28.2811 24.4926C27.6885 24.4926 27.2169 24.68 26.8662 25.0549C26.5155 25.4298 26.3401 25.9558 26.3401 26.633V32.1293H23.2383V18.7061H26.3401V23.3679C26.6546 22.9205 27.0839 22.5637 27.628 22.2977C28.1722 22.0316 28.7829 21.8986 29.4601 21.8986Z"
        fill="white"
      />
      <path
        d="M16.6851 32.2562C15.7539 32.2562 14.9195 32.105 14.1818 31.8027C13.4441 31.5004 12.8516 31.0529 12.4041 30.4604C11.9688 29.8678 11.739 29.1543 11.7148 28.3199H15.0162C15.0646 28.7915 15.2279 29.1543 15.506 29.4083C15.7841 29.6501 16.1469 29.7711 16.5944 29.7711C17.0539 29.7711 17.4167 29.6683 17.6828 29.4627C17.9488 29.245 18.0818 28.9488 18.0818 28.5739C18.0818 28.2594 17.973 27.9994 17.7553 27.7939C17.5497 27.5883 17.2897 27.419 16.9753 27.286C16.673 27.1529 16.2376 27.0018 15.6693 26.8325C14.8469 26.5785 14.1758 26.3246 13.6558 26.0706C13.1358 25.8167 12.6883 25.4418 12.3134 24.946C11.9386 24.4501 11.7511 23.8032 11.7511 23.005C11.7511 21.8199 12.1804 20.8948 13.039 20.2297C13.8976 19.5525 15.0162 19.2139 16.3948 19.2139C17.7976 19.2139 18.9283 19.5525 19.7869 20.2297C20.6455 20.8948 21.1051 21.826 21.1655 23.0232H17.8097C17.7855 22.612 17.6344 22.2915 17.3562 22.0618C17.0781 21.8199 16.7214 21.699 16.286 21.699C15.9111 21.699 15.6088 21.8018 15.379 22.0074C15.1493 22.2008 15.0344 22.485 15.0344 22.8599C15.0344 23.2711 15.2279 23.5915 15.6148 23.8213C16.0018 24.0511 16.6065 24.299 17.4288 24.565C18.2511 24.8432 18.9162 25.1092 19.4241 25.3632C19.9441 25.6171 20.3916 25.986 20.7665 26.4697C21.1414 26.9534 21.3288 27.5762 21.3288 28.3381C21.3288 29.0636 21.1414 29.7227 20.7665 30.3153C20.4037 30.9078 19.8716 31.3794 19.1702 31.7301C18.4688 32.0808 17.6404 32.2562 16.6851 32.2562Z"
        fill="white"
      />
      <g filter="url(#filter0_d_2862_4649)">
        <path
          d="M40.2574 33.7861C39.2658 33.7861 38.3709 33.5745 37.5727 33.1512C36.7867 32.728 36.1639 32.1233 35.7044 31.3373C35.2569 30.5512 35.0332 29.6322 35.0332 28.5801C35.0332 27.5401 35.263 26.627 35.7225 25.841C36.182 25.0429 36.8109 24.4322 37.609 24.0089C38.4072 23.5857 39.302 23.374 40.2937 23.374C41.2853 23.374 42.1802 23.5857 42.9783 24.0089C43.7765 24.4322 44.4053 25.0429 44.8648 25.841C45.3244 26.627 45.5541 27.5401 45.5541 28.5801C45.5541 29.6201 45.3183 30.5391 44.8467 31.3373C44.3872 32.1233 43.7523 32.728 42.942 33.1512C42.1439 33.5745 41.249 33.7861 40.2574 33.7861ZM40.2574 31.1015C40.8499 31.1015 41.3518 30.8838 41.763 30.4484C42.1862 30.0131 42.3979 29.3903 42.3979 28.5801C42.3979 27.7698 42.1923 27.147 41.7811 26.7117C41.382 26.2763 40.8862 26.0587 40.2937 26.0587C39.689 26.0587 39.1872 26.2763 38.7881 26.7117C38.389 27.135 38.1895 27.7577 38.1895 28.5801C38.1895 29.3903 38.383 30.0131 38.7699 30.4484C39.169 30.8838 39.6648 31.1015 40.2574 31.1015Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2862_4649"
          x="31.0332"
          y="23.374"
          width="18.5215"
          height="18.4121"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2862_4649"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2862_4649"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
