import React, {forwardRef} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import '../../../node_modules/react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

export function convertLocalToUTCDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
  return date;
}

export default function RHFDateTime({name, ...inputProps}) {
  const {control} = useFormContext();

  const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
    <button
      style={{
        width: '180px',
        height: '31px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        border: '1 solid black',
        borderRadius: 4,
      }}
      type="button"
      onClick={onClick}
      ref={ref}>
      {value || 'Select Date'}
    </button>
  ));

  return (
    <Controller
      defaultValue={''}
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => {
        return (
          <div style={{zIndex: 100}}>
            <DatePicker
              className="form-control form-control-solid w-180px "
              placeholderText="Select date"
              onChange={date => field.onChange(convertLocalToUTCDate(date))}
              selected={field.value}
              showIcon
              closeOnScroll={true}
              customInput={<ExampleCustomInput />}
            />
          </div>
        );
      }}
    />
  );
}
