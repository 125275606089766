import React from 'react';

export default function HeadingComp({label, fontSize = '24px'}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '8%',
      }}>
      <p
        style={{
          fontSize: fontSize,
          fontWeight: 500,
          lineHeight: '36px',
        }}>
        {label ? label : ''}
      </p>
    </div>
  );
}
