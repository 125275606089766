import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import navMenus from './navMenus';
import {useNavigate} from 'react-router-dom';
import UserIcon from '../../assets/user.png';
import {NavConstant} from 'routes/NavConstant';
import {useAuth} from 'hooks/useAuth';
import {useSelector} from 'react-redux';
import {constants} from 'helper/constant';

export default function AccountMenuComp({username}) {
  const navigate = useNavigate();
  // @ts-ignore
  const {adminDetails} = useSelector(state => state?.login);

  const {logout} = useAuth();

  const firstChar = username ? username.charAt(0).toUpperCase() : '';

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    console.log('adminDetails=> ', adminDetails);
  }, []);

  const handleNav = path => {
    if (path === NavConstant.LOGOUT) {
      logout();
    } else if (path) {
      navigate(path);
    }
  };
  return (
    <React.Fragment>
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        {adminDetails?.firstname?.charAt(0).toUpperCase() +
          adminDetails?.firstname?.slice(1)}
        <Tooltip
          title="Account settings"
          style={{
            height: 50,
            gap: 12,
            display: 'flex',
          }}>
          {/* <IconButton
            onClick={handleClick}
            // size="small"
            // sx={{ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}> */}
          <img
            src={
              adminDetails?.image != ''
                ? constants.CDN + adminDetails?.image
                : 'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61'
            }
            style={{
              height: 50,
              width: 50,
              marginLeft: 12,
              border: '1px solid #FF7200',
              objectFit: 'cover',
              borderRadius: 100,
            }}
            // alt="user-icon"
            onClick={handleClick}
          />
          {/* </IconButton> */}
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          // elevation: 1,

          sx: {
            borderRadius: '20px',
            backgroundColor: '#7729E5',
            padding: '20px 50px  ',
            overflow: 'visible',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            // mt: 1.5,
            // '& .MuiAvatar-root': {
            //   width: 32,
            //   height: 32,
            //   ml: -0.5,
            //   mr: 1,
            // },
            // '&::before': {
            //   content: '""',
            //   bgcolor: '#B1B1F7',
            //   display: 'block',
            //   position: 'absolute',
            //   top: 0,
            //   right: 14,
            //   width: 10,
            //   height: 10,
            //   transform: 'translateY(-50%) rotate(45deg)',
            //   zIndex: 0,
            // },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        {navMenus?.length > 0 &&
          navMenus.map((data, index) => {
            return (
              <MenuItem
                onClick={() => handleNav(data?.navTo)}
                style={{
                  justifyContent: 'flex-end',
                  fontFamily: 'Poppins',
                  fontWeight: 300,
                  fontSize: '16px',
                  color: 'white',
                  height: '30px',
                }}
                key={index}>
                {data?.name ? (
                  <div style={{textAlign: 'right'}}>
                    {data?.name}
                    {navMenus.length - 1 > index && (
                      <hr
                        style={{
                          height: '1px',
                          backgroundColor: 'white',
                          border: 'none', // Add this line to remove default border
                          boxShadow: 'none', // Add this line to remove default box shadow
                          marginRight: 0,
                          marginLeft: 'auto',
                          width: '200px',
                        }}
                      />
                    )}
                  </div>
                ) : (
                  'NA'
                )}
              </MenuItem>
            );
          })}
      </Menu>
    </React.Fragment>
  );
}
