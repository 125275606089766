import React, {useEffect, useState} from 'react';
import {NavConstant} from 'routes/NavConstant';
import ViewAllButton from './buttons/ViewAllButton';
import {useLocation} from 'react-router-dom';

export default function AanalyticsCardComp({
  count,
  label,
  navTo = NavConstant.HOME,
  onClick = () => {},
  selected = '#C6C6C654',
  style = {},
}) {
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '20px',
        height: '167px',
        minWidth: '308px',
        backgroundColor: '#C6C6C654',
        boxShadow: '1px 2px 3px #00000040',
        // backgroundImage: selected,
        fontFamily: 'Poppins-Medium',
        fontSize: 14,
        cursor: 'pointer',
        ...style,
      }}>
      <h4
        style={{
          fontSize: '48px',
          fontWeight: 500,
          lineHeight: '72px',
        }}>
        {count}
      </h4>
      <p
        style={{
          fontSize: '15px',
          fontWeight: 500,
          lineHeight: '21px',
          color: '#505050',
        }}>
        {label}
      </p>

      <div
        style={{
          marginTop: 20,
        }}>
        <ViewAllButton />
      </div>
    </div>
  );
}
