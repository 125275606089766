// navconstants.js

export const NavConstant = {
  LOGIN: '/',
  UploadAssets: '/upload-assets',
  PagePricing: '/page-pricing',
  EditBriefAssets: '/edit-brief-assets',
  FORGOT_PASSWORD: '/forgot-password',
  SHOOTERS: '/shooters',
  USER_PROFILE: '/users',
  TEMP: '/temp',
  Collections: 'collections',
  Bundles: 'bundles',
  TAGGED: '/tagged',
  HOME: '/home',
  DRAFTS: '/drafts',
  MANAGE_ADMIN: '/manage-admin',
  USER_ACTIVITY: '/user-activity',
  ALL_SIGNUPS: '/all-signups',
  SETTINGS: '/settings',
  LOGOUT: '/logout',
  NOT_AUTHORIZED: '/not-authorized',
  CREATE_BRIEF: '/brief/create',
  EDIT_BRIEF: '/brief/edit',
  ALL_BRIEFS: '/briefs',
  ACTIVE_BRIEFS: '/activebriefs',
  DEACTIVED_BRIEFS: '/deactivedbriefs',
  FEATURED_BRIEFS: '/featuredbriefs',

  ASSETS: '/assets',
  ALLASSETS: '/allassets',

  BRIEF_APPLICATION: '/brief-application',
  BRIEF_Submission: '/brief-submission',
  BRIEF: '/brief',
  MASTERCLASS: '/masterclass',
  PROShooters: '/proshooters',
  Event: '/event',
  Sheets: '/sheets',
};
