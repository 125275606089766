import React from 'react'

export default function PrimaryLink({
    linkLabel,
    to
}) {
    return (

        <a href={to}
            style={{
                fontFamily: "Poppins",
                fontWeight: "300",
                fontSize: "14px",
                color:"#FFFFFF",
                textDecoration: "none",
                letterSpacing:1
            }}
        >
        {linkLabel ? linkLabel : "NA"}    
        </a>
    )
}