import React from 'react';
import './app.scss';

import NavRoutes from 'routes/NavRoutes';
import {Provider} from 'react-redux';
import {store} from 'store/store';
import {NativeBaseProvider} from 'native-base';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <NativeBaseProvider>
      <ToastContainer />
      <Provider store={store}>
        <NavRoutes />
      </Provider>
    </NativeBaseProvider>
  );
}

export default App;
