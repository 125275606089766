import React, {useState, useEffect} from 'react';
import Terms from '../../component/terms';
import Logo from '../../component/logo/logo';
import {CustomInput} from '../../component/input/Input';
import PasswordInput from '../../component/input/PasswordInput';
import {
  auth,
  logInWithEmailAndPassword,
  sendPasswordReset,
} from '../../config/firebase.js';
import {useNavigate} from 'react-router-dom';
import {getAccessTokenAPI} from '../../services/Login.js';
import PrimaryButton from 'component/buttons/PrimaryButton';
import PrimaryLink from 'component/buttons/PrimaryLink';
import ErrorBlock from 'component/ErrorBlock';
import '../../app.scss';
import {useAuth} from 'hooks/useAuth';
import GraphicSvg from 'assets/GraphicDesign';
import ShotwotSvg from 'assets/ShotwotLogo';
import SecondaryButton from 'component/buttons/SecondaryButton';
import Key from 'assets/Key';
import {toast} from 'react-toastify';
import * as yup from 'yup';

const Login = () => {
  const {user, adminRoleId} = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(user != null);
    if (user != null) navigate('/home');
    return () => {};
  }, []);

  const {login} = useAuth();

  const emailschema = yup.string().email('Invalid Email');
  const passwordschema = yup.string().min(8);

  const loginAPI = async () => {
    try {
      const res = await emailschema.validate(email);
    } catch (error) {
      toast.error('Invalid Email');
      return;
    }

    try {
      const res = await passwordschema.validate(password);
    } catch (error) {
      toast.error('Invalid Password');
      return;
    }

    // setLoading(true);
    try {
      const firebaseRes = await logInWithEmailAndPassword(email, password);
      if (firebaseRes) {
        // @ts-ignore
        const res = await getAccessTokenAPI(firebaseRes?.user?.accessToken);
        if (res) {
          // @ts-ignore
          const tokens = res.tokens;
          const accesstoken = tokens?.accesstoken;
          console.log(accesstoken);
          if (!accesstoken) {
            toast.error('Unable to login.');

            alert('Unable to login. Please try after some time.');
            return;
          }
          toast.success('Logged In Sucessfully!');
          login(accesstoken);
        }
      }
    } catch (err) {
      toast.error('Invalid Email Or Password');
    } finally {
      // setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
      }}>
      <GraphicSvg
        style={{
          position: 'absolute',
          // width: '100%',
          display: 'flex',
          marginTop: -100,
          zIndex: 1,
        }}
      />
      <div
        style={{
          backgroundColor: 'white',
          justifyContent: 'center',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <div
          style={{
            width: 1100,
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <div
            style={{
              width: 1100,
              minHeight: '90vh',
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div
              style={{
                width: 550,
                height: 538,
                // justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}>
              <div
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  paddingTop: 12,
                }}>
                <ShotwotSvg
                  style={{
                    display: 'flex',
                    zIndex: 100,
                  }}
                />
                <p
                  style={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                    fontSize: 24,
                    zIndex: 100,
                    color: '#1A1A1A',
                  }}>
                  engine room
                </p>
              </div>
            </div>
            <div
              style={{
                width: 403,
                height: 538,
                // justifyContent: 'center',
                // alignItems: 'center',
                display: 'flex',
                background:
                  'linear-gradient(67.15deg, #CCCCF6 38.62%, #DCC5EB 72.57%)',
                borderRadius: 10,
                zIndex: 100,
              }}>
              <div
                style={{
                  gap: 38,
                  flexDirection: 'column',
                  display: 'flex',
                  margin: '120px 20px',
                }}>
                <p
                  style={{
                    zIndex: 100,

                    fontSize: 24,
                    color: '#1A1A1A',
                  }}>
                  Login
                </p>
                <CustomInput
                  // @ts-ignore
                  placeholder="Enter Email"
                  id="outlined-adornment-email"
                  type={'text'}
                  value={email}
                  onChange={i => {
                    setEmail(i.target.value);
                  }}
                  style={{
                    marginBottom: 20,
                    color: 'black',
                  }}
                />
                <PasswordInput password={password} setPassword={setPassword} />
                <div
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}>
                  <div
                    style={{
                      flexDirection: 'row',
                      gap: 10,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      if (email === '') {
                        toast.error('Please enter a valid email');
                      } else {
                        sendPasswordReset(email);
                      }
                    }}>
                    <Key />
                    Reset password?
                  </div>

                  <SecondaryButton
                    fontStyle={{
                      fontSize: 20,
                      fontWeight: '500',
                    }}
                    buttonLabel={'Login'}
                    labelColor="white"
                    color="#7729E5"
                    onClick={async () => {
                      await loginAPI();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'flex-end',
            width: 1100,
          }}>
          <Terms />
        </div>
      </div>
    </div>
  );
};

export default Login;
