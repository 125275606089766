import fetchHandler from './FetchHandler';

export const getAttahcement = () => {
  return fetchHandler({
    method: 'get',
    url: 'admin/attachement',
  });
};

export const postAttahcement = data => {
  return fetchHandler({
    method: 'post',
    url: 'admin/attachement',
    data,
  });
};

export const getPricing = () => {
  return fetchHandler({
    method: 'get',
    url: 'general',
  });
};

export const postPricing = data => {
  return fetchHandler({
    method: 'post',
    url: 'general/create',
    data,
  });
};

export const getCategory = () => {
  return fetchHandler({
    method: 'get',
    url: 'general/category',
  });
};

export const postCategory = data => {
  return fetchHandler({
    method: 'post',
    url: 'general/category/create',
    data,
  });
};
