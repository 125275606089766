import {NavConstant} from 'routes/NavConstant';

const navMenus = [
  {
    id: 1,
    name: 'Settings',
    navTo: NavConstant.SETTINGS,
  },
  {
    id: 2,
    name: 'Sign out',
    navTo: NavConstant.LOGOUT,
  },
];

export default navMenus;
