import React, {useEffect} from 'react';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
} from '@mui/material';
import {styled} from '@mui/system';

const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const DropDownMultiple = ({
  label,
  options,
  value,
  errorMessage,
  disabled,
  setValues,
  width = '346px',
  height = '32px',
}) => {
  const [name, setName] = React.useState(value);

  const handleChange = event => {
    console.log(event);
    const {
      target: {value},
    } = event;
    setValues(typeof value === 'string' ? value.split(',') : value);
    setName(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    setName(value);
  }, [value]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      {errorMessage && (
        <p style={{color: '#FC3CB2', marginLeft: 4, fontSize: '12px'}}>
          {errorMessage}
        </p>
      )}
      <FormControl
        size="small"
        style={{
          alignSelf: 'center',
          display: 'flex',
        }}>
        {!value ? (
          <InputLabel
            style={{
              alignSelf: 'center',
              display: 'flex',
            }}>
            {label}
          </InputLabel>
        ) : null}
        <Select
          value={name}
          onChange={handleChange}
          renderValue={selected => {
            if (selected?.length === 0) {
              return <em>{label}</em>;
            }
            return selected?.join(', ');
          }}
          multiple={true}
          sx={{
            width: width,
            backgroundColor: 'white',
          }}
          disabled={disabled}
          displayEmpty>
          {options.map(option => {
            console.log(option, name);
            return (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={name?.indexOf(option.value) > -1} />
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default DropDownMultiple;
