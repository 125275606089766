import React, {useRef, useState} from 'react';
import {useForm, FormProvider, useFieldArray} from 'react-hook-form';
import {object, string} from 'yup';
import RHFInputField from '../RHFInputField';
import RHFPicker from '../RHFPicker';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSelector} from 'react-redux';
import {createAdminUserApi} from 'services/ManageAdmin';
import {fetchAdmins} from 'store/slice/adminSlice';
import RHFPasswordField from '../RHPasswordField';
import {IconButton, LinearProgress} from '@mui/material';
import DustbinSvg from 'assets/DustbinSvg';
import {AddCircleOutlineRounded, Cancel} from '@mui/icons-material';
import {createPlaylistApi, updatePlaylistApi} from 'services/Playlist';
import {toast} from 'react-toastify';
import {SingleImageUpload} from 'component/images/SingleImageUpload';
import axios from 'axios';
import {constants} from 'helper/constant';

const validationSchema = object({});

function CreatePlaylistForm({onDone, playlist = {}}) {
  const [loading, setLoading] = React.useState(false);
  const [inputs, setInputs] = useState(playlist?.videos || ['']); // Initial state with one input
  const [pfile, setPFile] = useState(
    playlist?.thumbnail ? constants.CDN + playlist?.thumbnail : null,
  );
  const [progress, setProgress] = useState(0);

  // @ts-ignore
  const {adminRoles} = useSelector(state => state?.adminRoles);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: playlist,
  });

  const {handleSubmit, register, reset, setValue, getValues, control} = methods;

  const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'videos', // unique name for your Field Array
  });

  const FormComp = ({label, inputComp}) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          //   alignItems: "center",
          width: '906px',
        }}>
        <div
          style={{
            width: '192px',
            // marginTop: 20,
          }}>
          {label}
        </div>
        {inputComp}
      </div>
    );
  };

  const api = async (file, url) => {
    await axios({
      url: url,
      method: 'put',
      data: file,
      headers: {
        'Content-Type': file.type,
        'Content-Encoding': 'base64',
      },
      onUploadProgress: function (progressEvent) {
        setProgress(prev => prev + progressEvent?.bytes);
      },
      validateStatus: function (status) {
        toast.success('file uploaded');
        return status <= 300; // Resolve only if the status code is less than 500
      },
    });
  };

  const onSubmit = async data => {
    console.log('CreateAdminForm data ', data);
    setLoading(true);
    let res;
    try {
      if (Object.keys(playlist)?.length == 0) {
        res = await createPlaylistApi({
          ...data,
          file: {
            name: pfile?.name,
            type: pfile?.type,
          },
          files: images?.map((f, i) => {
            return {
              name: f?.name,
              type: f?.type,
            };
          }),
        });
      } else {
        res = await updatePlaylistApi({
          ...data,
          file: {
            name: pfile?.name,
            type: pfile?.type,
          },
          files: images,
        });
      }
      if (res?.success) {
        playlist == {}
          ? toast.success('created playlist!')
          : toast.success('updated playlist!');
        const res2 = await api(pfile, res?.data?.url);

        for (let i in images) {
          try {
            const res3 = await api(images[i], res?.data?.urls[i]);
          } catch (error) {}
        }
      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      toast.error(error);

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const addInput = () => {
    setInputs([...inputs, '']);
  };

  const inputFiles = useRef(null);

  const handleClick = () => {
    inputFiles.current.click();
  };

  const [images, setImages] = useState([]);

  const onChangeFile = async (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files?.[0];
    if (file) {
      for (let f of images) {
        if (file?.name == f?.name) {
          return;
        }
      }

      setImages([...images, file]);

      setValue(`videos.${index}`, file?.name);

      // const mediainfo = await mediaInfoFactory();
      // const result = mediainfo.analyzeFile(filtered[0]);
      // console.log(result);
      // mediainfo.close();
      // console.log(window?.scre);
    }
  };
  // Function to remove an input by index
  const removeInput = index => {
    const filtered = images?.filter((_, i) => i !== index);
    setImages(filtered);
  };

  return (
    <FormProvider {...methods}>
      <div style={{width: '100%'}}>
        {progress !== 0 ? (
          <LinearProgress
            variant="determinate"
            value={parseInt(
              (progress * 100) /
                (pfile?.size +
                  images?.reduce(function (acc, curr) {
                    return acc + curr?.size;
                  }, 0)) +
                '',
            )}
            sx={{
              backgroundColor: '#D9D9D9',
              span: {
                background: 'linear-gradient(to right, #FC3CB2, #00C0FB)',
              },
              height: '10px',
              'border-radius': '25px',
              marginTop: 5,
              marginBottom: 5,
            }}
          />
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{flexDirection: 'row', display: 'flex', gap: 73}}>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                width: 906 - 73,
                gap: 12,
              }}>
              <FormComp
                label="Chapter Title"
                inputComp={
                  <RHFInputField
                    name="title"
                    // @ts-ignore
                    register={register}
                    label="title"
                    placeholder="Please enter title here..."
                    width="641px"
                  />
                }
              />
              <FormComp
                label="About Chapter"
                inputComp={
                  <RHFInputField
                    name="description"
                    // @ts-ignore
                    register={register}
                    label="about"
                    placeholder="Description"
                    height="100px"
                    width="641px"
                    isMultiline={true}
                  />
                }
              />
              {/* <FormComp
                label="Add Video Files"
                inputComp={
                  <div
                    style={{
                      height: '350px',
                      width: '641px',
                      border: '1px dashed #7729E5',
                      borderRadius: 4,
                      cursor: 'pointer',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 20,
                    }}
                    onClick={handleClick}>
                    <input
                      type="file"
                      style={{display: 'none'}}
                      id="upload"
                      ref={inputFiles}
                      multiple
                      onChange={onChangeFile}
                    />
                    {'+ Add Files to Upload'}
                    <ul
                      style={{
                        // flexWrap: 'wrap',
                        display: 'flex',
                        // listStyleType: 'none',
                        gap: 20,
                        flexDirection: 'column',
                        // marginTop: 190,
                        overflow: 'scroll',

                        width: '600px',
                      }}
                      id="assets">
                      {images?.map((i, index) => (
                        <li
                          id={index?.toString()}
                          style={{
                            flexDirection: 'row',
                            display: 'flex',
                            padding: 10,
                            justifyContent: 'space-between',
                            backgroundColor: 'white',
                            borderRadius: 6,
                            width: '500px',
                          }}>
                          {i?.name}
                          <Cancel
                            onClick={e => {
                              setImages(prev => prev?.filter(img => img !== i));
                              e?.stopPropagation();
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                }
              /> */}
              <FormComp
                label="Add Videos"
                inputComp={
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 12,
                    }}>
                    {fields?.map((input, index) => (
                      <>
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          Episode {index + 1}
                          <div
                            style={{
                              position: 'absolute',
                              marginLeft: '610px',
                              //   right: '460px', // Adjust position as needed
                              cursor: 'pointer',
                              marginTop: 8,
                            }}>
                            <DustbinSvg
                              onClick={() => {
                                remove(index);
                                removeInput(index);
                              }}
                              size="18"
                            />
                          </div>
                        </div>
                        <input
                          style={{
                            width: 641,
                            height: 31,
                            padding: '8px 12px',
                            boxSizing: 'border-box',
                            border: '0px #B0B8C4',
                            borderRadius: '6px',
                            backgroundColor: 'white',
                            fontSize: '14px',
                            resize: 'none',
                            cursor: 'pointer',
                          }}
                          value={
                            images?.[index]?.name ||
                            inputs?.[index] ||
                            'Upload file'
                          }
                          onClick={handleClick}
                        />
                        <input
                          type="file"
                          style={{display: 'none'}}
                          id="upload"
                          ref={inputFiles}
                          multiple={false}
                          onChange={e => onChangeFile(e, index)}
                        />
                      </>
                    ))}
                  </div>
                }
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <IconButton onClick={() => insert(fields?.length + 1, '')}>
                  <AddCircleOutlineRounded
                    style={{
                      fontSize: '32px',
                    }}
                  />
                </IconButton>
              </div>
            </div>
            <div
              style={{
                borderLeft: '1px solid ',
                borderColor: '#00000050',
                padding: '0px 73px',
                display: 'flex',
                flexDirection: 'column',
                gap: 6,
              }}>
              <div
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}>
                <SingleImageUpload image={pfile} setImage={setPFile} />
                Add Thumbnail
              </div>
            </div>
          </div>
        </form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '30px',
            marginRight: 24,
            flexDirection: 'row',
            gap: 5,
          }}>
          <SecondaryButton
            onClick={() => {
              reset(playlist);
              setPFile(
                playlist?.thumbnail
                  ? constants.CDN + playlist?.thumbnail
                  : null,
              );
            }}
            buttonLabel={'Discard'}
            color="#BFBFBF"
            labelColor="white"
            type="button"
            width="86px"
          />

          <SecondaryButton
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
            buttonLabel={Object.keys(playlist)?.length > 0 ? 'Edit' : 'Create'}
            color="#7729E5"
            labelColor="white"
            type="button"
            width="86px"
          />
        </div>
      </div>
    </FormProvider>
  );
}

export default CreatePlaylistForm;
