import React from 'react';

const CustomTab = ({tabList, currentTab, handleClick}) => {
  const TabComp = ({tabName, onClick, isActive}) => {
    return (
      <div
        onClick={onClick}
        style={{
          display: 'flex',
          borderRadius: '5px',
          justifyContent: 'center',
          alignItems: 'center',
          height: '43px',
          width: '167px',
          backgroundColor: isActive ? '#00C0FB' : '#C6C6C654',
          color: isActive ? 'white' : 'black',
          marginTop: 0,
          boxShadow: '1px 2px 3px #00000040',
          cursor: 'pointer',
        }}>
        {tabName}
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        borderRadius: '20px',
        gap: 10,
        minHeight: '5%',
        height: 'auto',

        marginTop: 20,
      }}>
      {tabList?.map(d => (
        <TabComp
          onClick={() => handleClick(d)}
          key={d?.tabId}
          tabName={d?.name}
          isActive={currentTab?.tabId === d?.tabId}
        />
      ))}
    </div>
  );
};

export default CustomTab;
