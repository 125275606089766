import fetchHandler from './FetchHandler';

export const createBriefApi = ({
  id = null,
  description,
  expiry,
  resolution,
  frameRate,
  duration,
  title,
  lightSetup,
  camera,
  tags,
  type,
  reward,
  cardImage,
  images,
  file,
  files,
  shotwotIdeas,
  orientation,
  assets,
}) => {
  expiry = expiry === '' ? null : expiry;
  files = files?.map(element => {
    return {
      name: element.name,
      type: element.type,
    };
  });
  if (file != null) {
    file = {
      name: file.name,
      type: file.type,
    };
  }

  return fetchHandler({
    method: 'post',
    url: 'admin/briefs/create',
    data: {
      description,
      expiry,
      resolution,
      frameRate,
      id,
      duration,
      title,
      lightSetup,
      camera,
      tags,
      type,
      reward: parseInt(reward || 0),
      cardImage,
      files,
      file,
      shotwotIdeas,
      orientation,
      assets: parseInt(assets || 0),
    },
  });
};

export const createBriefFromDraftApi = ({
  description,
  expiry,
  resolution,
  frameRate,
  duration,
  title,
  lightSetup,
  camera,
  tags,
  type,
  reward,
  cardImage,
  file,
  files,
  shotwotIdeas,
  orientation,
  assets,
  id = null,
}) => {
  expiry = expiry === '' ? null : expiry;
  let newFiles = [];
  let images = [];
  console.log(orientation);
  for (let element of files) {
    if (!(typeof element === 'string' || element instanceof String)) {
      newFiles.push({
        name: element?.name,
        type: element?.type,
      });
    } else {
      images.push(element);
    }
  }
  if (!(typeof file === 'string' || file instanceof String)) {
    file = {
      name: file?.name,
      type: file?.type,
    };
    cardImage = null;
  } else {
    file = null;
  }
  console.log(newFiles, cardImage, file, images);

  return fetchHandler({
    method: 'put',
    url: 'admin/briefs/createfromdraft',
    data: {
      description,
      expiry,
      resolution,
      frameRate,
      duration,
      id,
      title,
      lightSetup,
      camera,
      tags,
      type,
      reward: parseInt(reward || 0),
      cardImage,
      images,
      files: newFiles,
      file,
      shotwotIdeas,
      orientation,
      assets: parseInt(assets || 0),
    },
  });
};

export const createDraftApi = ({
  description,
  expiry,
  resolution,
  frameRate,
  duration,
  title,
  lightSetup,
  camera,
  tags,
  type,
  reward,
  cardImage,
  file,
  files,
  shotwotIdeas,
  orientation,
  assets,
  id = null,
}) => {
  expiry = expiry === '' ? new Date()?.toISOString() : expiry;
  let newFiles = [];
  let images = [];
  console.log(orientation);
  for (let element of files) {
    if (!(typeof element === 'string' || element instanceof String)) {
      newFiles.push({
        name: element?.name,
        type: element?.type,
      });
    } else {
      images.push(element);
    }
  }
  if (!(typeof file === 'string' || file instanceof String)) {
    file = {
      name: file?.name,
      type: file?.type,
    };
    cardImage = null;
  } else {
    file = null;
  }

  return fetchHandler({
    method: 'post',
    url: 'admin/briefs/drafts/create',
    data: {
      description,
      id,
      expiry,
      resolution,
      frameRate,
      duration,
      title,
      lightSetup,
      camera,
      tags,
      type,
      reward: parseInt(reward || 0),
      cardImage,
      images,
      files: newFiles,
      file,
      shotwotIdeas,
      orientation,
      assets: parseInt(assets || 0),
    },
  });
};

export const updateBriefApi = ({
  id,
  description,
  isActive,
  expiry,
  resolution,
  frameRate,
  duration,
  title,
  lightSetup,
  camera,
  tags,
  type,
  reward,
  cardImage,
  file,
  files = [],
  shotwotIdeas,
  orientation,
  assets,
  isFeatured,
}) => {
  expiry = expiry === '' ? null : expiry;
  let newFiles = [];
  let images = [];
  console.log(orientation);
  for (let element of files) {
    if (!(typeof element === 'string' || element instanceof String)) {
      newFiles.push({
        name: element?.name,
        type: element?.type,
      });
    } else {
      images.push(element);
    }
  }
  if (!(typeof file === 'string' || file instanceof String)) {
    file = {
      name: file?.name,
      type: file?.type,
    };
    cardImage = null;
  } else {
    file = null;
  }
  console.log(newFiles, cardImage);

  return fetchHandler({
    method: 'put',
    url: 'admin/briefs/update',
    data: {
      description,
      expiry,
      resolution,
      frameRate,
      duration,
      isFeatured,
      title,
      lightSetup,
      camera,
      tags,
      type,
      reward: parseInt(reward || 0),
      cardImage,
      images,
      files: newFiles,
      file,
      shotwotIdeas,
      id,
      isActive,
      orientation,
      assets: parseInt(assets || 0),
    },
  });
};

export const updateDraftApi = ({
  id,
  description,
  isActive,
  expiry,
  resolution,
  frameRate,
  duration,
  title,
  lightSetup,
  camera,
  tags,
  type,
  reward,
  cardImage,
  file,
  files = [],
  shotwotIdeas,
  orientation,
  assets,
}) => {
  expiry = expiry === '' ? new Date()?.toISOString() : expiry;
  let newFiles = [];
  let images = [];
  console.log(orientation);
  for (let element of files) {
    if (!(typeof element === 'string' || element instanceof String)) {
      newFiles.push({
        name: element?.name,
        type: element?.type,
      });
    } else {
      images.push(element);
    }
  }
  if (!(typeof file === 'string' || file instanceof String)) {
    file = {
      name: file?.name,
      type: file?.type,
    };
    cardImage = null;
  } else {
    file = null;
  }
  console.log(newFiles, cardImage);

  return fetchHandler({
    method: 'put',
    url: 'admin/briefs/drafts/update',
    data: {
      description,
      expiry,
      resolution,
      frameRate,
      duration,
      title,
      lightSetup,
      camera,
      tags,
      type,
      reward: parseInt(reward || 0),
      cardImage,
      images,
      files: newFiles,
      file,
      shotwotIdeas,
      id,
      isActive,
      orientation,
      assets: parseInt(assets || 0),
    },
  });
};

export const updateActiveBriefAPI = data => {
  return fetchHandler({
    method: 'put',
    url: 'admin/briefs/update',
    data,
  });
};

export const getAllBriefsApi = (isActive, isFeatured) => {
  let data;

  if (isActive != null) {
    data = {
      order: -1,
      isActive,
    };
  } else {
    data = {
      order: -1,
    };
  }

  if (isFeatured != null) {
    return fetchHandler({
      method: 'post',
      url: `admin/briefs/list`,
      data: {
        isFeatured,
        order: -1,
      },
    });
  }

  return fetchHandler({
    method: 'post',
    url: 'admin/briefs/list',
    data,
  });
};

export const getDraftBriefsApi = () => {
  return fetchHandler({
    method: 'get',
    url: 'admin/briefs/drafts/list',
  });
};

export const downloadDraftBriefsApi = () => {
  return fetchHandler({
    method: 'get',
    url: 'admin/briefs/drafts/list/download',
  });
};

export const downloadAllBriefsApi = (isActive, isFeatured) => {
  let data;

  if (isActive != null) {
    data = {
      order: -1,
      isActive,
    };
  } else {
    data = {
      order: -1,
    };
  }

  if (isFeatured != null) {
    return fetchHandler({
      method: 'post',
      url: `admin/briefs/list/download`,
      data: {
        isFeatured,
        order: -1,
      },
    });
  }

  return fetchHandler({
    method: 'post',
    url: 'admin/briefs/list/download',
    data,
  });
};

export const getBriefApplicationsApi = id => {
  return fetchHandler({
    method: 'get',
    url: 'admin/brief/applications/' + id,
    data: {
      order: -1,
    },
  });
};

export const getAllBriefApplicationsApi = () => {
  return fetchHandler({
    method: 'get',
    url: 'admin/brief/applications',
  });
};

export const getAllSubmissionsApi = () => {
  return fetchHandler({
    method: 'get',
    url: 'admin/brief/submissions',
  });
};

export const getPaidShootersApi = () => {
  return fetchHandler({
    method: 'get',
    url: 'admin/brief/paidshooters',
  });
};

export const getActivitiesApi = () => {
  return fetchHandler({
    method: 'get',
    url: 'admin/user/activity',
  });
};

export const getBriefSubmissionsApi = id => {
  return fetchHandler({
    method: 'get',
    url: 'admin/brief/submissions/' + id,
    data: {
      order: -1,
    },
  });
};

export const getBriefShortlisted = id => {
  return fetchHandler({
    method: 'get',
    url: 'admin/brief/shortlisted/' + id,
    data: {
      order: -1,
    },
  });
};

export const getBriefSaved = id => {
  return fetchHandler({
    method: 'get',
    url: `admin/brief/saved?id=${id}`,
    data: {
      order: -1,
    },
  });
};

export const deleteBriefApplication = id => {
  return fetchHandler({
    method: 'delete',
    url: 'admin/brief/applications/' + id,
  });
};

export const getBriefByIdApi = id => {
  return fetchHandler({
    method: 'get',
    url: 'admin/briefs/details/' + id,
  });
};

export const getDraftByIdApi = id => {
  return fetchHandler({
    method: 'get',
    url: 'admin/briefs/drafts/' + id,
  });
};

export const getbriefApplicationApi = id => {
  if (id) {
    return fetchHandler({
      method: 'get',
      url: `admin/brief/application/${id}`,
    });
  }
};

export const getbriefApplicationAssetsApi = (briefId, userId) => {
  return fetchHandler({
    method: 'get',
    url: `admin/assets/${briefId}/${userId}`,
  });
};
export const briefApplicationActionApi = data => {
  if (data) {
    return fetchHandler({
      method: 'put',
      url: 'admin/brief/application/action',
      data,
    });
  }
};

export const addAssetsApi = data => {
  if (data) {
    return fetchHandler({
      method: 'put',
      url: 'admin/brief/application/add-assets',
      data,
    });
  }
};
