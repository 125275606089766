import {combineReducers} from '@reduxjs/toolkit';
// slices
import adminReducer from './slice/adminSlice';
import userReducer from './slice/usersSlice';
import briefReducer from './slice/briefSlice';
import assetReducer from './slice/assetSlice';
import adminRoleSliceReducer from './slice/adminRoleSlice';
import loginReducer from './slice/loginSlice';
import adminDetailReducer from './slice/adminDataSlice';
import masterReducer from './slice/masterclassSlice';
import withdrawReducer from './slice/withdrawSlice';

const rootReducer = combineReducers({
  admins: adminReducer,
  adminRoles: adminRoleSliceReducer,
  users: userReducer,
  login: loginReducer,
  brief: briefReducer,
  asset: assetReducer,
  masterclass: masterReducer,
  adminData: adminDetailReducer,
  withdraw: withdrawReducer,
});

export default rootReducer;
