import HeadingComp from 'component/HeadingComp';
import DataTableComp from 'component/datatable/DataTableComp';

import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useEffect, useRef, useState} from 'react';

import {manageAdminCol} from 'component/datatable/DataTableColumns';
import CreateAdmin from './CreateAdmin';

//keep redux import at end
import {fetchAdmins, clearAdmins, updateAdmin} from 'store/slice/adminSlice';
import {dispatch, useSelector} from 'store/store';
import {HeadLabel} from 'component/HeadLabel';
import {ArrowCircleLeftOutlined} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import {CircleTick} from 'component/CircleTick';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import UpdateAdminForm from 'component/forms/comman/UpdateAdminForm';
import ReactModal from 'react-modal';
import {Edit} from 'component/Edit';
import {deleteAdminUserApi, updateAdminUserApi} from 'services/ManageAdmin';
import {toast} from 'react-toastify';
import {Delete} from 'component/Delete';
import {dateComparator} from 'helper/comparator';

const customStyles = {
  content: {
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0px',
    padding: -20,
    // backdropFilter: 'blur(1000px)',

    overlay: {
      backdropFilter: 'blur(10000px)',
    },
  },
};

Modal.setAppElement('#root');

export default function ManageAdmin() {
  // @ts-ignore
  const {admins} = useSelector(state => state?.admins);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [current, setCurrent] = React.useState({});

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  // @ts-ignore
  useEffect(() => {
    dispatch(fetchAdmins());
    return () => dispatch(clearAdmins());
  }, []);

  const navigate = useNavigate();
  const gridRef = useRef();

  const [adminCol] = useState([
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
    },
    {field: 'firstname', headerName: 'First name', width: 120},
    {field: 'lastname', headerName: 'Last name', width: 120},
    {field: 'email', headerName: 'Login ID', width: 120},
    {
      field: 'edit',
      headerName: 'Edit',
      width: 80,
      cellRenderer: Edit,
      valueGetter: params => {
        return params?.data?.active;
      },
      onCellClicked: e => {
        setCurrent(e?.data);
        openModal();
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 120,
      valueGetter: params => {
        switch (params?.data?.role) {
          case 1:
            return 'Super Admin';
          case 2:
            return 'Admin';
          case 3:
            return 'Brief Manager';
          case 4:
            return 'Asset Manager';
          default:
            break;
        }
      },
    },
    {
      field: 'active',
      headerName: 'Active',
      width: 80,
      cellRenderer: CircleTick,
      // valueGetter: params => {
      //   return params?.data?.active;
      // },
      editable: true,
    },
    {
      field: 'created',
      headerName: 'Last Modified',
      width: 220,
      valueGetter: params => {
        const date = new Date(params?.data?.created);
        return date?.toLocaleDateString('en-GB');
        // + '  ' + date?.toLocaleTimeString()
      },
      comparator: dateComparator,
    },
    {
      field: 'Delete',
      headerName: 'Delete',
      width: 80,
      cellRenderer: e => {
        if (e?.data?.email?.includes('shubham@kridhafilms.com')) {
          return null;
        }
        return <Delete />;
      },
      onCellClicked: async e => {
        console.log(e?.data);
        if (e?.data?.email?.includes('shubham@kridhafilms.com')) {
          return null;
        }
        try {
          const res = await deleteAdminUserApi(e?.data?.id);
          if (res?.success) toast.success('Admin deleted');
          dispatch(fetchAdmins());
        } catch (error) {
          toast.error(error);
        }
      },
      // valueGetter: params => {
      //   return params?.data?.active;
      // },
    },
  ]);

  return (
    <DashboardLayout>
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}>
        <UpdateAdminForm
          initial={current}
          onDone={() => {
            dispatch(fetchAdmins());
            closeModal();
          }}
        />
      </ReactModal>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 15,
          alignItems: 'center',
        }}>
        <ArrowCircleLeftOutlined
          onClick={() => navigate(-1)}
          style={{
            // marginLeft: 20,
            cursor: 'pointer',
          }}
        />
        <HeadLabel label={'Admin Panel'} />
      </div>
      <CreateAdmin />
      {/* <div
        style={{
          display: 'flex',
          borderRadius: '20px',
          minHeight: '26%',
          height: 'auto',
          padding: '20px 20px',
          backgroundColor: '#C6C6C654',
          marginTop: 20,
          boxShadow: '1px 2px 3px #00000040',
        }}>
        <div
          style={{
            width: '100%',
            justifyContent: 'center',
            //    backgroundColor:"red"
          }}>

          <div
            style={{
              marginTop: '10px',
              //    backgroundColor:"red"
            }}>
            <DataTableComp
              rows={admins}
              columns={manageAdminCol}
              handleClick={() => {}}
            />
          </div>
        </div>
      </div> */}
      <HeadingComp label={'Manage Admins'} fontSize="20px" />
      <p
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
          flex: 1,
          marginBottom: 5,
          marginTop: -40,
        }}>
        Total Entries: {admins?.length || 0}
      </p>
      <div
        className={'ag-theme-quartz'}
        style={{width: '100%', height: '500px'}}>
        <AgGridReact
          ref={gridRef}
          // defaultColDef={{...defaultColDef}}
          readOnlyEdit={true}
          getRowId={params => params.data.id}
          onCellEditRequest={async event => {
            const oldData = event?.data;
            const field = event?.colDef?.field;
            const newData = {...oldData};
            newData[field] = event?.newValue;
            try {
              await updateAdminUserApi(newData);
            } catch (error) {
              toast.error(error);
              console.log(error);
            } finally {
              toast.success('User updated successfully');
            }
            dispatch(updateAdmin(newData));
          }}
          // onRowClicked={e => {
          //   setCurrent(e?.data);
          //   openModal();
          // }}
          rowData={admins || []}
          columnDefs={adminCol}
          pagination={true}
          paginationPageSize={20}
          rowSelection="multiple"
          scrollbarWidth={9}
          alwaysShowHorizontalScroll={true}
          alwaysShowVerticalScroll={true}
        />
      </div>
    </DashboardLayout>
  );
}
