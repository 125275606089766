import React, {useState} from 'react';
import HeadingComp from 'component/HeadingComp';

import {dispatch} from 'store/store';
import {fetchAdmins} from 'store/slice/adminSlice';
import {CreateAdminForm} from 'component/forms';

function CreateAdmin() {
  const [notify, setNotify] = useState(null);
  const handleDone = data => {
    dispatch(fetchAdmins());
    setNotify(data);
    console.log(data);
  };
  return (
    <div
      style={{
        display: 'flex',
        borderRadius: '6px',
        minHeight: '26%',
        padding: '20px 20px',
        backgroundColor: '#C6C6C654',
        boxShadow: '1px 2px 3px #00000040',
        marginTop: 20,
        width: 916,
      }}>
      <div
        style={{
          width: 560,
          justifyContent: 'center',
          //    backgroundColor:"red"
        }}>
        <div
          style={{
            width: 580,
            justifyContent: 'center',
            // backgroundColor: "red"
          }}>
          <HeadingComp label={'Create New'} fontSize="20px" />

          <CreateAdminForm onDone={handleDone} />
        </div>
      </div>
      <div
        style={{
          // width: '50%',
          borderLeft: '1px solid',
          borderColor: '#00000050',
          padding: '10px 20px',
          //    backgroundColor:"green",
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}>
        {notify ? 'New User Created Succesfully' : null}
        <br></br>
        <br></br>
        {notify ? 'Login Id:  ' + notify?.email : null}
        <br></br>
        {notify ? 'Password:  ' + notify?.password : null}
      </div>
    </div>
  );
}

export default CreateAdmin;
