import React, {useEffect} from 'react';
import './tag.scss';

export const TagInput = props => {
  const [tags, setTags] = React.useState(props?.tags || []);
  const removeTags = indexToRemove => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
    props?.selectedTags([
      ...tags?.filter((_, index) => index !== indexToRemove),
    ]);
  };

  const addTags = event => {
    if (event.target.value !== '') {
      if (tags?.length > 0) {
        setTags([...tags, event.target.value]);
        props?.selectedTags([...tags, event.target.value]);
      } else {
        setTags([event.target.value]);
        props?.selectedTags([event.target.value]);
      }
      //   props.selectedTags([...tags, event.target.value]);
      event.target.value = '';
    }
  };

  const width = props?.width || 416;

  const height = props?.height || 80;

  useEffect(() => {
    setTags(props?.tags);
  }, [props]);

  return (
    <div
      className="tags-input"
      style={{
        width,
        minHeight: height,
      }}>
      <ul id="tags">
        {tags?.map((tag, index) => (
          <li key={index} className="tag">
            <span className="tag-title">{tag}</span>
            {/* <span className="tag-close-icon" onClick={() => removeTags(index)}>
              x
            </span> */}
          </li>
        ))}
      </ul>
      <input
        type="text"
        onKeyUp={event => {
          if (event.key === 'Enter') {
            addTags(event);

            event?.stopPropagation();
            event?.preventDefault();
          }
        }}
        placeholder="Click to add tags"
        onKeyDown={event =>
          event.key === 'Backspace' && event.target?.value == ''
            ? removeTags(tags?.length - 1)
            : null
        }
      />
    </div>
  );
};
