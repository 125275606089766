import {NavConstant} from 'routes/NavConstant';

const sidebarMenus = [
  {
    headingTitle: 'Admin Dashboard',
    subMenus: [
      // {
      //   id: 1,
      //   name: 'Home',
      //   navTo: NavConstant.HOME,
      //   isActive: false,
      //   isDisabled: false,
      //   allowedRoles: [1, 2],
      // },
      {
        id: 1,
        name: 'Admin Panel',
        navTo: NavConstant.MANAGE_ADMIN,
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },

      {
        id: 2,
        name: 'Manage Page Copy',
        navTo: '/page-copy',
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 3,
        name: 'Manage Product Pricing',
        navTo: '/page-pricing',
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 4,
        name: 'Manage Attachements',
        navTo: '/attachements',
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 5,
        name: 'Withdrawal Requests',
        navTo: '/withdrawal',
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
    ],
  },
  {
    headingTitle: 'User Dashboard',
    subMenus: [
      {
        id: 1,
        name: 'All Sign ups',
        navTo: NavConstant.ALL_SIGNUPS,
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 2,
        name: 'Shooters',
        navTo: NavConstant.SHOOTERS,
        isActive: true,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 3,
        name: 'User Activity',
        navTo: NavConstant.USER_ACTIVITY,
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 4,
        name: 'Create a User',
        navTo: '/users/',
        isActive: false,
        isDisabled: true,
        allowedRoles: [1, 2],
      },
    ],
  },
  {
    headingTitle: 'Brief Dashboard',
    subMenus: [
      {
        id: 1,
        name: 'Create New Brief',
        navTo: NavConstant.CREATE_BRIEF,
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 2,
        name: 'All Briefs',
        navTo: NavConstant.ALL_BRIEFS,
        isActive: true,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 2,
        name: 'Active Briefs',
        navTo: NavConstant.ACTIVE_BRIEFS,
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 3,
        name: 'Draft Briefs',
        navTo: NavConstant.DRAFTS,
        isActive: true,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 4,
        name: 'Deactivated Briefs',
        navTo: NavConstant.DEACTIVED_BRIEFS,
        isActive: true,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 5,
        name: 'Featured Briefs',
        navTo: NavConstant.FEATURED_BRIEFS,
        isActive: true,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
    ],
  },
  {
    headingTitle: 'Asset Dashboard',
    subMenus: [
      {
        id: 1,
        name: 'All Assets',
        navTo: NavConstant.ALLASSETS,
        isActive: true,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 2,
        name: 'Upload Assets',
        navTo: NavConstant.UploadAssets,
        isActive: true,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 3,
        name: 'New Assets',
        navTo: NavConstant.ASSETS,
        isActive: true,
        isDisabled: false,
        allowedRoles: [1, 2],
      },

      {
        id: 4,
        name: 'Tagged Assets',
        navTo: NavConstant.TAGGED,
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 5,
        name: 'Collections',
        navTo: NavConstant.Collections,
        isActive: false,
        isDisabled: true,
        allowedRoles: [1, 2],
      },
      {
        id: 6,
        name: 'Bundles',
        navTo: NavConstant.Bundles,
        isActive: false,
        isDisabled: true,
        allowedRoles: [1, 2],
      },
      {
        id: 7,
        name: 'Category',
        navTo: '/category',
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },

      // {
      //   id: 6,
      //   name: 'Archived Assets',
      //   navTo: '',
      //   isActive: false,
      //   isDisabled: true,
      //   allowedRoles: [1, 2],
      // },
    ],
  },
  {
    headingTitle: 'Pro Shooter Dashboard',
    subMenus: [
      {
        id: 1,
        name: 'Pro Shooters',
        navTo: NavConstant.PROShooters,
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 2,
        name: 'Masterclasses',
        navTo: NavConstant.MASTERCLASS,
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 1,
        name: 'Events',
        navTo: NavConstant.Event,
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
      {
        id: 2,
        name: 'Cheat Sheet',
        navTo: NavConstant.Sheets,
        isActive: false,
        isDisabled: false,
        allowedRoles: [1, 2],
      },
    ],
  },
];

export default sidebarMenus;
