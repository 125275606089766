import { store } from 'store/store';
import fetchHandler from './FetchHandler';
import { setAdminDetails } from 'store/slice/loginSlice';
import { getAdminRoles } from 'helper/reduxHelper';


export const getAccessTokenAPI = (idToken) => {
  return fetchHandler({
    method: 'post',
    url: 'admin/signin',
    data: {
      "idToken": idToken
    },
  });
};


export const getSignInAdminDetails = () => {
  return fetchHandler({
    method: 'get',
    url: 'admin/details/me',
  })
};