import fetchHandler from './FetchHandler';

export const getAllUsersApi = (created,skip, from, to, pro) => {
    console.log(created, skip);
    return fetchHandler({
        method: 'post',
        url: 'admin/users/list',
        data: {
            created,
            skip,
            from,
            to,
            pro
        },
      });
};

export const getAllShootersApi = (created,skip, from, to) => {
    console.log(created, skip);
    return fetchHandler({
        method: 'post',
        url: 'admin/shooters/list',
        data: {
            created,
            skip,
            from,
            to
        },
      });
};

export const searchUsersApi = (key, pro) => {
    return fetchHandler({
        method: 'post',
        url: 'admin/users/search',
        data: {
            key,
            pro
        },
      });
};

export const getTotalUsersApi = () => {
    return fetchHandler({
        method: 'get',
        url: 'admin/totalusers',
      });
};

export const getTotalShootersApi = () => {
    return fetchHandler({
        method: 'get',
        url: 'admin/totalshooters',
      });
};

export const getUserApi = (id) => {
    return fetchHandler({
        method: 'get',
        url: `admin/user?id=${id}`,
      });
};

export const getUserStatsApi = (id) => {
    return fetchHandler({
        method: 'get',
        url: `admin/user/stats?id=${id}`,
      });
};

export const updateUserApi = (data) => {
    return fetchHandler({
        method: 'post',
        url: `admin/user/update`,
        data
      });
};

export const downloadUsersApi = ({from,to, pro = null}) => {
    return fetchHandler({
        method: 'post',
        url: 'admin/users/download',
        data: {
           from,to, pro
        },
      });
};

export const downloadShootersApi = ({from,to}) => {
    return fetchHandler({
        method: 'post',
        url: 'admin/shooters/download',
        data: {
           from,to
        },
      });
};