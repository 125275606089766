import React from 'react';
import LogoImage from '../../assets/logo';
import './logo.scss';

const Logo = ({
  firstColor = 'white',
  secondColor = 'white',
  height = '81',
  width = '70',
  fontSize = '128px',
  fontColor = 'white',
}) => {
  return (
    <div className="logoContainer">
      <div className="logo">
        <LogoImage
          fillColor={firstColor}
          height={height ? height : null}
          width={width ? width : null}
        />
        {/* <LogoImage fillColor={secondColor} 
                  height={height ? height : null}
                  width={width ? width : null}
        /> */}
      </div>
      <div
        style={{
          fontFamily: 'Poppins',
          fontSize: fontSize,
          fontWeight: 600,
          color: fontColor,
          marginLeft: 6,
        }}>
        <p>Engineroom |</p>
      </div>
    </div>
  );
};

export default Logo;
