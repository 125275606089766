import React, { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import { getSignInAdminDetails } from 'services/Login';
import { store } from 'store/store';
import { setAdminDetails } from 'store/slice/loginSlice';
import { getAdminRoles } from 'helper/reduxHelper';
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null);
  const [userData, setUserData] = useLocalStorage('userData', null);
  const adminRoleId = 1
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async data => {
    setUser(data);
    navigate('/home');
    const res = await getSignInAdminDetails()
      if (res) {
        const {adminRoles} = getAdminRoles();
        // @ts-ignore
        const filterData = adminRoles?.filter((d) => res?.role === d?.value);
  
        let userdata = {
          ...res,
          adminRole: {
            ...filterData[0],
          },
        };
        store.dispatch(setAdminDetails(userdata));
      }
  };

  

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate('/', { replace: true });
  };

  return <AuthContext.Provider value={{ user, adminRoleId, login, logout, setUserData, userData }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const {
    user,
    adminRoleId,
    login,
    logout,
    setUserData,
    userData
  } = useContext(AuthContext);
  return { user, adminRoleId, login, logout, setUserData, userData }
};

