import { getAllBriefsApi, getDraftBriefsApi } from 'services/Brief';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


export const fetchBriefs = createAsyncThunk(
  'users/fetchBriefs',
  // @ts-ignore
  async ({ isActive = null, isFeatured = null }) => {
    const response = await getAllBriefsApi(isActive, isFeatured);
    return response;
  },
);

export const fetchDraftBriefs = createAsyncThunk(
  'users/fetchDraftBriefs',
  // @ts-ignore
  async () => {
    const response = await getDraftBriefsApi();
    return response;
  },
);

const initialState = {
  briefs: [],
  loading: true,
  hasMore: false,
  totalResults: 0,
};

const briefSlice = createSlice({
  name: 'briefs',
  initialState,
  reducers: {
    clearBriefs: () => {
      return initialState;
    },
    deleteBrief: (state, action) => {
      console.log(action.payload);
      if (state.briefs?.length) {
        const filteredBriefs = state.briefs?.filter(
          brief => brief.id !== action.payload.id,
        );

        state.totalResults -= 1;
        state.briefs = filteredBriefs;
      }
    },
    updateBrief: (state, action) => {
      const updatedBrief = action.payload;
      if (state.briefs) {
        const updatedBriefs = state.briefs?.map(brief => {
          if (brief.id === updatedBrief.id) {
            return updatedBrief;
          }
          return brief;
        });

        state.briefs = updatedBriefs;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBriefs.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        state.loading = false;
        state.briefs = data;
      })
      .addCase(fetchDraftBriefs.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        state.loading = false;
        state.briefs = data;
      })
      .addCase(fetchBriefs.pending, state => {
        state.loading = true;
      })
      .addCase(fetchBriefs.rejected, state => {
        state.loading = false;
      })
  },
});

const { actions, reducer } = briefSlice

// Reducer
export default reducer;

export const { clearBriefs, updateBrief, deleteBrief } = actions;