// @ts-nocheck
import React, { useState } from 'react';
import Terms from '../../component/terms';
import Logo from '../../component/logo/logo';
import { CustomInput } from '../../component/input/Input';
import PrimaryButton from 'component/buttons/PrimaryButton';
import PrimaryLink from 'component/buttons/PrimaryLink';
import ErrorBlock from 'component/ErrorBlock';
import '../../app.scss';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);


    const onInputChange = (val) => {

        if (val) {
            setError(false)
            setEmail(val)
        }
        else {
            setEmail(val)
        }

    }


    const handleSubmit = () => {

        if (!email) {
            setError(true)
        }
        else {
            setError(false)
        }
    }


    return (
        <div className="gradient_background">
        <div
            style={{
                flexDirection: 'column',
                height: '100vh',
                display: 'flex',
            }}>
            <div
                style={{
                    display: 'flex',
                    height: '85vh',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                <div style={{ width: '50%', paddingLeft: '10%' }}>
                    <Logo />
                </div>
                <div
                    style={{
                        width: '50%',
                        // marginRight: '10%',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <div>

                        <ErrorBlock
                            label={"Wrong Email"}
                            visible={error}
                        />

                        <CustomInput
                            placeholder="Enter Email"
                            id="outlined-adornment-email"
                            type={'text'}
                            value={email}
                            onChange={i =>
                                onInputChange(i.target.value)

                            }

                        />

                        <div style={{ 
                            display: 'flex', 
                            justifyContent: "space-between", 
                            marginTop: 20, 
                            alignItems: "center" }}>


                            <PrimaryLink
                                linkLabel={"Login?"}
                                to={"/"}
                            />

                            <PrimaryButton
                                buttonLabel={"Submit"}
                                onClick={() => handleSubmit()}

                            />

                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <Terms />
            </div>
        </div>
        </div>
    );
};

export default ForgotPassword;
