import {
  getAllAssetsApi,
  getBriefAssetsApi,
  getNewAssetsApi,
  searchAssetsApi,
} from 'services/Asset';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchAssets = createAsyncThunk(
  'users/fetchassets',
  // @ts-ignore
  async ({isActive = null, s = 0, l = 1, page = 0, status = 0}) => {
    const response = await getAllAssetsApi(
      isActive,
      (page - 1) * 50,
      page * 50,
      status,
    );
    return response;
  },
);

export const searchAssets = createAsyncThunk(
  'users/searchassets',
  // @ts-ignore
  async ({isActive = null, key = '', status = 0}) => {
    const response = await searchAssetsApi(isActive, key, status);
    return response;
  },
);

export const fetchNewAssets = createAsyncThunk(
  'users/fetchassets',
  // @ts-ignore
  async ({isActive = null, s = 0, l = 1, page = 0}) => {
    const response = await getNewAssetsApi({s: (page - 1) * 50, l: page * 50});
    return response;
  },
);

export const fetchBriefAssets = createAsyncThunk(
  'users/fetchbriefassets',
  // @ts-ignore
  async ({briefId}) => {
    const response = await getBriefAssetsApi(briefId);
    return response;
  },
);

const initialState = {
  assets: [],
  loading: true,
  hasMore: false,
  totalResults: 0,
};

const assetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    clearAssets: () => {
      return initialState;
    },
    deleteAsset: (state, action) => {
      console.log(action.payload);
      if (state.assets?.length) {
        const filteredassets = state.assets?.filter(
          asset => asset.id !== action.payload.id,
        );

        state.totalResults -= 1;
        state.assets = filteredassets;
      }
    },
    updateAsset: (state, action) => {
      const updatedasset = action.payload;
      if (state.assets) {
        const updatedassets = state.assets?.map(asset => {
          if (asset.id === updatedasset.id) {
            return updatedasset;
          }
          return asset;
        });

        state.assets = updatedassets;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAssets.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        console.log(data);
        state.loading = false;
        state.assets = data;
      })
      .addCase(searchAssets.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        console.log(data);
        state.loading = false;
        state.assets = data;
      })
      .addCase(fetchBriefAssets.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        console.log(data);
        state.loading = false;
        state.assets = data;
      })
      .addCase(fetchAssets.pending, state => {
        state.loading = true;
      })
      .addCase(fetchAssets.rejected, state => {
        state.loading = false;
      });
  },
});

const {actions, reducer} = assetSlice;

// Reducer
export default reducer;

export const {clearAssets, updateAsset, deleteAsset} = actions;
