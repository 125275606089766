import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import DropDownInput from '../input/DropDownInput';

export default function RHPicker({
  name,
  width = '346px',
  height = '32px',
  ...inputProps
}) {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => {
        return (
          <DropDownInput
            width={width}
            height={height}
            {...field}
            {...inputProps}
            errorMessage={error?.message || ''}
            //   @ts-ignore
            setValue={e => field.onChange(e.target.value)}
          />
        );
      }}
    />
  );
}
