import React, {useEffect, useState, useCallback, useRef} from 'react';
import {DataGrid, GridPagination} from '@mui/x-data-grid';
import './datatable.scss';
import MuiPagination from '@mui/material/Pagination';
import SearchInput from 'component/input/SearchInput';
import {getTotalUsersApi, downloadUsersApi} from 'services/Users';
import {fetchUsers, searchUsers} from 'store/slice/usersSlice';
import {dispatch, useSelector} from 'store/store';
import debounce from 'lodash/debounce';
import SecondaryButton from 'component/buttons/SecondaryButton';
import RHFDateTime, {convertLocalToUTCDate} from 'component/forms/RHFDateTime';
import {AgGridReact} from 'ag-grid-react';
import ReactDatePicker from 'react-datepicker';
import {NavConstant} from 'routes/NavConstant';
import {useNavigate} from 'react-router-dom';
import {HeadLabel} from 'component/HeadLabel';
import {ArrowCircleLeftOutlined} from '@mui/icons-material';
import {dateComparator} from 'helper/comparator';

export default function ProShootersDataTable({columns = []}) {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const [totalUsers, setTotalUsers] = useState(0);
  const [search, setSearch] = useState('');

  const handleTotalUsers = async () => {
    try {
      const res = await getTotalUsersApi();
      setTotalUsers(parseInt(res?.data));
    } catch (error) {
      console.log(error);
    }
  };

  const searchEvent = value => {
    setSearch(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce((text: string) => {
      if (text === '') {
        handleTotalUsers();
        dispatch(fetchUsers({pro: true}));
      } else {
        dispatch(searchUsers({key: text, pro: true}));
        setTotalUsers(0);
      }
    }, 500),
    [],
  );

  const {users, loading} = useSelector(state => state?.users);

  const [customCols] = useState([
    // "profileimage": "",
    // "bio": "",
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
      // renderCell: params =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      field: 'id',
      headerName: 'UID',
      width: 200,
    },
    {
      field: 'created',
      headerName: 'Created',
      width: 180,
      valueGetter: row => {
        const date = new Date(row?.data?.created);
        return date?.toLocaleDateString('en-GB');
        // + ' ' + date?.toLocaleTimeString()
      },
      comparator: dateComparator,
    },
    {field: 'email', headerName: 'Email Id', width: 200},
    {field: 'firstname', headerName: 'First Name', width: 150},
    {field: 'lastname', headerName: 'Last Name', width: 150},
    {field: 'mobile', headerName: 'Mobile no', width: 150},
    {
      field: 'city',
      headerName: 'City',
      width: 150,
      valueGetter: row => {
        return row?.data?.location?.city;
      },
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      valueGetter: row => {
        return row?.data?.location?.state;
      },
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 150,
      valueGetter: row => {
        return row?.data?.location?.country;
      },
    },

    {
      field: 'created',
      headerName: 'Last Login',
      width: 180,
      valueGetter: row => {
        let date;
        date = new Date(row?.data?.userdata?.created);

        if (!row?.data?.userdata?.created) {
          date = new Date(row?.data?.created);
        }
        return date?.toLocaleDateString('en-GB');
        // + ' ' + date?.toLocaleTimeString()
      },
      comparator: dateComparator,
    },
  ]);

  const gridRef = useRef();

  function Pagination({page, onPageChange, className}) {
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={parseInt(totalUsers / 20) + 1}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    );
  }

  function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  const downloadFile = async () => {
    var res;
    try {
      res = await downloadUsersApi({
        from: start,
        to: end,
        pro: true,
      });
      console.log(res);
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Shotwot_Pro_Users.csv');
      // document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.log(error);
    }
  };

  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  useEffect(() => {
    handleTotalUsers();
    dispatch(
      fetchUsers({
        from: convertLocalToUTCDate(start),
        to: convertLocalToUTCDate(end),
        pro: true,
      }),
    );

    return () => {};
  }, [start, end]);

  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 15,
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 15,
            alignItems: 'center',
          }}>
          <ArrowCircleLeftOutlined
            onClick={() => navigate(-1)}
            style={{
              // marginLeft: 20,
              cursor: 'pointer',
            }}
          />
          <HeadLabel label={'Pro Shooters'} />
        </div>
        <SecondaryButton
          isLoading={loading}
          buttonLabel={'Export'}
          onClick={downloadFile}
          // @ts-ignore
          type="submit"
        />

        {/* <ViewAllButton /> */}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: 10,
        }}>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 10,
          }}>
          <div style={{zIndex: 100}}>
            <ReactDatePicker
              placeholderText="Select Start date"
              onChange={setStart}
              selected={start}
              showIcon
              closeOnScroll={true}
            />
          </div>
          <div style={{zIndex: 100}}>
            <ReactDatePicker
              placeholderText="Select End date"
              onChange={setEnd}
              selected={end}
              showIcon
              closeOnScroll={true}
            />
          </div>
        </div>

        <SearchInput search={search} setSearch={searchEvent} />
      </div>
      {/* <div
        style={{
          height: '100%',
          width: '100%',
          borderRadius: '20px',
          minHeight: '26%',
          padding: '20px 20px',
          backgroundColor: '#C6C6C654',
          marginTop: 20,
          boxShadow: '1px 2px 3px #00000040',
        }}>
        <DataGrid
          style={{
            border: 'none',
          }}
          pagination
          slots={{
            pagination: CustomPagination,
          }}
          rows={users || []}
          columns={customCols}
          pageSizeOptions={[20]}
          slotProps={{
            toolbar: {
              showQuickFilter: false,
            },
          }}
          rowCount={totalUsers}
          paginationMode="server"
          onPaginationModelChange={i => {
            if (i.page === paginationModel.page + 1) {
              dispatch(
                fetchUsers({
                  created: users[users?.length - 1]?.created,
                  skip: 0,
                }),
              );
            } else {
              dispatch(fetchUsers({skip: i.page}));
            }
            setPaginationModel(i);
          }}
          loading={loading}
          paginationModel={paginationModel}
          density="compact"
        />
      </div> */}
      <div
        className={'ag-theme-quartz'}
        style={{width: '100%', height: '250vh'}}>
        <AgGridReact
          ref={gridRef}
          defaultColDef={{resizable: true, sortable: true, filter: true}}
          readOnlyEdit={true}
          getRowId={params => params.data.id}
          // onCellEditRequest={onCellEditRequest}
          onRowClicked={p => {
            // window.open(`${window.location.origin}/users/${p?.data?.id}`);
            navigate(`${NavConstant.USER_PROFILE}/${p?.data?.id}`);
          }}
          rowData={users || []}
          columnDefs={customCols}
          pagination={true}
          paginationPageSize={50}
          rowSelection="multiple"
          scrollbarWidth={9}
          alwaysShowHorizontalScroll={true}
          alwaysShowVerticalScroll={true}
        />
      </div>
    </>
  );
}
