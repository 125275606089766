import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

export default function RHFTextInputField({
  name,
  register,
  width = '475px',
  height = '100px',
  ...inputProps
}) {
  const {control} = useFormContext();

  return (
    <Controller
      defaultValue={''}
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              color: '#FC3CB2',
              fontSize: '12px',
            }}>
            {error ? error.message : null}
            <textArea
              style={{
                width,
                height,
                padding: '8px 12px',
                boxSizing: 'border-box',
                border: '0px #B0B8C4',
                borderRadius: '5px',
                backgroundColor: 'white',
                fontSize: '14px',
                resize: 'none',
              }}
              {...field}
              {...inputProps}
              onChange={field.onChange}
              {...register(name, {required: true})}
            />
          </div>
        );
      }}
    />
  );
}
