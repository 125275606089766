export const CircleTick = ({value}) => (
  <span
    style={{
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      alignItems: 'center',
    }}>
    {
      <img
        alt={`${value}`}
        src={`https://www.ag-grid.com/example-assets/icons/${
          value ? 'tick-in-circle' : 'cross-in-circle'
        }.png`}
        style={{width: 'auto', height: 'auto'}}
      />
    }
  </span>
);
