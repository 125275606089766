import React, {useState, useEffect, useCallback} from 'react';
import DashboardLayout from 'component/templates/DashboardLayout';
import {AgGridReact} from 'ag-grid-react'; // React Grid Logic
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import {clearBriefs, fetchBriefs, updateBrief} from 'store/slice/briefSlice';
import {dispatch, useSelector} from 'store/store';
import './datatable.scss';
import SearchInput from 'component/input/SearchInput';
import {CircleTick} from 'component/CircleTick';
import {
  addAssetsApi,
  updateActiveBriefAPI,
  updateBriefApi,
} from 'services/Brief';
import {Link, useNavigate} from 'react-router-dom';
import {NavConstant} from 'routes/NavConstant';
import {useRef} from 'react';
import {
  IconButton,
  Input,
  InputAdornment,
  LinearProgress,
  Pagination,
  Typography,
} from '@mui/material';
import {GridSearchIcon} from '@mui/x-data-grid';
import {fetchAssets} from 'store/slice/assetSlice';
import {countAllAssetsApi, createAssetApi} from 'services/Asset';
import {Edit} from 'component/Edit';
import {HeadLabel} from 'component/HeadLabel';
import {
  ArrowCircleLeftOutlined,
  Cancel,
  CloudSync,
  CloudUpload,
  RestartAlt,
} from '@mui/icons-material';
import AssetForm from 'component/forms/comman/AssetForm';
import {countAllApplicationsApi, getApprovedApi} from 'services/Application';
import {ActionAsset} from 'component/ActionAsset';
import {toast} from 'react-toastify';
import {GridApi} from 'ag-grid-community';
import ReactModal from 'react-modal';
import Modal from 'react-modal';
import SecondaryButton from 'component/buttons/SecondaryButton';
import axios from 'axios';

const customStyles = {
  content: {
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0px',
    padding: -20,
    // backdropFilter: 'blur(1000px)',

    overlay: {
      backdropFilter: 'blur(10000px)',
    },
  },
};

Modal.setAppElement('#root');

const UploadAssets = () => {
  function LinkComponent(props) {
    return (
      <Link to={`${NavConstant.BRIEF}/${props.value}`}>{props.value}</Link>
    );
  }

  const [current, setCurrent] = useState(null);
  const [upload, setUpload] = useState(false);
  const [images, setImages] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalbriefs, setTotalBriefs] = useState(0);

  function LinkComponentType(props) {
    switch (props?.value) {
      case 1:
        return 'Images';
      case 2:
        return 'Videos';
        break;
      case 3:
        return 'Audio';
      default:
        break;
    }
  }

  const inputFiles = useRef(null);

  const handleClick = () => {
    inputFiles.current.click();
  };

  const onChangeFile = async event => {
    event.stopPropagation();
    event.preventDefault();
    var files = event.target.files;

    if (files) {
      let filtered = Array.from(files)?.filter(f => {
        for (let i of images) {
          if (f?.name == i?.name) return false;
        }
        return true;
      });
      console.log(filtered);

      setImages([...images, ...filtered]);
      // const mediainfo = await mediaInfoFactory();
      // const result = mediainfo.analyzeFile(filtered[0]);
      // console.log(result);
      // mediainfo.close();
      // console.log(window?.scre);
    }
  };

  // Column Definitions: Defines & controls grid columns.
  // @ts-ignore
  const [colDefs, setColDefs] = useState([
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
      // renderCell: params =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      field: 'id',
      headerClass: 'super-app-theme--header',
      headerName: 'Brief ID',
      // cellRenderer: LinkComponent,
      width: 200,
    },
    {
      field: 'assetTitle',
      headerName: 'Brief Title',
      headerClass: 'super-app-theme--header',
      width: 150,
      valueGetter: row => row?.data?.brief?.title,
    },
    {
      field: 'type',
      headerName: 'Brief Type',

      width: 100,
      valueGetter: row => row?.data?.brief?.type,
    },
    {
      field: 'shooter',
      headerName: 'User Id',
      headerClass: 'super-app-theme--header',
      width: 150,
      valueGetter: row => row?.data?.userId,
    },
    {
      field: 'shooter',
      headerName: 'User Email/Mobile No',
      headerClass: 'super-app-theme--header',
      width: 180,
      valueGetter: row => row?.data?.user?.email || row?.data?.user?.mobile,
    },

    {
      field: 'shooter',
      headerName: 'Total Assets',
      headerClass: 'super-app-theme--header',
      width: 80,
      valueGetter: row => row?.data?.totalAssets,
    },

    {
      field: 'status',
      width: 100,
      valueGetter: row => {
        switch (row?.data?.adminAssets) {
          case false:
            return 'Pending';
          case true:
            return 'Uploaded';
        }
      },
    },
    {
      field: 'edit',
      headerName: 'Action',
      width: 80,
      cellRenderer: row => {
        return (
          <>
            <ActionAsset
              id={row?.data?.id}
              current={current}
              upload={upload}
              images={images}
            />
          </>
        );
      },
      valueGetter: params => {
        return params?.data?.active;
      },
      onCellClicked: e => {
        if (!upload) {
          // handleClick();
          setImages([]);
          setTotal(0);
          setCurrent(e?.data);
          abortRef.current = false;
          setIsOpen(true);
        }
      },
    },
    {
      field: 'edit',
      headerName: 'Last Modified',
      width: 120,
      valueGetter: params => {
        const date = new Date(params?.data?.content?.updated);
        return date?.toLocaleDateString('en-GB');
      },
      onCellClicked: e => {
        // openModal();
      },
    },
  ]);

  // @ts-ignore

  const [page, setPage] = useState(1);
  const [resolution, setResolution] = useState();
  const [duration, setDuration] = useState();

  const [applications, setApplications] = useState([]);

  useEffect(() => {
    // @ts-ignore

    countAllApplicationsApi().then(r => {});
    return () => {
      dispatch(clearBriefs());
    };
  }, []);

  useEffect(() => {
    getApprovedApi((page - 1) * 50, page * 50).then(res => {
      setApplications(res?.data);
    });
    setCurrent(null);
  }, [page]);

  const add = async () => {
    let rawPayload = {
      id: current?.id,
      totalAssets: current?.totalAssets + images?.length,
    };

    try {
      const res = await addAssetsApi(rawPayload);
      if (res?.success) {
        toast.success('files are uploading');
      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setCurrent(null);
    }
  };

  useEffect(() => {
    let tot = 0;
    for (let f of images) {
      tot += f?.size;
    }
    setTotal(tot);
  }, [images]);

  const [progress, setProgress] = useState(0);

  const api = async (file, url) => {
    await axios({
      url: url,
      method: 'put',
      data: file,
      headers: {
        'Content-Type': file?.type,
        'Content-Encoding': 'base64',
      },
      onUploadProgress: function (progressEvent) {
        console.log(progressEvent);
        setProgress(prev => prev + progressEvent?.bytes);
      },
      validateStatus: function (status) {
        return status <= 300; // Resolve only if the status code is less than 500
      },
    });
  };
  const abortRef = React.useRef(false);

  const onSubmit = async () => {
    setUpload(true);

    try {
      for (let i in images) {
        if (abortRef.current) {
          break;
        }

        try {
          const res = await createAssetApi({
            file: {
              name: images[i]?.name,
              type: images[i]?.type,
              size: images[i]?.size,
              // fps,
            },
            asset: {
              briefId: current?.briefId,
              userId: current?.userId,
              applicationId: current?.id,
              brief: {
                title: current?.brief?.title + '-',
              },
            },
            name: current?.user?.email || current?.user?.mobile + '@shotwot',
          });
          if (res?.data?.url) {
            try {
              const res2 = await api(images[i], res?.data?.url);
            } catch (error) {
              toast.error(error);
            }
          }
        } catch (error) {
          toast.error(error);
        }
      }
    } catch (error) {
      toast.error(error);
    } finally {
      closeModal();
      toast.success('flex uploaded successfully');
      if (!abortRef.current) window.location.reload();
      setUpload(false);
    }
  };

  useEffect(() => {
    if (current != null && upload) {
      add();
    }
  }, [upload]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  async function onCellEditRequest(event) {
    const oldData = event.data;
    const field = event.colDef.field;
    const newData = {...oldData};
    newData[field] = event.newValue;
    try {
      const res = await updateActiveBriefAPI(newData);
      // @ts-ignore
      if (res?.success) {
        dispatch(updateBrief(res?.data));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const gridRef = useRef();
  const navigate = useNavigate();

  const onFilterTextBoxChanged = useCallback(e => {
    gridRef.current.api.setGridOption('quickFilterText', e);
  }, []);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  const [search, setSearch] = useState('');

  function afterOpenModal() {}

  function closeModal() {
    if (!upload) {
    } else {
      abortRef.current = true;
      setIsOpen(false);
    }
  }

  return (
    <DashboardLayout>
      <script
        type="text/javascript"
        src="https://unpkg.com/mediainfo.js"></script>
      <div>
        <ReactModal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}>
          <div
            style={{
              display: 'flex',
              borderRadius: '6px',
              minHeight: '450px',
              padding: '20px 20px',
              backgroundColor: '#C6C6C654',
              boxShadow: '1px 2px 3px #00000040',
              marginTop: 20,
              width: 916,
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            {progress !== 0 ? (
              <>
                <LinearProgress
                  variant="determinate"
                  value={parseInt((progress * 100) / total + '')}
                  sx={{
                    backgroundColor: '#D9D9D9',
                    span: {
                      background: 'linear-gradient(to right, #FC3CB2, #00C0FB)',
                    },
                    height: '10px',
                    'border-radius': '25px',
                    width: '100%',
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                />
                <Typography
                  style={{
                    position: 'absolute',
                    color: 'black',
                    top: 45,
                    transform: 'translateX(-50%)',
                    fontSize: 16,
                  }}>
                  {parseInt((progress * 100) / total + '')}%
                </Typography>
              </>
            ) : null}
            <div
              style={{
                height: '350px',
                width: '900px',
                border: '1px dashed #7729E5',
                borderRadius: 4,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                padding: 10,
              }}
              onClick={handleClick}>
              <input
                type="file"
                style={{display: 'none'}}
                id="upload"
                ref={inputFiles}
                multiple
                onChange={onChangeFile}
              />
              {'+ Add Files to Upload'}
              <ul
                style={{
                  // flexWrap: 'wrap',
                  display: 'flex',
                  // listStyleType: 'none',
                  gap: 20,
                  flexDirection: 'column',
                  // marginTop: 190,
                  overflow: 'scroll',

                  width: '900px',
                }}
                id="assets">
                {images?.map((i, index) => (
                  <li
                    id={index?.toString()}
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      padding: 10,
                      justifyContent: 'space-between',
                      backgroundColor: 'white',
                      borderRadius: 6,
                      width: '840px',
                    }}>
                    {i?.name}
                    <Cancel
                      onClick={e => {
                        setImages(prev => prev?.filter(img => img !== i));
                        e?.stopPropagation();
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
                gap: 10,
              }}>
              <button
                // isLoading={loading}
                // @ts-ignore
                type="button"
                onClick={closeModal}
                style={{
                  backgroundColor: '#8A8395',
                  // borderColor: '#D9D9D9',
                  borderRadius: 10,
                  borderWidth: 0,
                  padding: '0 8px',
                  color: 'white',
                  cursor: 'pointer',
                }}
                id="new">
                Cancel
              </button>
              <SecondaryButton
                buttonLabel={'Start Upload'}
                // @ts-ignore
                type="button"
                onClick={async () => {
                  await onSubmit();
                }}
                color={'#7729E5'}
                labelColor="white"
              />
            </div>
          </div>
        </ReactModal>

        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 15,
            alignItems: 'center',
          }}>
          <ArrowCircleLeftOutlined
            onClick={() => navigate(-1)}
            style={{
              // marginLeft: 20,
              cursor: 'pointer',
            }}
          />
          <HeadLabel label={'Upload Assets'} />
        </div>

        <div
          style={{
            marginTop: '10px',
            width: 1419,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: 10,
              width: 1419,
              marginLeft: -20,
            }}>
            <SearchInput
              search={search}
              setSearch={e => {
                setSearch(e);

                onFilterTextBoxChanged(e);
              }}
            />

            <p>
              {(page - 1) * 50 + '  -  ' + 50 * page + '  of  ' + totalbriefs}
            </p>
          </div>
          <div
            className={'ag-theme-quartz'}
            style={{width: '1419px', height: '150vh'}}>
            <AgGridReact
              ref={gridRef}
              defaultColDef={{...defaultColDef}}
              suppressPaginationPanel={true}
              readOnlyEdit={true}
              getRowId={params => params.data.id}
              onCellEditRequest={onCellEditRequest}
              rowData={applications}
              columnDefs={colDefs}
              pagination={true}
              paginationPageSize={50}
              rowSelection="multiple"
              scrollbarWidth={9}
              alwaysShowHorizontalScroll={true}
              alwaysShowVerticalScroll={true}
            />
          </div>
          <Pagination
            count={parseInt(totalbriefs / 50 + '')}
            shape="rounded"
            onChange={(event, p) => {
              console.log(p);
              setPage(p);
            }}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default UploadAssets;
