import {
  ArrowCircleLeftOutlined,
  ExpandCircleDownTwoTone,
} from '@mui/icons-material';
import CustomTab from 'component/CustomTab';
import HeadingComp from 'component/HeadingComp';
import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useCallback, useState} from 'react';
import CreatePlaylist from './components/CreatePlaylist';
import {Navigate, useNavigate} from 'react-router-dom';
import {HeadLabel} from 'component/HeadLabel';
import AllPlaylist from './components/AllPlaylist';
import CreateEvent from './components/CreateEvent';
import AllEvent from './components/AllEvent';
import CreateCheatSheet from './components/CreateCheatSHeet';
import AllCheatSheet from './components/AllCheatSheet';

export default function CheatSheets() {
  const tabs = [
    {
      tabId: 1,
      name: 'All Cheat Sheets',
    },
    {
      tabId: 2,
      name: 'Create Cheat Sheet',
    },
    // {
    //   tabId: 2,
    //   name: 'Edit Playlist',
    // },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [playlist, setPlaylist] = useState({});

  const handleTabClick = props => {
    console.log(props);
    if (props) {
      setCurrentTab(props);
      setPlaylist({});
    }
  };
  const navigate = useNavigate();

  const HandlView = useCallback(() => {
    switch (currentTab?.tabId) {
      case 2:
        return <CreateCheatSheet playlist={playlist} />;

      default:
        return (
          <AllCheatSheet
            setCurrent={d => {
              setCurrentTab(tabs[1]);
              setPlaylist(d);
            }}
          />
        );
    }
  }, [currentTab]);

  return (
    <DashboardLayout>
      <div>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 15,
            alignItems: 'center',
            paddingBottom: 12,
          }}>
          <ArrowCircleLeftOutlined
            onClick={() => {
              if (currentTab?.tabId == 2) {
                setCurrentTab(tabs[0]);
                return;
              }
              navigate(-1);
            }}
            style={{
              cursor: 'pointer',
            }}
          />
          <HeadLabel label={'Pro Shooter DashBoard | CheatSheets'} />
        </div>
        {/* <PrimaryDropDown /> */}

        <CustomTab
          currentTab={currentTab}
          handleClick={handleTabClick}
          tabList={tabs}
        />

        <HandlView />
      </div>
    </DashboardLayout>
  );
}
