// @ts-nocheck
import {
  ArrowBack,
  ArrowCircleLeft,
  ArrowCircleLeftOutlined,
  Download,
} from '@mui/icons-material';
import BackButtonSvg from 'assets/BackButtonSvg';
import CustomButton from 'component/buttons/CustomButton';
import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  briefApplicationActionApi,
  getbriefApplicationApi,
  getbriefApplicationAssetsApi,
} from 'services/Brief';

export default function BriefSubmissionPage() {
  const {id: briefApplicationId} = useParams(); // Use useParams to access the route parameters
  const [isLoading, setIsLoading] = useState({});
  const navigate = useNavigate();

  const [applicationData, setApplicationData] = useState();
  const [assets, setAssets] = useState([]);
  const [release, setRelease] = useState();
  const [resolution, setResolution] = useState('0');
  const [duration, setDuration] = useState(0);
  const [current, setCurrent] = useState(-1);
  const [size, setSize] = useState(0);

  async function download(dataurl, filename) {
    const link = document.createElement('a');
    link.href = dataurl;
    link.download = filename;
    link.click();
  }

  const briefActions = [
    {
      id: 1,
      name: 'Submitt',
      status: 5,
      bgColor: '#9550C4',
    },
    {
      id: 2,
      name: 'Shortlist',
      status: 6,
      bgColor: '#FFA500',
    },
    {
      id: 3,
      name: 'Accept',
      status: 8,
      bgColor: '#3B981A',
    },
    {
      id: 4,
      name: 'Reject',
      status: 7,
      bgColor: '#B50000',
    },
  ];
  useEffect(() => {
    if (briefApplicationId) {
      getbriefApplicationApi(briefApplicationId).then(res => {
        if (res?.data) {
          console.log(res?.data);
          setApplicationData(res?.data);
          getbriefApplicationAssetsApi(
            res?.data?.briefId,
            res?.data?.userId,
          ).then(sub => {
            if (sub?.data) {
              setAssets(sub?.data?.Assets);
              setRelease(sub?.data?.Release);
            }
          });
        }
      });
    }
  }, [briefApplicationId]);

  const HeadLabel = ({label}) => {
    return (
      <div
        style={{
          fontFamily: 'Poppins',
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '30px',
          letterSpacing: '0em',
          textAlign: 'left',
        }}>
        {label}
      </div>
    );
  };

  const CustomContainer = ({children}) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '20px',
          gap: 10,
          minHeight: '5%',
          height: 'auto',
          padding: '20px 20px',
        }}>
        {children}
      </div>
    );
  };
  const BtnContainer = ({children}) => {
    return (
      <div
        style={{
          display: 'flex',

          borderRadius: '20px',
          gap: 10,
          minHeight: '5%',
          height: 'auto',
          padding: '20px 20px',

          justifyContent: 'end',
        }}>
        {children}
      </div>
    );
  };
  const LabelPair = ({
    leftLabel,
    rightLabel,
    lineHeight = '24px',
    rightcolor,
    width = '30%',
    rigthLabelWidth = '70%',
    icon,
  }) => {
    const leftLabelStyle = {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight,
      letterSpacing: '0em',
      textAlign: 'left',
      width,
    };

    const rightLabelStyle = {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '24px',
      width: rigthLabelWidth,
      ...rightcolor,
    };

    return (
      <div style={{display: 'flex', width: '100%', marginTop: 10}}>
        {/* @ts-ignore */}
        <span style={leftLabelStyle}>{leftLabel}</span>

        {leftLabel?.toLowerCase().includes('note') ? (
          <span
            style={{
              ...rightLabelStyle,
              border: '2px solid #45454550',
              borderRadius: 8,
              padding: 12,
            }}>
            {rightLabel}
          </span>
        ) : Array.isArray(rightLabel) ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            {rightLabel?.map(data => {
              return <span style={{...rightLabelStyle}}>{data}</span>;
            })}
          </div>
        ) : (
          <>
            <span style={{...rightLabelStyle}}>{rightLabel}</span>
            {icon}
          </>
        )}
      </div>
    );
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleActions = async data => {
    setIsLoading(prevLoading => ({...prevLoading, [data.id]: true}));
    let rawPayload = {
      status: data?.status,
      id: briefApplicationId,
      userId: applicationData?.user?.id,
      briefId: applicationData?.brief?.id,
    };

    try {
      const res = await briefApplicationActionApi(rawPayload);
      if (res?.success) {
        setApplicationData(prev => {
          return {...prev, status: res?.data?.status};
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(prevLoading => ({...prevLoading, [data.id]: false}));
    }
  };

  useEffect(() => {
    if (assets?.length > 0) {
      setCurrent(0);
      let totalSize = 0;
      for (let i of assets) {
        totalSize += i?.size;
      }
      setSize(totalSize);
    }
  }, [assets]);

  const getStatus = () => {
    switch (applicationData?.status) {
      case 1:
        return 'Applied';
      case 2:
        return 'Shortlisted';
      case 3:
        return 'Rejected';
      case 4:
        return 'Accepted';
      case 5:
        return 'Content Submitted';
      case 6:
        return 'Content Shortlisted';
      case 7:
        return 'Content Rejected';
      case 8:
        return 'Content Approved';
      default:
        return '';
    }
  };

  return (
    <DashboardLayout>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 15,
          alignItems: 'center',
        }}>
        <ArrowCircleLeftOutlined
          onClick={handleGoBack}
          style={{
            marginLeft: 20,
            cursor: 'pointer',
          }}
        />
        <HeadLabel label={'Shooter Details'} />
      </div>

      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flex: 1,
          // gap: 20,
        }}>
        <div
          style={{
            width: 401,
            // overflow: 'hidden',
            // marginRight: 20,
          }}>
          <CustomContainer>
            {/* <LabelPair
                leftLabel={'UserName'}
                rightLabel={applicationData?.user?.username}
              /> */}

            <LabelPair
              leftLabel={'User Id'}
              rightLabel={
                <a
                  style={{}}
                  href={`${window.location.origin}/users/${applicationData?.user?.id}`}>
                  {applicationData?.user?.id}
                </a>
              }
            />
            <LabelPair
              leftLabel={'Email'}
              rightLabel={applicationData?.user?.email}
            />
            <LabelPair
              leftLabel={'Application'}
              width="25%"
              rightLabel={
                <a
                  href={`/brief-application/${briefApplicationId}`}
                  style={{
                    marginLeft: 20,
                  }}>
                  Application Link
                </a>
              }
            />

            <LabelPair
              leftLabel={'Brief Id'}
              rightLabel={
                <a
                  style={{}}
                  href={`${window.location.origin}/brief/${applicationData?.brief?.id}`}>
                  {applicationData?.brief?.id}
                </a>
              }
            />

            <LabelPair
              leftLabel={'Brief Title'}
              rightLabel={applicationData?.brief?.title}
            />
            <LabelPair
              leftLabel={'Assets'}
              rightLabel={applicationData?.brief?.assets}
            />
            <LabelPair
              leftLabel={'Pro Shooter'}
              rightLabel={
                applicationData?.user?.pro ? 'Subscribed' : 'UnSubscribed'
              }
            />
            <LabelPair
              leftLabel={'Status'}
              rightLabel={getStatus()}
              rightcolor={
                {
                  // color: applicationData?.status == 6 ? 'red' : 'green',
                  // fontWeight: 700,
                }
              }
            />
          </CustomContainer>

          <CustomContainer>
            <HeadLabel label={'Submission Details '} />

            <LabelPair
              width={'100%'}
              leftLabel={'Date'}
              rightLabel={new Date(applicationData?.created)?.toLocaleString(
                'en-US',
                {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                },
              )}
            />
            <LabelPair
              width={'100%'}
              leftLabel={'Time'}
              rightLabel={new Date(
                applicationData?.created,
              )?.toLocaleTimeString('en-US', {
                // weekday: 'long',
                hour: '2-digit',
                minute: '2-digit',
              })}
            />
            <LabelPair
              leftLabel={'Assets'}
              rightLabel={assets?.length}
              width={'100%'}
            />
            <LabelPair leftLabel={'Size'} rightLabel={size} width={'100%'} />
            <LabelPair
              width={'100%'}
              rigthLabelWidth={'55%'}
              leftLabel={'Release Attached: '}
              rightLabel={release?.length}
              icon={
                <Download
                  style={
                    {
                      // paddingLeft: 25,
                    }
                  }
                  onClick={async () => {
                    for (let i of release) {
                      // await download(i?.assetFile, '');
                      window.open(i?.assetFile);
                    }
                  }}
                />
              }
            />
          </CustomContainer>
          <BtnContainer>
            {briefActions
              ?.filter(d => d?.id !== 1)
              .map(data => {
                return (
                  <CustomButton
                    key={data?.id}
                    onClick={() => handleActions(data)}
                    buttonLabel={data?.name}
                    bgColor={data?.bgColor}
                    labelColor="#FFFFFF"
                    isLoading={isLoading[data.id]}
                  />
                );
              })}
          </BtnContainer>
        </div>
        <div
          style={{
            flexDirection: 'column',
            width: 720,
            gap: 12,
            display: 'flex',
          }}>
          <div
            style={{
              flexDirection: 'column',
              display: 'flex',
              // gap: 20,
              width: 720,
              backgroundColor: '#EDEDED',
              borderRadius: 8,
              padding: 8,
            }}>
            {!(applicationData?.brief?.type == 'Video') ? (
              <img
                id="imgTop"
                style={{
                  height: 405,
                  width: 720,
                  borderRadius: 8,
                  border: '1 solid black',
                  objectFit: 'cover',
                }}
                onLoad={e => {
                  setResolution(
                    e.currentTarget.naturalWidth +
                      '*' +
                      e.currentTarget.naturalHeight,
                  );
                }}
                src={assets[current]?.assetFile}
                onClick={() => {
                  document.getElementById('imgTop')?.requestFullscreen();
                }}
              />
            ) : (
              <video
                style={{
                  width: 720,
                  height: 405,
                  borderRadius: 8,
                }}
                key={current}
                controls={true}
                preload="metadata"
                onLoadedMetadataCapture={e => {
                  setResolution(
                    e.currentTarget.videoWidth +
                      '*' +
                      e.currentTarget.videoHeight,
                  );
                  setDuration(e.currentTarget.duration);
                }}>
                <source
                  src={
                    // 'https://shotwot-test.b-cdn.net/' +
                    assets[current]?.assetFile
                  }
                  // src="https://s3.eu-central-1.wasabisys.com/shotwotwasabitest/Warehouse%40cloud/Shotwot%20Originals/6602c01c67d9576b0e89e6f8/660d02b1734985e90355cbfd-2024-04-25/Asset/VID20240424134954.mp4?AWSAccessKeyId=J9BA04D5J9LHDCQ4URUX&Expires=1714047161&Signature=eTaV9bB4nf2Z23HT5xLA0635j2Q%3D"
                  //   type="video/mp4"
                />
              </video>
            )}
          </div>

          <div
            style={{
              // width: '40%',
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              listStyleType: 'none',
            }}>
            <p style={{fontFamily: 'Poppins', fontWeight: '600', fontSize: 14}}>
              Details |&nbsp;
            </p>
            <p style={{}}>Name &nbsp; </p>
            <p style={{fontFamily: 'Poppins', fontWeight: '600', fontSize: 14}}>
              {assets[current]?.assetTitle} | &nbsp;
            </p>
            <p style={{}}>Resolution &nbsp; </p>
            <p style={{fontFamily: 'Poppins', fontWeight: '600', fontSize: 14}}>
              {resolution} | &nbsp;
            </p>
            <p style={{}}>Duration &nbsp; </p>
            <p style={{fontFamily: 'Poppins', fontWeight: '600', fontSize: 14}}>
              {parseInt(duration)} sec | &nbsp;
            </p>
            <p style={{}}>Size &nbsp; </p>
            <p style={{fontFamily: 'Poppins', fontWeight: '600', fontSize: 14}}>
              {assets[current]?.size} | &nbsp;
            </p>
            <p style={{}}>Format &nbsp; </p>
            <p style={{fontFamily: 'Poppins', fontWeight: '600', fontSize: 14}}>
              {assets[current]?.assetTitle?.split('.')?.[1]} | &nbsp;
            </p>
            <p style={{}}>Frame Rate &nbsp; </p>
            <p style={{fontFamily: 'Poppins', fontWeight: '600', fontSize: 14}}>
              {assets[current]?.fps} &nbsp;
            </p>
          </div>
          <CustomButton
            // key={data?.id}
            onClick={() => {
              download(assets[current]?.assetFile, assets[current]?.assetTitle);
            }}
            buttonLabel={'Download'}
            bgColor={'#7729E5'}
            labelColor="#FFFFFF"
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              backgroundColor: 'rgba(198, 198, 198, 0.33)',
              borderRadius: 20,
              gap: 20,
              paddingTop: 20,
              paddingBottom: 30,
            }}>
            {/* <h4
              style={{
                justifyContent: 'flex-end',
                display: 'flex',
                marginRight: 50,
              }}>
              Release Attached: {release?.length}
            </h4> */}
            <ul
              style={{
                flexWrap: 'wrap',
                display: 'flex',
                listStyleType: 'none',
                gap: 20,
              }}
              id="assets">
              {assets?.map((asset, index) => {
                return (
                  <li
                    onClick={e => {
                      setCurrent(e?.currentTarget?.id);
                    }}
                    key={index?.toString()}
                    id={index}
                    style={{
                      height: '125px',
                      width: '100px',
                      borderWidth: 1,
                      borderColor: '#BFBFBF',
                    }}>
                    {!(applicationData?.brief?.type == 'Video') ? (
                      <img
                        style={{
                          height: '100px',
                          width: '100px',
                          objectFit: 'cover',
                        }}
                        src={asset?.assetFile}
                      />
                    ) : (
                      <video
                        style={{
                          height: '100px',
                          width: '100px',
                        }}>
                        <source src={asset?.assetFile} type="video/mp4" />
                      </video>
                    )}
                    <h6>{asset?.assetTitle}</h6>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
