import {AgGridReact} from 'ag-grid-react';
import CustomButton from 'component/buttons/CustomButton';
import PrimaryButton from 'component/buttons/PrimaryButton';
import SecondaryButton from 'component/buttons/SecondaryButton';
import DataTableComp from 'component/datatable/DataTableComp';
import {SingleImageUpload} from 'component/images/SingleImageUpload';
import DashboardLayout from 'component/templates/DashboardLayout';
import {constants} from 'helper/constant';
import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {NavConstant} from 'routes/NavConstant';
import {
  deleteBriefApplication,
  getBriefApplicationsApi,
  getBriefByIdApi,
  getBriefSaved,
  getBriefShortlisted,
  getBriefSubmissionsApi,
} from 'services/Brief';
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import {CircleTick} from 'component/CircleTick';
import {Action} from 'component/Action';
import {Delete} from 'component/Delete';
import {ArrowCircleLeftOutlined} from '@mui/icons-material';
import {dateComparator} from 'helper/comparator';

function BriefPage() {
  const gridRef = useRef();

  const {id} = useParams(); // Use useParams to access the route parameters
  const navigate = useNavigate();
  const [briefData, setBriefData] = useState();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize loading state to true

  const tabs = [
    {
      tabId: 1,
      name: 'Application',
    },
    {
      tabId: 2,
      name: 'Submission',
    },
    {
      tabId: 3,
      name: 'Saved',
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0]);

  useEffect(() => {
    getBriefByIdApi(id).then(res => {
      console.log(res);
      if (res?.data) {
        setBriefData(res?.data);
      }
      setLoading(false); // Set loading to false after brief data is fetched
    });
  }, []);

  useEffect(() => {
    if (currentTab?.tabId === 1) {
      getBriefApplicationsApi(id).then(res => {
        setApplications(res?.data);
      });
    } else if (currentTab?.tabId === 2) {
      getBriefSubmissionsApi(id).then(res => {
        setApplications(res?.data);
      });
    } else {
      getBriefSaved(id).then(res => {
        console.log(res);
        setApplications(res?.data);
      });
    }
  }, [currentTab]);

  const handleNav = props => {
    console.log(props);
    let data = applications?.filter(briefData => briefData?.id == props?.id);

    navigate(`${NavConstant.BRIEF_APPLICATION}/${props.id}`, {
      state: {data: 1},
    });
  };

  function BriefInfo(data) {
    const expiry = new Date(data?.expiry)?.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <div style={{width: '80%', paddingRight: 10}}>
          <p style={{fontWeight: '560'}}>Brief Id: {id}</p>
          <p>Brief Title: {data?.title}</p>
          <p className="label gap">Description: {data?.description}</p>
          <p className="label gap">Reward: {data?.reward}</p>
          <p className="label gap">Brief Type: {data?.type}</p>
          <p className="label gap">
            Activate On: {new Date(data?.created)?.toLocaleString()}
          </p>
          <p className="label gap">
            Status: {data?.isActive ? 'Active' : 'InActive'}
          </p>
          <p className="label gap">
            Featured: {data?.isFeatured ? 'Featured' : 'Not Featured'}
          </p>
          <p className="label gap">
            Expiry: {new Date(data?.expiry)?.toLocaleString()}
          </p>
        </div>
        <div>
          <SingleImageUpload
            image={constants.CDN + data?.cardImage}
            setImage={() => {}}
            style={{}}
            disabled={true}
          />
          <SecondaryButton
            onClick={() =>
              navigate(`${NavConstant.EDIT_BRIEF + '/' + id}`, {
                state: {data: data},
              })
            }
            buttonLabel={'Edit Brief'}
          />
        </div>
      </div>
    );
  }

  const briefApplicationsCol = [
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
    },
    {
      field: 'Date',
      headerName: 'Date',
      width: 100,
      valueGetter: params => {
        const date = new Date(params?.data?.created);
        return date?.toLocaleDateString('en-GB');
      },
      comparator: dateComparator,
    },
    {
      field: 'Time',
      headerName: 'Time',
      width: 100,
      valueGetter: params => {
        const date = new Date(params?.data?.created);
        return date?.toLocaleTimeString();
      },
    },
    {
      field: 'User Name',
      headerName: 'User Name',
      width: 200,
      onCellClicked: event => {
        navigate(`${NavConstant.USER_PROFILE}/${event?.data?.userId}`);
      },
      valueGetter: params => {
        return params?.data?.user?.username;
      },
    },
    {
      field: 'User Email',
      headerName: 'User Email',
      width: 200,
      valueGetter: params => {
        return params?.data?.user?.email;
      },
    },
    {
      field: 'Pro Shooter',
      headerName: 'Pro Shooter',
      width: 80,
      cellRenderer: CircleTick,

      valueGetter: params => {
        return params?.data?.user?.pro;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      valueGetter: params => {
        console.log(params?.data?.status);

        switch (params?.data?.status) {
          case 1:
            return 'Applied';
          case 3:
            return 'Rejected';
          case 2:
            return 'Shortlisted';
          case 4:
            return 'Accepted';
          case 5:
            return 'Content Submitted';
          case 6:
            return 'Content Shortlisted';
          case 7:
            return 'Content Rejected';
          case 8:
            return 'Content Approved';

          default:
            break;
        }
      },
    },
    {
      field: 'reel',
      headerName: 'Showreel',
      width: 120,
      valueGetter: params => {
        return params?.data?.reel?.[0]?.length ? 'Yes' : 'No';
      },
    },
    {
      field: 'Treatment',
      headerName: 'Treatment',
      width: 100,
      valueGetter: params => {
        return params?.data?.note?.length ? 'Yes' : 'No';
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 80,
      cellRenderer: Action,
      onCellClicked: event => {
        console.log(event);
        if (currentTab?.tabId === 1) {
          // window.location.replace(
          //   `${NavConstant.BRIEF_APPLICATION}/${event?.data?.id}`,
          // );
          navigate(`${NavConstant.BRIEF_APPLICATION}/${event?.data?.id}`);
          return;
        } else if (currentTab?.tabId === 2) {
          navigate(`${NavConstant.BRIEF_Submission}/${event?.data?.id}`);
          return;
        } else {
          navigate(`${NavConstant.BRIEF_APPLICATION}/${event?.data?.id}`);
          return;
        }
      },
      valueGetter: params => {
        return params?.data?.user?.pro;
      },
    },
    {
      field: 'Delete',
      headerName: 'Delete',
      width: 80,
      cellRenderer: Delete,
      onCellClicked: event => {
        deleteBriefApplication(event?.data?.id).then(res => {
          const filtered = applications?.filter(i => i?.id != event?.data?.id);
          setApplications(filtered);
        });
      },
      valueGetter: params => {
        return params?.data?.user?.pro;
      },
    },
    // {
    //   field: 'id',
    //   headerName: 'Id',
    //   width: 200,
    //   headerClass: 'super-app-theme--header',
    //   cellRenderer: LinkComponent,
    // },
  ];

  const briefSavedCol = [
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
    },
    {
      field: 'Date',
      headerName: 'Date',
      width: 100,
      valueGetter: params => {
        const date = new Date(params?.data?.action?.created);
        return date?.toLocaleDateString('en-GB');
      },
      comparator: dateComparator,
    },
    {
      field: 'Time',
      headerName: 'Time',
      width: 100,
      valueGetter: params => {
        const date = new Date(params?.data?.action?.created);
        return date?.toLocaleTimeString();
      },
    },
    {
      field: 'User Name',
      headerName: 'User Name',
      width: 200,
      onCellClicked: event => {
        window.open(`${window.location.origin}/users/${event?.data?.user?.id}`);
      },
      valueGetter: params => {
        return params?.data?.user?.id;
      },
    },
    {
      field: 'User Email',
      headerName: 'User Email',
      width: 200,
      valueGetter: params => {
        return params?.data?.user?.email;
      },
    },
    {
      field: 'Pro Shooter',
      headerName: 'Pro Shooter',
      width: 80,
      cellRenderer: CircleTick,

      valueGetter: params => {
        return params?.data?.user?.pro;
      },
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 130,
    //   valueGetter: params => {
    //     console.log(params?.data?.status);

    //     switch (params?.data?.status) {
    //       case 1:
    //         return 'Applied';
    //       case 3:
    //         return 'Rejected';
    //       case 2:
    //         return 'Shortlisted';
    //       case 4:
    //         return 'Accepted';
    //       case 5:
    //         return 'Content Submitted';
    //       case 6:
    //         return 'Content Shortlisted';
    //       case 7:
    //         return 'Content Rejected';
    //       case 8:
    //         return 'Content Approved';

    //       default:
    //         break;
    //     }
    //   },
    // },
    // {
    //   field: 'reel',
    //   headerName: 'Showreel',
    //   width: 120,
    //   valueGetter: params => {
    //     return params?.data?.reel?.[0]?.length ? 'Yes' : 'No';
    //   },
    // },
    // {
    //   field: 'Treatment',
    //   headerName: 'Treatment',
    //   width: 100,
    //   valueGetter: params => {
    //     return params?.data?.note?.length ? 'Yes' : 'No';
    //   },
    // },
    // {
    //   field: 'Action',
    //   headerName: 'Action',
    //   width: 80,
    //   cellRenderer: Action,
    //   onCellClicked: event => {
    //     console.log(event);
    //     if (currentTab?.tabId === 1) {
    //       // window.location.replace(
    //       //   `${NavConstant.BRIEF_APPLICATION}/${event?.data?.id}`,
    //       // );
    //       navigate(`${NavConstant.BRIEF_APPLICATION}/${event?.data?.id}`);
    //       return;
    //     } else if (currentTab?.tabId === 2) {
    //       navigate(`${NavConstant.BRIEF_Submission}/${event?.data?.id}`);
    //       return;
    //     } else {
    //       navigate(`${NavConstant.BRIEF_APPLICATION}/${event?.data?.id}`);
    //       return;
    //     }
    //   },
    //   valueGetter: params => {
    //     return params?.data?.user?.pro;
    //   },
    // },
    // {
    //   field: 'Delete',
    //   headerName: 'Delete',
    //   width: 80,
    //   cellRenderer: Delete,
    //   onCellClicked: event => {
    //     deleteBriefApplication(event?.data?.id).then(res => {
    //       const filtered = applications?.filter(i => i?.id != event?.data?.id);
    //       setApplications(filtered);
    //     });
    //   },
    //   valueGetter: params => {
    //     return params?.data?.user?.pro;
    //   },
    // },
    // {
    //   field: 'id',
    //   headerName: 'Id',
    //   width: 200,
    //   headerClass: 'super-app-theme--header',
    //   cellRenderer: LinkComponent,
    // },
  ];

  const CustomTab = ({tabName, onClick, isActive}) => {
    return (
      <div
        onClick={onClick}
        style={{
          display: 'flex',
          borderRadius: '5px',
          justifyContent: 'center',
          alignItems: 'center',
          height: '43px',
          width: '167px',
          backgroundColor: isActive ? '#7729E5' : '#C6C6C654',
          color: isActive ? 'white' : 'black',
          marginTop: 20,
          boxShadow: '1px 2px 3px #00000040',
          cursor: 'pointer',
        }}>
        {tabName}
      </div>
    );
  };

  const handleTabClick = data => {
    if (data) {
      setCurrentTab(data);
    }
  };

  const HeadLabel = ({label}) => {
    return (
      <div
        style={{
          fontFamily: 'Poppins',
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '30px',
          letterSpacing: '0em',
          textAlign: 'left',
        }}>
        {label}
      </div>
    );
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 15,
          alignItems: 'center',
        }}>
        <ArrowCircleLeftOutlined
          onClick={handleGoBack}
          style={{
            // marginLeft: 20,
            cursor: 'pointer',
          }}
        />
        <HeadLabel label={'Brief Page'} />
      </div>
      <div style={{marginTop: '20px'}}>
        <BriefInfo {...briefData} />
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              borderRadius: '20px',
              gap: 10,
              minHeight: '5%',
              height: 'auto',
              // padding: '20px 0px',
              marginTop: 20,
            }}>
            {tabs?.map(d => (
              <CustomTab
                onClick={() => handleTabClick(d)}
                key={d?.tabId}
                tabName={d?.name}
                isActive={currentTab?.tabId === d?.tabId}
              />
            ))}
          </div>
          <p
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
              flex: 1,
              marginBottom: 5,
            }}>
            Total Entries: {applications?.length || 0}
          </p>
          <div
            className={'ag-theme-quartz'}
            style={{width: '100%', height: '500px'}}>
            {currentTab?.tabId == 1 || currentTab?.tabId == 2 ? (
              <AgGridReact
                ref={gridRef}
                // defaultColDef={{...defaultColDef}}
                // readOnlyEdit={true}
                getRowId={params => params.data.id}
                // onCellEditRequest={onCellEditRequest}
                rowData={applications || []}
                columnDefs={briefApplicationsCol}
                pagination={true}
                paginationPageSize={20}
                rowSelection="multiple"
                scrollbarWidth={9}
                alwaysShowHorizontalScroll={true}
                alwaysShowVerticalScroll={true}
              />
            ) : (
              <AgGridReact
                ref={gridRef}
                // defaultColDef={{...defaultColDef}}
                // readOnlyEdit={true}
                getRowId={params => params.data.id}
                // onCellEditRequest={onCellEditRequest}
                rowData={applications || []}
                columnDefs={briefSavedCol}
                pagination={true}
                paginationPageSize={20}
                rowSelection="multiple"
                scrollbarWidth={9}
                alwaysShowHorizontalScroll={true}
                alwaysShowVerticalScroll={true}
              />
            )}
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

export default BriefPage;
