import fetchHandler from './FetchHandler';

export const getAllAdminsApi = () => {
    return fetchHandler({
        method: 'get',
        url: 'admin/list',
      });
};


export const createAdminUserApi = (data) => {
    return fetchHandler({
        method: 'post',
        url: 'admin/create',
        data,
    })
}


export const NotifyUserApi = (data) => {
    return fetchHandler({
        method: 'post',
        url: 'admin/notify',
        data,
    })
}

export const deleteAdminUserApi = (id) => {
    return fetchHandler({
        method: 'delete',
        url: 'admin/delete?id='+id,
    })
}

export const deleteUserApi = (id) => {
    return fetchHandler({
        method: 'delete',
        url: 'admin/deleteuser?id='+id,
    })
}

export const updateAdminUserApi = (data) => {
    return fetchHandler({
        method: 'put',
        url: 'admin/update',
        data,
    })
}

