import { object, string } from 'yup';






const validationSchema = object({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    mobile: string()
        .required('Mobile number is required')
        .max(10, 'Mobile number must be 10 digit only')
});


export default validationSchema