import ViewAllButton from 'component/buttons/ViewAllButton';
import UsersDataTable from 'component/datatable/UsersDataTable';
import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useEffect} from 'react';
import ProShootersDataTable from 'component/datatable/ProShootersDatatable';

export default function ProShooters() {
  return (
    <DashboardLayout>
      <ProShootersDataTable />
    </DashboardLayout>
  );
}
