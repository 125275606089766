import React, {useState, useEffect, useRef, useCallback} from 'react';
import DashboardLayout from 'component/templates/DashboardLayout';
import {AgGridReact} from 'ag-grid-react'; // React Grid Logic
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import {
  clearBriefs,
  deleteBrief,
  fetchBriefs,
  updateBrief,
} from 'store/slice/briefSlice';
import {dispatch, useSelector} from 'store/store';
import './datatable.scss';
import SearchInput from 'component/input/SearchInput';
import {CircleTick} from 'component/CircleTick';
import {
  downloadAllBriefsApi,
  updateActiveBriefAPI,
  updateBriefApi,
} from 'services/Brief';
import {IconButton, Input, InputAdornment} from '@mui/material';
import {GridSearchIcon} from '@mui/x-data-grid';
import {dateComparator} from 'helper/comparator';
import {HeadLabel} from 'component/HeadLabel';
import {ArrowCircleLeftOutlined} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import SecondaryButton from 'component/buttons/SecondaryButton';

const FeaturedBriefs = () => {
  function LinkComponent(props) {
    return (
      <a href={window.location.origin + '/brief/' + props.value}>
        {props.value}
      </a>
    );
  }

  // Column Definitions: Defines & controls grid columns.
  // @ts-ignore
  const [colDefs, setColDefs] = useState([
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
      // renderCell: params =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      field: 'id',
      headerClass: 'super-app-theme--header',
      headerName: 'Brief Id',
      cellRenderer: LinkComponent,
      width: 300,
    },
    {
      field: 'title',
      headerClass: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'type',
      width: 100,
    },
    {
      field: 'reward',
      headerName: 'Reward(INR)',

      width: 100,
    },

    {
      field: 'isActive',
      headerName: 'Status',

      width: 100,
      cellRenderer: CircleTick,
      // editable: true,
    },
    {
      field: 'isFeatured',
      headerName: 'Featured',

      width: 100,
      cellRenderer: CircleTick,
      editable: true,
    },
    {
      // field: 'Camera',
      headerName: 'Camera',
      width: 120,
      valueGetter: row => {
        if (row?.data?.camera?.Mobile && row?.data?.camera?.DSLR)
          return 'DSLR, Mobile';
        if (row?.data?.camera?.Mobile) return 'Mobile';
        if (row?.data?.camera?.DSLR) return 'DSLR';
        // const date = new Date(row?.data?.created);
        // return date?.toLocaleDateString('en-GB') + ' ' + date?.toLocaleTimeString();
      },
    },
    {
      field: 'assets',
      headerName: 'Assets',
      width: 100,
    },
    {
      field: 'activatedOn',
      headerName: 'Activated On',
      width: 130,
      valueGetter: row => {
        const date = new Date(row?.data?.activated);
        return date?.toLocaleDateString('en-GB');
      },
      comparator: dateComparator,
    },
    {
      field: 'deactivatedOn',
      headerName: 'Deactivated On',
      width: 130,
      valueGetter: row => {
        const date = new Date(row?.data?.deactivated);
        return date?.toLocaleDateString('en-GB');
      },
      comparator: dateComparator,
    },
    {
      field: 'created',
      headerName: 'Created On',
      width: 130,
      valueGetter: row => {
        const date = new Date(row?.data?.created);
        return date?.toLocaleDateString('en-GB');
      },
      comparator: dateComparator,
      // sortable: true,
    },
    {
      field: 'updated',
      headerName: 'Last Updated',
      width: 130,
      valueGetter: row => {
        const date = new Date(row?.data?.updated);
        return date?.toLocaleDateString('en-GB');
      },
      comparator: dateComparator,
    },
    {
      field: 'updated',
      headerName: 'Expiry On',
      width: 130,
      valueGetter: row => {
        const date = new Date(row?.data?.expiry);
        return date?.toLocaleDateString('en-GB');
      },
      comparator: dateComparator,
    },
    {
      field: 'saved',
      headerName: 'Saved',
      width: 130,
      valueGetter: row => {
        return row?.data?.saves?.total;
      },
    },
    {
      field: 'applications',
      headerName: 'Applications',
      width: 130,
      valueGetter: row => {
        return row?.data?.applications?.total;
      },
    },
    {
      field: 'submission',
      headerName: 'Submission',
      width: 130,
      valueGetter: row => {
        return row?.data?.submissions?.total;
      },
    },
  ]);

  // @ts-ignore
  const {briefs, loading} = useSelector(state => state?.brief);

  useEffect(() => {
    dispatch(fetchBriefs({isFeatured: true}));
    return () => {
      dispatch(clearBriefs());
    };
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  async function onCellEditRequest(event) {
    const oldData = event.data;
    const field = event.colDef.field;
    const newData = {...oldData};
    newData[field] = event.newValue;
    try {
      const res = await updateActiveBriefAPI(newData);
      // @ts-ignore
      if (res?.success) {
        if (!field?.includes('Featured')) {
          dispatch(updateBrief(res?.data));
        } else dispatch(deleteBrief(res?.data));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const gridRef = useRef();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  const onFilterTextBoxChanged = useCallback(e => {
    gridRef.current.api.setGridOption('quickFilterText', e);
  }, []);

  const downloadFile = async () => {
    var res;
    try {
      res = await downloadAllBriefsApi(null, true);
      console.log(res);
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Featured_Briefs.csv');
      // document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DashboardLayout>
      {/* <div className="main"> */}
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 15,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 15,
            alignItems: 'center',
          }}>
          <ArrowCircleLeftOutlined
            onClick={() => navigate(-1)}
            style={{
              // marginLeft: 20,
              cursor: 'pointer',
            }}
          />
          <HeadLabel label={'Featured Briefs'} />
        </div>
        <SecondaryButton
          isLoading={loading}
          buttonLabel={'Export'}
          onClick={downloadFile}
          // @ts-ignore
          type="submit"
        />
      </div>
      <div
        style={{
          marginTop: '10px',
          width: '100%',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: 10,
            marginLeft: -10,
          }}>
          <SearchInput
            search={search}
            setSearch={e => {
              setSearch(e);
              onFilterTextBoxChanged(e);
            }}
          />
          Total: {briefs?.length}
        </div>
        <div
          className={'ag-theme-quartz'}
          style={{width: '100%', height: '500px'}}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={{...defaultColDef}}
            readOnlyEdit={true}
            getRowId={params => params.data.id}
            onCellEditRequest={onCellEditRequest}
            rowData={briefs}
            columnDefs={colDefs}
            pagination={true}
            paginationPageSize={20}
            rowSelection="multiple"
            scrollbarWidth={9}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
          />
        </div>
      </div>
      {/* </div> */}
    </DashboardLayout>
  );
};

export default FeaturedBriefs;
