import React from 'react';

export default function ShotwotSvg({
  fillColor,
  width = '346',
  height = '80',
  style,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{...style}}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 10.6195C0 4.7545 4.77134 0 10.6571 0H335.343C341.229 0 346 4.7545 346 10.6195V69.3805C346 75.2455 341.229 80 335.343 80H10.6571C4.77134 80 0 75.2455 0 69.3805V10.6195Z"
        fill="#7729E5"
      />
      <path
        d="M42.1339 64.9288C38.6326 64.9288 35.4952 64.3625 32.7215 63.2297C29.9478 62.097 27.7198 60.4205 26.0374 58.2004C24.4005 55.9802 23.5365 53.3069 23.4456 50.1805H35.859C36.0408 51.9476 36.6547 53.3069 37.7005 54.2584C38.7463 55.1646 40.1104 55.6177 41.7928 55.6177C43.5207 55.6177 44.8848 55.2326 45.8851 54.4623C46.8855 53.6467 47.3857 52.5366 47.3857 51.132C47.3857 49.954 46.9764 48.9798 46.158 48.2096C45.385 47.4393 44.4074 46.805 43.2251 46.3065C42.0884 45.8081 40.4515 45.2418 38.3144 44.6074C35.2224 43.6559 32.6988 42.7044 30.7436 41.7529C28.7883 40.8014 27.1059 39.3968 25.6964 37.5391C24.2868 35.6814 23.582 33.2573 23.582 30.2669C23.582 25.8265 25.1962 22.3604 28.4246 19.8683C31.653 17.331 35.859 16.0623 41.0426 16.0623C46.3171 16.0623 50.5686 17.331 53.797 19.8683C57.0253 22.3604 58.7532 25.8492 58.9806 30.3349H46.3626C46.2716 28.7943 45.7033 27.5936 44.6574 26.7327C43.6116 25.8265 42.2703 25.3734 40.6333 25.3734C39.2238 25.3734 38.087 25.7586 37.2231 26.5288C36.3591 27.2538 35.9272 28.3186 35.9272 29.7232C35.9272 31.2637 36.6547 32.4644 38.1097 33.3253C39.5648 34.1862 41.8383 35.115 44.9303 36.1119C48.0222 37.154 50.5231 38.1508 52.4329 39.1023C54.3881 40.0538 56.0705 41.4357 57.48 43.2481C58.8896 45.0605 59.5944 47.394 59.5944 50.2485C59.5944 52.9671 58.8896 55.4365 57.48 57.6566C56.1159 59.8768 54.1153 61.6439 51.478 62.9579C48.8407 64.2719 45.726 64.9288 42.1339 64.9288Z"
        fill="white"
      />
      <path
        d="M90.1664 26.1211C94.5315 26.1211 98.0327 27.571 100.67 30.4708C103.307 33.3253 104.626 37.2673 104.626 42.2966V64.4531H93.031V43.8598C93.031 41.3225 92.3717 39.3515 91.0531 37.9469C89.7344 36.5423 87.9611 35.84 85.733 35.84C83.505 35.84 81.7317 36.5423 80.413 37.9469C79.0944 39.3515 78.4351 41.3225 78.4351 43.8598V64.4531H66.772V14.1593H78.4351V31.6262C79.6173 29.9497 81.2315 28.6131 83.2777 27.6163C85.3238 26.6195 87.6201 26.1211 90.1664 26.1211Z"
        fill="white"
      />
      <path
        d="M130.055 64.9968C126.326 64.9968 122.961 64.2039 119.96 62.618C117.005 61.0322 114.663 58.7667 112.935 55.8216C111.253 52.8765 110.412 49.4329 110.412 45.491C110.412 41.5943 111.276 38.1735 113.003 35.2283C114.731 32.2379 117.096 29.9497 120.097 28.3639C123.098 26.7781 126.463 25.9851 130.191 25.9851C133.92 25.9851 137.285 26.7781 140.286 28.3639C143.287 29.9497 145.651 32.2379 147.379 35.2283C149.107 38.1735 149.971 41.5943 149.971 45.491C149.971 49.3876 149.084 52.8312 147.311 55.8216C145.583 58.7667 143.196 61.0322 140.149 62.618C137.148 64.2039 133.783 64.9968 130.055 64.9968ZM130.055 54.9381C132.283 54.9381 134.17 54.1225 135.716 52.4913C137.307 50.8602 138.103 48.5267 138.103 45.491C138.103 42.4552 137.33 40.1218 135.784 38.4906C134.284 36.8595 132.419 36.0439 130.191 36.0439C127.918 36.0439 126.031 36.8595 124.53 38.4906C123.03 40.0765 122.279 42.4099 122.279 45.491C122.279 48.5267 123.007 50.8602 124.462 52.4913C125.962 54.1225 127.827 54.9381 130.055 54.9381Z"
        fill="white"
      />
      <path
        d="M177.37 54.5982V64.4531H171.436C167.208 64.4531 163.911 63.4336 161.546 61.3947C159.182 59.3104 158 55.9349 158 51.268V36.1798H153.362V26.5288H158V17.2857H169.663V26.5288H177.302V36.1798H169.663V51.4039C169.663 52.5366 169.936 53.3522 170.481 53.8506C171.027 54.349 171.936 54.5982 173.21 54.5982H177.37Z"
        fill="white"
      />
      <path
        d="M249.753 16.7419L237.271 64.4531H223.153L215.514 33.0535L207.602 64.4531H193.484L181.343 16.7419H193.825L200.713 51.4719L209.239 16.7419H222.062L230.246 51.4719L237.203 16.7419H249.753Z"
        fill="white"
      />
      <path
        d="M272.926 55.7536C269.198 55.7536 265.833 54.9607 262.832 53.3749C259.876 51.789 257.535 49.5235 255.807 46.5784C254.124 43.6333 253.283 40.1897 253.283 36.2478C253.283 32.3512 254.147 28.9303 255.875 25.9851C257.603 22.9947 259.967 20.7065 262.968 19.1207C265.969 17.5349 269.334 16.7419 273.063 16.7419C276.791 16.7419 280.156 17.5349 283.157 19.1207C286.158 20.7065 288.523 22.9947 290.25 25.9851C291.978 28.9303 292.842 32.3512 292.842 36.2478C292.842 40.1444 291.956 43.588 290.182 46.5784C288.454 49.5235 286.067 51.789 283.021 53.3749C280.02 54.9607 276.655 55.7536 272.926 55.7536ZM272.926 45.6949C275.154 45.6949 277.041 44.8793 278.587 43.2481C280.179 41.617 280.975 39.2835 280.975 36.2478C280.975 33.212 280.202 30.8786 278.656 29.2474C277.155 27.6163 275.291 26.8007 273.063 26.8007C270.789 26.8007 268.902 27.6163 267.402 29.2474C265.901 30.8333 265.151 33.1667 265.151 36.2478C265.151 39.2835 265.878 41.617 267.333 43.2481C268.834 44.8793 270.698 45.6949 272.926 45.6949Z"
        fill="#FF7200"
      />
      <path
        d="M320.242 54.5982V64.4531H314.308C310.079 64.4531 306.782 63.4336 304.418 61.3947C302.054 59.3104 300.871 55.9349 300.871 51.268V36.1798H296.233V26.5288H300.871V17.2857H312.534V26.5288H320.173V36.1798H312.534V51.4039C312.534 52.5366 312.807 53.3522 313.353 53.8506C313.899 54.349 314.808 54.5982 316.081 54.5982H320.242Z"
        fill="white"
      />
    </svg>
  );
}
