import {DeleteIcon} from 'native-base';

export const Delete = ({value}) => (
  <span
    style={{
      display: 'flex',
      justifyContent: 'center',
      // height: '60%',
      alignItems: 'center',
      alignSelf: 'center',
      // borderRadius: '5px',
      // width: '50px',
      // backgroundColor: '#7729E5',
      // color: 'white',
      // boxShadow: '1px 2px 3px #00000040',
      // cursor: 'pointer',
      // textAlign: 'center',
      marginTop: 6,
    }}>
    <DeleteIcon
      style={{
        color: '#1C1B1F',
      }}
      size={7}
    />
  </span>
);
