import React, {useEffect, useState, useCallback, useRef} from 'react';
import {DataGrid, GridPagination} from '@mui/x-data-grid';
import './datatable.scss';
import MuiPagination from '@mui/material/Pagination';
import SearchInput from 'component/input/SearchInput';
import {
  getTotalUsersApi,
  downloadUsersApi,
  downloadShootersApi,
} from 'services/Users';
import {fetchShooters, fetchUsers, searchUsers} from 'store/slice/usersSlice';
import {dispatch, useSelector} from 'store/store';
import debounce from 'lodash/debounce';
import SecondaryButton from 'component/buttons/SecondaryButton';
import RHFDateTime, {convertLocalToUTCDate} from 'component/forms/RHFDateTime';
import {AgGridReact} from 'ag-grid-react';
import ReactDatePicker from 'react-datepicker';
import DashboardLayout from 'component/templates/DashboardLayout';
import {ArrowCircleLeftOutlined} from '@mui/icons-material';
import {HeadLabel} from 'component/HeadLabel';
import {useNavigate} from 'react-router-dom';
import {NavConstant} from 'routes/NavConstant';
import {CircleTick} from 'component/CircleTick';
import {dateComparator} from 'helper/comparator';

export default function ShootersTable({columns = []}) {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const [search, setSearch] = useState('');

  const searchEvent = value => {
    setSearch(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce((text: string) => {
      if (text === '') {
        dispatch(fetchUsers({}));
      } else {
        dispatch(searchUsers({key: text}));
      }
    }, 500),
    [],
  );

  const {shooters, loading} = useSelector(state => state?.users);

  const [customCols] = useState([
    // "profileimage": "",
    // "bio": "",
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
      // renderCell: params =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {field: 'id', headerName: 'UID', width: 200},
    {field: 'firstname', headerName: 'First Name', width: 150},
    {field: 'lastname', headerName: 'Last Name', width: 150},
    {field: 'email', headerName: 'Email Id', width: 200},
    {field: 'mobile', headerName: 'Mobile no', width: 150},
    {
      headerName: 'Last Application',
      width: 180,
      valueGetter: row => {
        if (!row?.data?.lastApplied) return null;
        const date = new Date(row?.data?.lastApplied);
        return date?.toLocaleDateString('en-GB');
        //  + ' ' + date?.toLocaleTimeString()
      },
      comparator: dateComparator,
    },
    {
      headerName: 'Last Submission',
      width: 180,
      valueGetter: row => {
        if (row?.data?.submitted == 0) return null;
        const date = new Date(row?.data?.lastSubmitted);
        return date?.toLocaleDateString('en-GB');
        // + ' ' + date?.toLocaleTimeString()
      },
      comparator: dateComparator,
    },
    {
      field: 'applied',
      headerName: 'Applied',
      width: 120,
      //   valueGetter: row => {

      //     return date?.toLocaleDateString('en-GB') + ' ' + date?.toLocaleTimeString();
      //   },
    },
    {
      field: 'submitted',
      headerName: 'Submitted',
      width: 120,
      //   valueGetter: row => {

      //     return date?.toLocaleDateString('en-GB') + ' ' + date?.toLocaleTimeString();
      //   },
    },
    {
      field: 'kyc',
      headerName: 'Kyc Status',
      width: 100,
      cellRenderer: CircleTick,
    },
    {
      field: 'pro',
      headerName: 'Pro Shooter',
      width: 120,
      cellRenderer: CircleTick,
    },
    {
      field: 'created',
      headerName: 'Sign Up',
      width: 120,
      valueGetter: row => {
        const date = new Date(row?.data?.created);
        return date?.toLocaleDateString('en-GB');
      },
      comparator: dateComparator,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 150,
      valueGetter: row => {
        return row?.data?.location?.city;
      },
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      valueGetter: row => {
        return row?.data?.location?.state;
      },
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 150,
      valueGetter: row => {
        return row?.data?.location?.country;
      },
    },
  ]);

  const gridRef = useRef();

  const downloadFile = async () => {
    var res;
    try {
      res = await downloadShootersApi({
        from: start,
        to: end,
      });
      console.log(res);
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Shotwot_Shooters.csv');
      // document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.log(error);
    }
  };

  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  useEffect(() => {
    dispatch(
      fetchShooters({
        from: convertLocalToUTCDate(start),
        to: convertLocalToUTCDate(end),
      }),
    );

    return () => {};
  }, [start, end]);

  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 15,
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              gap: 15,
              alignItems: 'center',
            }}>
            <ArrowCircleLeftOutlined
              onClick={() => navigate(-1)}
              style={{
                // marginLeft: 20,
                cursor: 'pointer',
              }}
            />
            <HeadLabel label={'All Shooters'} />
          </div>
          <SecondaryButton
            buttonLabel={'Export'}
            onClick={downloadFile}
            // @ts-ignore
            type="submit"
          />

          {/* <ViewAllButton /> */}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: 10,
          }}>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              gap: 10,
            }}>
            <div style={{zIndex: 100}}>
              <ReactDatePicker
                placeholderText="Select Start date"
                onChange={setStart}
                selected={start}
                showIcon
                closeOnScroll={true}
              />
            </div>
            <div style={{zIndex: 100}}>
              <ReactDatePicker
                placeholderText="Select End date"
                onChange={setEnd}
                selected={end}
                showIcon
                closeOnScroll={true}
              />
            </div>
          </div>

          <SearchInput search={search} setSearch={searchEvent} />
        </div>
        <div
          className={'ag-theme-quartz'}
          style={{width: '100%', height: '500px'}}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={{resizable: true, sortable: true, filter: true}}
            readOnlyEdit={true}
            getRowId={params => params.data.id}
            // onCellEditRequest={onCellEditRequest}
            onRowClicked={p => {
              // (`${window.location.origin}/users/${p?.data?.id}`);
              navigate(`${NavConstant.USER_PROFILE}/${p?.data?.id}`);
            }}
            rowData={shooters || []}
            columnDefs={customCols}
            pagination={true}
            paginationPageSize={20}
            rowSelection="multiple"
            scrollbarWidth={9}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
          />
        </div>
      </>
    </DashboardLayout>
  );
}
