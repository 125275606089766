// @ts-nocheck
import React, {useCallback, useState, useEffect} from 'react';
import {
  useForm,
  FormProvider,
  useFieldArray,
  Controller,
} from 'react-hook-form';
import {object, string, array} from 'yup';
import RHFInputField from '../RHFInputField';
import RHFTextInputField from '../RHFTextInputField';
import RHFPicker from '../RHFPicker';
import RHFDateTime from '../RHFDateTime';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  createBriefApi,
  createBriefFromDraftApi,
  createDraftApi,
  getBriefByIdApi,
  getDraftByIdApi,
  updateBriefApi,
  updateDraftApi,
} from 'services/Brief';
import {TagInput} from '../../input/TagInput';
import {ButtonBase, FormControlLabel} from '@material-ui/core';
import {SingleImageUpload} from 'component/images/SingleImageUpload';
import ImageSlider from 'component/images/ImageSlider';
import AddSvg from 'assets/AddSvg';
import axios from 'axios';
import {IconButton, LinearProgress} from '@mui/material';

import Button from '@mui/material/Button';
import ThumbsUp from 'assets/thumbsUp';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {NavConstant} from 'routes/NavConstant';
import {constants} from 'helper/constant';
import CustomButton from 'component/buttons/CustomButton';
import {ArrowCircleLeftOutlined, Delete} from '@mui/icons-material';
import {HeadLabel} from 'component/HeadLabel';
import DashboardLayout from 'component/templates/DashboardLayout';

const frameRate = [
  {
    id: 1,
    label: '24fps',
    value: '24fps',
  },
  {
    id: 2,
    label: '25fps',
    value: '25fps',
  },
  {
    id: 3,
    label: '30fps',
    value: '30fps',
  },
  {
    id: 4,
    label: '50fps',
    value: '50fps',
  },
  {
    id: 5,
    label: '60fps',
    value: '60fps',
  },
  {
    id: 6,
    label: '120fps',
    value: '120fps',
  },
  {
    id: 7,
    label: '240fps',
    value: '240fps',
  },
  {
    id: 8,
    label: '30fps+',
    value: '30fps+',
  },
  {
    id: 9,
    label: '50fps+',
    value: '50fps+',
  },
  {
    id: 10,
    label: '100fps+',
    value: '100fps+',
  },
];

const duration = [
  {
    id: 1,
    label: '10',
    value: '10',
  },
  {
    id: 2,
    label: '20',
    value: '20',
  },
  {
    id: 3,
    label: '30',
    value: '30',
  },
];

const resolution = [
  {
    id: 1,
    label: 'FHD',
    value: 'FHD',
  },
  {
    id: 2,
    label: '4k',
    value: '4k',
  },
  {
    id: 3,
    label: '4k+',
    value: '4k+',
  },
  {
    id: 4,
    label: '6k',
    value: '6k',
  },
  {
    id: 5,
    label: '8k',
    value: '8k',
  },
];

const validationSchema = object({
  title: string().required('title is required'),
  // description: string().required('description is required'),
  // tags: array().of(string()),
  // shotwotIdeas: array()
  //   .of(
  //     object().shape({
  //       title: string().typeError('Wrong type.').required('Required.'),
  //     }),
  //   )
  //   .required(),
});

const draftSchema = object().shape({
  title: string(),
});

function DraftForm({onDone = () => {}}) {
  const {id} = useParams(); // Use useParams to access the route parameters

  const [brief, setBrief] = useState();
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [tags, setTags] = React.useState(brief?.tags || []);
  const [files, setFiles] = React.useState(brief?.images || []);
  const [file, setFile] = React.useState(null);

  const [total, setTotal] = React.useState(0);
  const Themes = [
    {Key: 'Studio', Value: false},
    {Key: 'Indoor', Value: false},
    {Key: 'Outdoor', Value: false},
  ];
  const cameraTypes = [
    {Key: 'DSLR', Value: false},
    {Key: 'Mobile', Value: false},
  ];

  const orientationTypes = [
    {Key: 'landscape', Value: false},
    {Key: 'potrait', Value: false},
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const [progress, setProgress] = useState(0);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues:
      brief == null
        ? {
            shotwotIdeas: [{}, {}, {}],
          }
        : brief,
  });

  const checkKeyDown = e => {
    console.log(e);
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const {
    handleSubmit,
    setValue,
    register,
    control,
    getValues,
    setError,
    reset,
  } = methods;

  const {fields, append, prepend, remove, swap, move, insert, replace} =
    useFieldArray({
      control,
      name: 'shotwotIdeas',
    });

  const api = async (file, url) => {
    await axios({
      url: url,
      method: 'put',
      data: file,
      headers: {
        'Content-Type': file.type,
        'Content-Encoding': 'base64',
      },
      onUploadProgress: function (progressEvent) {
        setProgress(prev => prev + progressEvent?.bytes);
      },
      validateStatus: function (status) {
        return status <= 300; // Resolve only if the status code is less than 500
      },
    });
  };

  const onSubmit = async data => {
    setLoading(true);
    data.tags = tags;
    data.files = files;
    data.file = file;
    try {
      let res;
      //   if (data.id) {
      //     res = await updateBriefApi(data);
      //     console.log(res);
      //   } else {
      res = await createBriefFromDraftApi(data);
      //   }
      if (res?.data?.urls) {
        for (let i in res?.data?.urls) {
          try {
            const fileRes = await api(files[i], res?.data?.urls[i]);
            console.log(fileRes);
          } catch (error) {
            console.log(error);
          }
        }
      }
      if (res?.data?.url) {
        try {
          const fileRes = await api(file, res?.data?.url);
          console.log(fileRes);
        } catch (error) {
          console.log(error);
        }
      }
      navigate(`${NavConstant.ALL_BRIEFS}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      onDone();
    }
  };

  const onSave = async () => {
    console.log(1);
    const data = getValues();
    // try catch block for yup validate function. abortEarly: false so it returns all errors on our form.
    // try {
    //   await draftSchema.validate(data, {abortEarly: false});
    // } catch (error) {
    //   // loop over all errors and setError to use them in react-hook-form
    //   error.inner?.map((inner, index) => {
    //     const {type, path, errors} = inner;
    //     return setError(path, {type, message: errors[index]});
    //   });
    // }
    console.log(data);
    setLoading(true);
    data.tags = tags;
    data.files = files;
    data.file = file;
    try {
      let res;

      res = await updateDraftApi(data);

      if (res?.data?.urls) {
        for (let i in res?.data?.urls) {
          try {
            const fileRes = await api(files[i], res?.data?.urls[i]);
            console.log(fileRes);
          } catch (error) {
            console.log(error);
          }
        }
      }
      if (res?.data?.url) {
        try {
          const fileRes = await api(file, res?.data?.url);
          console.log(fileRes);
        } catch (error) {
          console.log(error);
        }
      }
      // navigate(`${NavConstant.ALL_BRIEFS}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      onDone();
    }
  };

  useEffect(() => {
    setTotal(0);
    setProgress(0);
    for (let item of files) {
      if (!(typeof item === 'string' || item instanceof String)) {
        setTotal(prev => prev + item.size);
        console.log(item.size);
      }
    }
    if (file) {
      if (!(typeof file === 'string' || file instanceof String)) {
        setTotal(prev => prev + file.size);
      }
    }

    return () => {};
  }, [files, file]);

  useEffect(() => {
    setDone(false);
    getDraftByIdApi(id).then(res => {
      console.log(res);
      if (res?.data) {
        setBrief(res?.data);
        reset(res?.data);
        setFile(constants.CDN + res?.data?.cardImage);
        setFiles(res?.data?.images);
        setDone(true);
      }
    });
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <div>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 15,
            alignItems: 'center',
            paddingBottom: 12,
          }}>
          <ArrowCircleLeftOutlined
            onClick={handleGoBack}
            style={{
              cursor: 'pointer',
            }}
          />
          <HeadLabel label={'Edit Draft'} />
        </div>
        {done ? (
          <div
            style={{
              borderRadius: '20px',
              // minHeight: '95%',
              overflow: 'auto',
              padding: '45px 18px',
              backgroundColor: '#C6C6C654',
              boxShadow: '1px 2px 3px #00000040',
              fontWeight: '400',
              fontSize: 14,
              width: 989,
            }}>
            {progress !== 0 ? (
              <LinearProgress
                variant="determinate"
                value={parseInt((progress * 100) / total)}
                sx={{
                  backgroundColor: '#D9D9D9',
                  span: {
                    background: 'linear-gradient(to right, #FC3CB2, #00C0FB)',
                  },
                  height: '10px',
                  'border-radius': '25px',
                  marginTop: 5,
                  marginBottom: 5,
                }}
              />
            ) : null}
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={e => checkKeyDown(e)}>
                <div
                  style={{
                    display: 'flex',
                  }}>
                  <div
                    style={{
                      width: 614,
                      gap: 18,
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        // justifyContent: 'space-between',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Brief Title
                      </div>
                      <RHFInputField
                        name="title"
                        label="Title"
                        placeholder="Brief Title"
                        width={424}
                        height="31"
                        register={register}
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Brief Description
                      </div>
                      <RHFTextInputField
                        name="description"
                        label="Description"
                        placeholder="Brief Description"
                        register={register}
                        width={424}
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Things to shoot
                      </div>
                      <TagInput
                        selectedTags={val => {
                          setTags(val);
                        }}
                        tags={tags}
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Reference Images
                      </div>
                      <ImageSlider
                        images={files}
                        setImages={newFiles => {
                          setFiles(prev => [...prev, ...newFiles]);
                        }}
                        remove={i => {
                          console.log(i);
                          setFiles(prev => prev.filter(img => img !== i));
                        }}
                      />
                    </div>

                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Shotwot Ideas
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: 424,
                        }}>
                        <ul
                          style={{
                            'list-style-type': 'none',
                            marginLeft: -40,
                          }}>
                          {fields.map((item, index) => {
                            return (
                              <li key={item.id}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 18,
                                  }}>
                                  <div
                                    style={{
                                      justifyContent: 'space-between',
                                      display: 'flex',
                                      flex: 1,
                                      marginBottom: -12,
                                      marginTop: 18,
                                    }}>
                                    Idea {index + 1}
                                    <IconButton
                                      style={{
                                        color: 'black',
                                        backgroundColor: '#EDEDED',
                                        height: 20,
                                        width: 20,
                                        borderRadius: 6,
                                        fontSize: 35,
                                      }}
                                      type="button"
                                      onClick={() => {
                                        remove(index);
                                      }}>
                                      <Delete color="black" fontSize="medium" />
                                    </IconButton>
                                  </div>
                                  <RHFInputField
                                    width="424px"
                                    label="Heading"
                                    placeholder="Heading"
                                    name={`shotwotIdeas.${index}.title`}
                                    register={register}
                                  />
                                  <RHFTextInputField
                                    name={`shotwotIdeas.${index}.description`}
                                    label="Description"
                                    placeholder="Body"
                                    register={register}
                                    width="424px"
                                  />
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                        <button
                          style={{
                            padding: 0,
                            border: 'none',
                            background: 'none',
                            justifyContent: 'flex-end',
                            display: 'flex',
                            marginTop: 12,
                          }}
                          type="button"
                          onClick={() => {
                            append();
                          }}>
                          <AddSvg />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderLeft: '1px solid ',
                      borderColor: '#00000050',
                      padding: '0px 25px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 6,
                    }}>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Card Image
                      </div>
                      <div
                        style={{
                          flexDirection: 'column',
                        }}>
                        <SingleImageUpload image={file} setImage={setFile} />
                      </div>
                    </div>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Brief Reward
                      </div>
                      <RHFInputField
                        name="reward"
                        label="Reward"
                        placeholder="Enter Reward"
                        width="180px"
                        height="31px"
                        register={register}
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Brief Type
                      </div>
                      <RHFPicker
                        name="type"
                        label="Select Type "
                        options={[
                          {
                            id: 1,
                            label: 'Video',
                            value: 'Video',
                          },
                          {
                            id: 2,
                            label: 'Audio',
                            value: 'Audio',
                          },
                          {
                            id: 3,
                            label: 'Images',
                            value: 'Images',
                          },
                        ]}
                        width="180px"
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Expiry
                      </div>
                      <RHFDateTime
                        name="expiry"
                        label="expiry"
                        placeholder="Enter Expiry"
                      />
                    </div>
                    <div style={{whiteSpace: 'nowrap'}}>Lightning Setup</div>

                    <div style={{padding: '0px'}}>
                      {Themes.map((item, i) => (
                        <FormControlLabel
                          key={item?.key}
                          control={
                            <input
                              type="checkbox"
                              name={`nativeThemes[${i}]`}
                              style={{margin: 12}}
                              {...register('lightSetup.' + item?.Key, {
                                required: true,
                              })}
                            />
                          }
                          label={item?.Key}
                        />
                      ))}
                    </div>
                    <div style={{whiteSpace: 'nowrap'}}>Camera Type</div>

                    <div style={{padding: '0px'}}>
                      {cameraTypes.map((item, i) => (
                        <FormControlLabel
                          key={item?.Key}
                          control={
                            <input
                              type="checkbox"
                              name={`nativeThemes[${i}]`}
                              // checked={item?.Value}
                              style={{margin: 12}}
                              {...register('camera.' + item?.Key, {
                                required: true,
                              })}
                            />
                          }
                          label={item?.Key}
                        />
                      ))}
                    </div>

                    <div style={{whiteSpace: 'nowrap'}}>Orientation</div>

                    <div style={{padding: '0px'}}>
                      {orientationTypes.map((item, i) => (
                        <FormControlLabel
                          key={item?.Key}
                          control={
                            <input
                              type="checkbox"
                              name={`nativeThemes[${i}]`}
                              // checked={item?.Value}
                              style={{margin: 12}}
                              {...register('orientation.' + item?.Key, {
                                required: true,
                              })}
                            />
                          }
                          label={item?.Key}
                        />
                      ))}
                    </div>

                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Resolution
                      </div>
                      <RHFPicker
                        name="resolution"
                        label="Select Resolution"
                        options={resolution}
                        width="180px"
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Frame Rate
                      </div>
                      <RHFPicker
                        name="frameRate"
                        label="Frame Rate"
                        options={frameRate}
                        width="180px"
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Duration
                      </div>
                      <RHFPicker
                        name="duration"
                        label="Duration"
                        options={duration}
                        width="180px"
                      />
                    </div>

                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          width: 150,
                          display: 'flex',
                        }}>
                        Assets
                      </div>
                      <RHFInputField
                        name="assets"
                        label="Reward"
                        placeholder="Total no. of assets"
                        width="180px"
                        register={register}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '30px',
                    gap: 10,
                  }}>
                  <button
                    // isLoading={loading}
                    // @ts-ignore
                    type="button"
                    onClick={onSave}
                    style={{
                      backgroundColor: '#8A8395',
                      // borderColor: '#D9D9D9',
                      borderRadius: 10,
                      borderWidth: 0,
                      padding: '0 8px',
                      color: 'white',
                    }}
                    id="new">
                    Save Draft
                  </button>
                  <SecondaryButton
                    isLoading={loading}
                    buttonLabel={'Create Brief'}
                    // @ts-ignore
                    type="submit"
                    color={'#7729E5'}
                    labelColor="white"
                  />
                </div>
              </form>
            </FormProvider>
          </div>
        ) : null}
      </div>
    </DashboardLayout>
  );
}

export default DraftForm;
