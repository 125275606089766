import axios from 'axios';
import {useAuth} from 'hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

axios.interceptors.response.use(
  response => {
    return response?.data;
  },
  async error => {
    if (error.code === 'ERR_NETWORK') {
      toast.error('No Network!');

      // handle network error
    }

    if (error.response) {
      const url = error.response.config.url;
      if (error.response.data?.code && error.response.data?.code === 500) {
        toast.error('Internal Server error!');

        return;
      }

      // If we get 403 error in any api that means token is expired.
      // So we make request to get access token.
      if (
        error.response.data?.code &&
        error.response.data?.code === 401 &&
        error.response.data?.code === 403
      ) {
        toast.error('user token expired!');
        window.localStorage.setItem('user', null);
        console.log(window.localStorage.getItem('user'), 'ere');
      }
    }
  },
);

export default async function fetchHandler({
  url,
  method,
  data = {},
  header = {},
}) {
  const URL = `https://backend.shotwot.com/api/v1/${url}`;
  // const URL = `http://localhost:8001/api/v1/${url}`;
  console.log('API REQUEST TO -> ', URL);
  const headers = {
    Authorization: `Bearer ${window.localStorage.getItem('user')}`,
    //implement auto token
    // Authorization: `Bearer ${getItemFromAsyncStorage(
    //   LOCAL_STORAGE_KEYS.accessToken,
    // )}`,
  };

  if (method === 'get') {
    return axios({
      url: URL,
      method: 'get',
      headers: {
        ...headers,
        ...header,
      },
    });
  }

  return axios({
    url: URL,
    method: method,
    headers: {
      ...headers,
      ...header,
    },
    data,
  });
}
