export const Action = ({value}) => (
  <span
    style={{
      display: 'flex',
      justifyContent: 'center',
      height: '60%',
      alignItems: 'center',
      alignSelf: 'center',
      borderRadius: '5px',
      width: '50px',
      backgroundColor: '#7729E5',
      color: 'white',
      boxShadow: '1px 2px 3px #00000040',
      cursor: 'pointer',
      textAlign: 'center',
      marginTop: 8,
    }}>
    {/* <div
      style={{
        borderRadius: '5px',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50px',
        height: '60%',
        backgroundColor: '#7729E5',
        color: 'white',
        boxShadow: '1px 2px 3px #00000040',
        cursor: 'pointer',
        textAlign: 'center',
      }}>
      <p
        style={{
          marginBottom: 30,
        }}> */}
    Open
    {/* </p>
    </div> */}
  </span>
);
