import fetchHandler from './FetchHandler';

export const getAllAssetsApi = (isActive, s, l, status) => {
  let data;

  if (isActive != null) {
    data = {
      order: -1,
      isActive,
    };
  } else {
    data = {
      order: -1,
    };
  }
  return fetchHandler({
    method: 'get',
    url: 'admin/assets/alllist?skip=' + s + '&limit=' + l + '&status=' + status,
    // data
  });
};

export const exportAllAssetsApi = status => {
  return fetchHandler({
    method: 'get',
    url: 'admin/assets/alllist/export?status=' + status,
    // data
  });
};

export const searchAssetsApi = (isActive, key, status) => {
  let data;

  if (isActive != null) {
    data = {
      order: -1,
      isActive,
    };
  } else {
    data = {
      order: -1,
    };
  }
  return fetchHandler({
    method: 'get',
    url: 'admin/assets/search?query=' + key + '&status=' + status,
    // data
  });
};

export const getNewAssetsApi = ({s, l}) => {
  return fetchHandler({
    method: 'get',
    url: 'admin/assets/list?skip=' + s + '&limit=' + l,
    // data
  });
};

export const getBriefAssetsApi = id => {
  return fetchHandler({
    method: 'get',
    url: 'admin/assets/brief-asset-list?briefId=' + id,
    // data
  });
};

export const countAllAssetsApi = status => {
  return fetchHandler({
    method: 'get',
    url: 'admin/assets/count?status=' + status,
  });
};

export const updateAssetApi = data => {
  return fetchHandler({
    method: 'post',
    url: 'admin/assets/update',
    data,
  });
};

export const createAssetApi = data => {
  return fetchHandler({
    method: 'post',
    url: 'admin/assets/create',
    data,
  });
};

export const deleteAssetApi = data => {
  return fetchHandler({
    method: 'post',
    url: 'admin/assets/delete',
    data,
  });
};
