import HeadingComp from 'component/HeadingComp'
import DashboardLayout from 'component/templates/DashboardLayout'
import React from 'react'

export default function NotAuthorized() {
    return (
        <DashboardLayout>


         

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: "20px",
                    minHeight: "20%",
                    padding: "20px 20px",
                    backgroundColor: "#C6C6C630",
                    boxShadow: "1px 2px 3px #00000040",
                    justifyContent:"center",
                    alignItems:"center"

                }}

            >
                   <HeadingComp
                label={"Not Authorized"}
            />
            </div>
        </DashboardLayout>
    )
}
