import {yupResolver} from '@hookform/resolvers/yup';
import {ArrowCircleLeftOutlined} from '@mui/icons-material';
import {LinearProgress} from '@mui/material';
import axios from 'axios';
import {HeadLabel} from 'component/HeadLabel';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {RHFInputField} from 'component/forms';
import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useEffect, useRef, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  getAttahcement,
  getPricing,
  postAttahcement,
  postPricing,
} from 'services/Attachement';
import * as yup from 'yup';

function Pricing() {
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    pro: yup.number().min(1),
    proDisc: yup.number().min(1),
    single: yup.number().min(1),
    singleDisc: yup.number().min(1),
    prem: yup.number().min(1),
    premDisc: yup.number().min(1),
    bundle5: yup.number().min(1),
    bundle5Disc: yup.number().min(1),
    bundle10: yup.number().min(1),
    bundle10Disc: yup.number().min(1),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    setValue,
    register,
    control,
    getValues,
    setError,
    reset,
  } = methods;

  const onSubmit = async data => {
    postPricing(data).then(r => {
      toast.success('updated pricing');
    });
  };

  useEffect(() => {
    getPricing().then(res => {
      reset(res?.data);
    });
  }, []);

  return (
    <DashboardLayout>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 15,
          alignItems: 'center',
          paddingBottom: 18,
        }}>
        <ArrowCircleLeftOutlined
          onClick={() => {
            navigate(-1);
          }}
          style={{
            cursor: 'pointer',
          }}
        />
        <HeadLabel label={'Product Pricing'} />
      </div>
      <div
        style={{
          gap: 24,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              gap: 24,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <div
              style={{
                display: 'flex',
                padding: 10,
                backgroundColor: 'rgba(200, 200, 200, 0.33)',
                borderRadius: 6,
                width: '837px',
                flexDirection: 'column',
                gap: 12,
              }}>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  width: 230,
                  alignSelf: 'center',
                }}>
                Pro Shooter
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                }}>
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    width: 260,
                    flexDirection: 'row',
                    display: 'flex',
                  }}>
                  Original Price{' '}
                  <p
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      paddingTop: 12,
                    }}>
                    (strike through)
                  </p>
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    marginRight: 10,
                  }}>
                  Inr
                </p>
                <RHFInputField
                  name="proDisc"
                  // @ts-ignore
                  register={register}
                  label="assetTitle"
                  width="180px"
                  placeholder="14999"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: 12,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Save Changes'}
                    // @ts-ignore
                    type="submit"
                    color={'#7729E5'}
                    labelColor="white"
                    width="130px"
                  />
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {}}
                  />
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                }}>
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    width: 260,
                    flexDirection: 'row',
                    display: 'flex',
                  }}>
                  Discounted Price
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    marginRight: 10,
                  }}>
                  Inr
                </p>
                <RHFInputField
                  name="pro"
                  // @ts-ignore
                  register={register}
                  label="assetTitle"
                  width="180px"
                  placeholder="6999"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: 12,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Save Changes'}
                    // @ts-ignore
                    type="submit"
                    color={'#7729E5'}
                    labelColor="white"
                    width="130px"
                  />
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {}}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                padding: 10,
                backgroundColor: 'rgba(200, 200, 200, 0.33)',
                borderRadius: 6,
                width: '837px',
                flexDirection: 'column',
                gap: 12,
              }}>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  width: 230,
                  alignSelf: 'center',
                }}>
                Single Download
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                }}>
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    width: 260,
                    flexDirection: 'row',
                    display: 'flex',
                  }}>
                  Original Price{' '}
                  <p
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      paddingTop: 12,
                    }}>
                    (strike through)
                  </p>
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    marginRight: 10,
                  }}>
                  Inr
                </p>
                <RHFInputField
                  name="single"
                  // @ts-ignore
                  register={register}
                  label="assetTitle"
                  width="180px"
                  placeholder="14999"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: 12,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Save Changes'}
                    // @ts-ignore
                    type="submit"
                    color={'#7729E5'}
                    labelColor="white"
                    width="130px"
                  />
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {}}
                  />
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                }}>
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    width: 260,
                    flexDirection: 'row',
                    display: 'flex',
                  }}>
                  Discounted Price
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    marginRight: 10,
                  }}>
                  Inr
                </p>
                <RHFInputField
                  name="singleDisc"
                  // @ts-ignore
                  register={register}
                  label="assetTitle"
                  width="180px"
                  placeholder="6999"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: 12,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Save Changes'}
                    // @ts-ignore
                    type="submit"
                    color={'#7729E5'}
                    labelColor="white"
                    width="130px"
                  />
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {}}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                padding: 10,
                backgroundColor: 'rgba(200, 200, 200, 0.33)',
                borderRadius: 6,
                width: '837px',
                flexDirection: 'column',
                gap: 12,
              }}>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  width: 230,
                  alignSelf: 'center',
                }}>
                Premium Download
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                }}>
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    width: 260,
                    flexDirection: 'row',
                    display: 'flex',
                  }}>
                  Original Price{' '}
                  <p
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      paddingTop: 12,
                    }}>
                    (strike through)
                  </p>
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    marginRight: 10,
                  }}>
                  Inr
                </p>
                <RHFInputField
                  name="prem"
                  // @ts-ignore
                  register={register}
                  label="assetTitle"
                  width="180px"
                  placeholder="14999"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: 12,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Save Changes'}
                    // @ts-ignore
                    type="submit"
                    color={'#7729E5'}
                    labelColor="white"
                    width="130px"
                  />
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {}}
                  />
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                }}>
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    width: 260,
                    flexDirection: 'row',
                    display: 'flex',
                  }}>
                  Discounted Price
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    marginRight: 10,
                  }}>
                  Inr
                </p>
                <RHFInputField
                  name="premDisc"
                  // @ts-ignore
                  register={register}
                  label="assetTitle"
                  width="180px"
                  placeholder="6999"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: 12,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Save Changes'}
                    // @ts-ignore
                    type="submit"
                    color={'#7729E5'}
                    labelColor="white"
                    width="130px"
                  />
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {}}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                padding: 10,
                backgroundColor: 'rgba(200, 200, 200, 0.33)',
                borderRadius: 6,
                width: '837px',
                flexDirection: 'column',
                gap: 12,
              }}>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  width: 230,
                  alignSelf: 'center',
                }}>
                Bundle of 5
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                }}>
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    width: 260,
                    flexDirection: 'row',
                    display: 'flex',
                  }}>
                  Original Price{' '}
                  <p
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      paddingTop: 12,
                    }}>
                    (strike through)
                  </p>
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    marginRight: 10,
                  }}>
                  Inr
                </p>
                <RHFInputField
                  name="bundle5"
                  // @ts-ignore
                  register={register}
                  label="assetTitle"
                  width="180px"
                  placeholder="14999"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: 12,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Save Changes'}
                    // @ts-ignore
                    type="submit"
                    color={'#7729E5'}
                    labelColor="white"
                    width="130px"
                  />
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {}}
                  />
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                }}>
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    width: 260,
                    flexDirection: 'row',
                    display: 'flex',
                  }}>
                  Discounted Price
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    marginRight: 10,
                  }}>
                  Inr
                </p>
                <RHFInputField
                  name="bundle5Disc"
                  // @ts-ignore
                  register={register}
                  label="assetTitle"
                  width="180px"
                  placeholder="6999"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: 12,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Save Changes'}
                    // @ts-ignore
                    type="submit"
                    color={'#7729E5'}
                    labelColor="white"
                    width="130px"
                  />
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {}}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                padding: 10,
                backgroundColor: 'rgba(200, 200, 200, 0.33)',
                borderRadius: 6,
                width: '837px',
                flexDirection: 'column',
                gap: 12,
              }}>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  width: 230,
                  alignSelf: 'center',
                }}>
                Bundle of 10
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                }}>
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    width: 260,
                    flexDirection: 'row',
                    display: 'flex',
                  }}>
                  Original Price{' '}
                  <p
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      paddingTop: 12,
                    }}>
                    (strike through)
                  </p>
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    marginRight: 10,
                  }}>
                  Inr
                </p>
                <RHFInputField
                  name="bundle10"
                  // @ts-ignore
                  register={register}
                  label="assetTitle"
                  width="180px"
                  placeholder="14999"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: 12,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Save Changes'}
                    // @ts-ignore
                    type="submit"
                    color={'#7729E5'}
                    labelColor="white"
                    width="130px"
                  />
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {}}
                  />
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                }}>
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    width: 260,
                    flexDirection: 'row',
                    display: 'flex',
                  }}>
                  Discounted Price
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    marginRight: 10,
                  }}>
                  Inr
                </p>
                <RHFInputField
                  name="bundle10Disc"
                  // @ts-ignore
                  register={register}
                  label="assetTitle"
                  width="180px"
                  placeholder="6999"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: 12,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Save Changes'}
                    // @ts-ignore
                    type="submit"
                    color={'#7729E5'}
                    labelColor="white"
                    width="130px"
                  />
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {}}
                  />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </DashboardLayout>
  );
}

export default Pricing;
