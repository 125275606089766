import React, {useEffect} from 'react';

import {Routes, Route} from 'react-router-dom';
import '../app.scss';
import ForgotPassword from 'pages/forgotPassword/ForgotPassword';
import {AuthProvider} from 'hooks/useAuth';
import Login from 'pages/login/Login';
import {ProtectedRoute} from 'routes/ProtectedRoutes';
import Temp from 'pages/temporary/Temp';
import {Home} from 'pages/home/Home';
import {CreateBrief} from 'pages/brief/CreateBrief';
import AllBriefs from 'pages/brief/AllBriefs';
import './route.scss';
import ManageAdmin from 'pages/admin/ManageAdmin';
import {NavConstant} from './NavConstant';
import {UserActivity} from 'pages/useractivity/UserActivity';
import AllSignups from 'pages/allsignups/AllSignups';
import Settings from 'pages/setting/Setting';
import NotAuthorized from 'pages/auth/NotAuthorized';
import BriefPage from 'pages/brief/BriefPage';
import ActiveBriefs from 'pages/brief/ActiveBriefs';
import DeActivatedBriefs from 'pages/brief/DeactivatedBriefs';
import BriefApplicationPage from 'pages/brief/BriefApplicationPage';
import MasterClass from 'pages/proshooter/MasterClass';
import Assets from 'pages/assets/BriefAssets';
import BriefSubmissionPage from 'pages/brief/BriefSubmissionPage';
import FeaturedBriefs from 'pages/brief/FeaturedBriefs';
import ShootersTable from 'component/datatable/ShootersTable';
import UserProfile from 'pages/user/UserProfile';
import {getSignInAdminDetails} from 'services/Login';
import {getAdminRoles} from 'helper/reduxHelper';
import {setAdminDetails} from 'store/slice/loginSlice';
import {store} from 'store/store';
import Attachement from 'pages/Attachement';
import {PageCopies} from 'pages/PageCopies';
import DraftBriefs from 'pages/brief/DraftBriefs';
import DraftForm from 'component/forms/comman/DraftForm';
import UploadAssets from 'pages/assets/UploadAssets';
import EditBriefsAsset from 'pages/assets/EditBriefsAsset';
import ProShooters from 'pages/proshooter/ProShooters';
import AllAsset from 'pages/assets/AllAssets';
import TaggedAsset from 'pages/assets/TaggedAssets';
import Events from 'pages/proshooter/Events';
import CheatSheets from 'pages/proshooter/CHeatSheets';
import WithDrawal from 'pages/WithDrawal';
import Pricing from 'pages/Pricing';
import Category from 'pages/Category';
import {Aanalytics} from 'component/Aanalytics';

function NavRoutes() {
  useEffect(() => {
    getSignInAdminDetails().then(res => {
      if (res) {
        const {adminRoles} = getAdminRoles();
        // @ts-ignore
        const filterData = adminRoles?.filter(d => res?.role === d?.value);

        let userdata = {
          ...res,
          adminRole: {
            ...filterData[0],
          },
        };
        store.dispatch(setAdminDetails(userdata));
      }
    });
  }, []);
  return (
    <AuthProvider>
      <Routes>
        <Route index path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path={NavConstant.NOT_AUTHORIZED} element={<NotAuthorized />} />

        <Route
          path={NavConstant.TEMP}
          element={
            <ProtectedRoute>
              <div className="gradient">
                <Temp />
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.HOME}
          element={
            <ProtectedRoute>
              <Aanalytics />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/attachements'}
          element={
            <ProtectedRoute>
              <Attachement />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/page-copy'}
          element={
            <ProtectedRoute>
              <PageCopies />
            </ProtectedRoute>
          }
        />

        <Route
          path={NavConstant.CREATE_BRIEF}
          element={
            <ProtectedRoute>
              <CreateBrief />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${NavConstant.EDIT_BRIEF}/:id`}
          element={
            <ProtectedRoute>
              <CreateBrief />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.ALL_BRIEFS}
          element={
            <ProtectedRoute>
              <AllBriefs />
            </ProtectedRoute>
          }
        />

        <Route
          path={NavConstant.DRAFTS}
          element={
            <ProtectedRoute>
              <DraftBriefs />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${NavConstant.DRAFTS}/:id`}
          element={
            <ProtectedRoute>
              <DraftForm />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.ASSETS}
          element={
            <ProtectedRoute>
              <Assets />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.ACTIVE_BRIEFS}
          element={
            <ProtectedRoute>
              <ActiveBriefs />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.FEATURED_BRIEFS}
          element={
            <ProtectedRoute>
              <FeaturedBriefs />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.DEACTIVED_BRIEFS}
          element={
            <ProtectedRoute>
              <DeActivatedBriefs />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.MANAGE_ADMIN}
          element={
            <ProtectedRoute>
              <ManageAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.USER_ACTIVITY}
          element={
            <ProtectedRoute>
              <UserActivity />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.SHOOTERS}
          element={
            <ProtectedRoute>
              <ShootersTable />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.USER_PROFILE + '/:id'}
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.ALL_SIGNUPS}
          element={
            <ProtectedRoute>
              <AllSignups />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.SETTINGS}
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />

        <Route
          path={NavConstant.UploadAssets}
          element={
            <ProtectedRoute>
              <UploadAssets />
            </ProtectedRoute>
          }
        />

        <Route
          path={NavConstant.EditBriefAssets + '/:id'}
          element={
            <ProtectedRoute>
              <EditBriefsAsset />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.PROShooters}
          element={
            <ProtectedRoute>
              <ProShooters />
            </ProtectedRoute>
          }
        />

        {/* breif details */}
        <Route
          path={`${NavConstant.BRIEF}/:id`}
          element={
            <ProtectedRoute>
              <BriefPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${NavConstant.BRIEF_APPLICATION}/:id`}
          element={
            <ProtectedRoute>
              <BriefApplicationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${NavConstant.BRIEF_Submission}/:id`}
          element={
            <ProtectedRoute>
              <BriefSubmissionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.MASTERCLASS}
          element={
            <ProtectedRoute>
              <MasterClass />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.Event}
          element={
            <ProtectedRoute>
              <Events />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.Sheets}
          element={
            <ProtectedRoute>
              <CheatSheets />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/withdrawal'}
          element={
            <ProtectedRoute>
              <WithDrawal />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.ALLASSETS}
          element={
            <ProtectedRoute>
              <AllAsset />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.PagePricing}
          element={
            <ProtectedRoute>
              <Pricing />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/category'}
          element={
            <ProtectedRoute>
              <Category />
            </ProtectedRoute>
          }
        />
        <Route
          path={NavConstant.TAGGED}
          element={
            <ProtectedRoute>
              <TaggedAsset />
            </ProtectedRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

export default NavRoutes;
