import React from 'react'

export default function ViewAllButton() {
    return (
        <p
            style={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "21px",
            }}

        >
            View all
        </p>
    )
}
