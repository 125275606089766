import fetchHandler from './FetchHandler';

export const getWithdrawsApi = ({s, l}) => {
  return fetchHandler({
    method: 'post',
    url: 'admin/withdraw/list',
    data: {
      skip: s,
      limit: l,
    },
  });
};

export const exportWithdrawsApi = ({s, l}) => {
  return fetchHandler({
    method: 'post',
    url: 'admin/withdraw/export',
    data: {
      skip: s,
      limit: l,
    },
  });
};

export const postWithdrawsApi = data => {
  return fetchHandler({
    method: 'post',
    url: 'admin/withdraw/update',
    data,
  });
};
