import {
  ArrowCircleLeftOutlined,
  ExpandCircleDownTwoTone,
} from '@mui/icons-material';
import CustomTab from 'component/CustomTab';
import HeadingComp from 'component/HeadingComp';
import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useCallback, useState} from 'react';
import CreatePlaylist from './components/CreatePlaylist';
import {Navigate, useNavigate} from 'react-router-dom';
import {HeadLabel} from 'component/HeadLabel';
import AllPlaylist from './components/AllPlaylist';

export default function MasterClass() {
  const tabs = [
    {
      tabId: 1,
      name: 'All Playlist',
    },
    {
      tabId: 2,
      name: 'Create Playlist',
    },
    // {
    //   tabId: 2,
    //   name: 'Edit Playlist',
    // },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [playlist, setPlaylist] = useState({});

  // const PrimaryDropDown = () => {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         borderRadius: '5px',
  //         height: '43px',
  //         width: '276px',
  //         backgroundColor: '#00C0FB',

  //         boxShadow: '1px 2px 3px #00000040',
  //         justifyContent: 'space-around',
  //         alignItems: 'center',
  //         color: 'white',
  //         flexDirection: 'row',
  //       }}>
  //       <p
  //         style={{
  //           fontSize: '24px',
  //           fontFamily: 'Poppins',
  //           fontWeight: '500',
  //         }}>
  //         Masterclass
  //       </p>

  //       <ExpandCircleDownTwoTone
  //         style={{
  //           color: 'white',
  //           fontSize: '30px',
  //         }}
  //       />
  //     </div>
  //   );
  // };

  const handleTabClick = props => {
    console.log(props);
    if (props) {
      setCurrentTab(props);
      setPlaylist({});
    }
  };
  const navigate = useNavigate();

  const HandlView = useCallback(() => {
    switch (currentTab?.tabId) {
      case 2:
        return <CreatePlaylist playlist={playlist} />;

      default:
        return (
          <AllPlaylist
            setCurrent={d => {
              setCurrentTab(tabs[1]);
              setPlaylist(d);
            }}
          />
        );
    }
  }, [currentTab]);

  return (
    <DashboardLayout>
      <div>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 15,
            alignItems: 'center',
            paddingBottom: 12,
          }}>
          <ArrowCircleLeftOutlined
            onClick={() => {
              if (currentTab?.tabId == 2) {
                setCurrentTab(tabs[0]);
                return;
              }
              navigate(-1);
            }}
            style={{
              cursor: 'pointer',
            }}
          />
          <HeadLabel label={'Pro Shooter DashBoard | Masterclass'} />
        </div>
        {/* <PrimaryDropDown /> */}

        <CustomTab
          currentTab={currentTab}
          handleClick={handleTabClick}
          tabList={tabs}
        />

        <HandlView />
      </div>
    </DashboardLayout>
  );
}
