import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from 'component/templates/DashboardLayout';
import ViewAllButton from 'component/buttons/ViewAllButton';
import AanalyticsCardComp from 'component/AanalyticsCardComp';
import {
  countAllApplicationsApi,
  downloadAllApplicationsApi,
  downloadAllPaidShootersApi,
  downloadAllSubmissionsApi,
} from 'services/Application';
import {countAllAssetsApi} from 'services/Asset';
import {AgGridReact} from 'ag-grid-react';
import {
  getActivitiesApi,
  getAllBriefApplicationsApi,
  getBriefApplicationsApi,
} from 'services/Brief';

import InfiniteScroll from 'react-infinite-scroller';
import PageCopiesCard from 'component/PageCopiesCard';
import EditShooterAgreement from 'component/EditShooterAgreement';
import TermsOfServices from 'component/TermsOfServices';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {getCopyAPi} from 'services/PageCopy';
import axios from 'axios';
import PrivacyPolicy from 'component/PrivacyPolicy';
import LicensePage from 'component/LicensePage';
import {toast} from 'react-toastify';
import {HeadLabel} from 'component/HeadLabel';
import {ArrowCircleLeftOutlined} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
// import PrivacyPolicy from '../ component/PrivacyPolicy';

export const PageCopies = () => {
  const [selected, setSelected] = useState(1);
  const [discard, setDiscard] = useState(true);

  const [state, setState] = useState({
    applications: 0,
    submissions: 0,
    accepted: 0,
  });

  const getTitle = () => {
    switch (selected - 1) {
      case 0:
        return 'Shooter Agreement';
      case 1:
        return 'Terms of Services';
      case 2:
        return 'Privacy Policy';
      case 3:
        return 'User Licenses';
      default:
        return 'Recent user activity';
    }
  };

  const api = async (file, url) => {
    console.log(file);
    try {
      const res = await axios({
        url: url,
        method: 'put',
        data: file,
        headers: {
          'Content-Type': 'text/plain',
          'Content-Encoding': 'base64',
        },
        onUploadProgress: function (progressEvent) {
          console.log(progressEvent);
        },
        validateStatus: function (status) {
          toast.success('Saved Changes !');
          return status <= 300; // Resolve only if the status code is less than 500
        },
      });

      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadTxtFile = () => {
    switch (selected - 1) {
      case 0:
        return 'Shooter Agreement';
      case 1: {
        const file = new Blob([document.getElementById('terms')?.value], {
          type: 'text/plain',
        });
        getCopyAPi('Terms.txt').then(res => {
          api(new File([file], 'Terms.txt', {type: 'text/plain'}), res?.data);
        });
        return;
      }
      case 2: {
        const file = new Blob([document.getElementById('privacy')?.value], {
          type: 'text/plain',
        });
        getCopyAPi('Privacy.txt').then(res => {
          api(new File([file], 'Privacy.txt', {type: 'text/plain'}), res?.data);
        });
        return;
      }
      case 3:
        const file = new Blob([document.getElementById('license')?.value], {
          type: 'text/plain',
        });
        getCopyAPi('License.txt').then(res => {
          api(new File([file], 'License.txt', {type: 'text/plain'}), res?.data);
        });
        return;
      default:
        return 'Recent user activity';
    }

    // element.href = URL.createObjectURL(file);
    // element.download = "myFile.txt";
    // document.body.appendChild(element); // Required for this to work in FireFox
    // element.click();
  };

  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 15,
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 15,
        }}>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 15,
            alignItems: 'center',
          }}>
          <ArrowCircleLeftOutlined
            onClick={() => navigate(-1)}
            style={{
              // marginLeft: 20,
              cursor: 'pointer',
            }}
          />
          <HeadLabel label={'Manage Page Copy'} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          gap: 60,
          //   height: '20%',
          overflow: 'auto',
          paddingBottom: 48,
        }}>
        <PageCopiesCard
          count={state?.applications}
          label={'Shooter \n Agreement'}
          navTo={''}
          // style={{
          //   backgroundImage:
          //     selected == 1
          //       ? 'linear-gradient(55deg,rgba(100, 101, 227, 0.33),rgba(149, 80, 196, 0.33))'
          //       : '#C6C6C654',
          // }}
          onClick={() => {
            setSelected(1);
          }}
        />
        <PageCopiesCard
          count={state?.submissions}
          label={'Terms of Services'}
          navTo={''}
          // style={{
          //   backgroundImage:
          //     selected == 2
          //       ? 'linear-gradient(55deg,rgba(100, 101, 227, 0.33),rgba(149, 80, 196, 0.33))'
          //       : '#C6C6C654',
          // }}
          onClick={() => {
            setSelected(2);
          }}
        />
        <PageCopiesCard
          count={state?.accepted}
          label={'Privacy Policy'}
          navTo={''}
          // style={{
          //   backgroundImage:
          //     selected == 3
          //       ? 'linear-gradient(55deg,rgba(100, 101, 227, 0.33),rgba(149, 80, 196, 0.33))'
          //       : null,
          // }}
          onClick={() => {
            setSelected(3);
          }}
        />

        <PageCopiesCard
          count={state?.accepted}
          label={'User Licenses'}
          navTo={''}
          // style={{
          //   backgroundImage:
          //     selected == 3
          //       ? 'linear-gradient(55deg,rgba(100, 101, 227, 0.33),rgba(149, 80, 196, 0.33))'
          //       : null,
          // }}
          onClick={() => {
            setSelected(4);
          }}
        />
      </div>
      <div
        style={
          {
            // backgroundColor:"red",
            // padding: '20px 30px',
          }
        }>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 0px',
            backgroundColor: 'rgba(200, 200, 200, 0.33)',
            width: '985px',
            border: '1px solid rgba(0, 0, 0, 0.55)',
          }}>
          <p
            style={{
              fontSize: '20px',
              fontWeight: 500,
              lineHeight: '21px',
              marginLeft: 38,
            }}>
            {getTitle()}
          </p>
          <div
            style={{
              display: 'flex',
              gap: 12,
              marginRight: 38,
            }}>
            <SecondaryButton
              buttonLabel={'Save Changes'}
              onClick={() => {
                uploadTxtFile();
              }}
              // @ts-ignore
              type="button"
              width="130px"
              color="#7729E5"
              labelColor="white"
            />

            <SecondaryButton
              buttonLabel={'Discard'}
              onClick={() => {
                toast.error('Change Discarded');

                setDiscard(!discard);
              }}
              // @ts-ignore
              type="button"
              width="74px"
              color="#736B80"
              labelColor="white"
            />
          </div>
        </div>
        {selected == 2 ? <TermsOfServices discard={discard} /> : null}
        {selected == 3 ? <PrivacyPolicy discard={discard} /> : null}
        {selected == 4 ? <LicensePage discard={discard} /> : null}
      </div>
    </DashboardLayout>
  );
};
