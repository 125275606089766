// Sidebar.jsx
import React from 'react';
import './sidebar.scss';
import sidebarMenus from './sidebarMenus';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from 'store/store';

const Sidebar = ({adminRoleId}) => {
  const location = useLocation();

  const {adminDetails} = useSelector(state => state?.login);

  const filteredMenus = sidebarMenus?.map(section => {
    const filteredSubmenus = section?.subMenus?.filter(submenu =>
      submenu?.allowedRoles?.includes(adminDetails?.role),
    );

    return {
      ...section,
      subMenus: filteredSubmenus,
    };
  });
  const SideBarComp = ({headingTitle, subMenus}) => {
    return (
      <div className="sidebar">
        <p>{headingTitle}</p>
        <hr
          style={{
            width: 179,
            backgroundColor: 'rgba(0, 0, 0, 0.32)',
          }}
        />
        <div>
          {subMenus?.map((data, index) => (
            <p
              key={index}
              className={`${
                data?.isDisabled ? 'submenu-disabled' : 'submenu'
              }`}>
              {!data?.isDisabled ? (
                <Link
                  to={data?.navTo}
                  className={`submenu ${
                    location.pathname === data?.navTo ? 'active' : ''
                  }`}>
                  {data.name}
                </Link>
              ) : (
                <span>{data.name}</span>
              )}
            </p>
          ))}
        </div>
      </div>
    );
  };

  if (filteredMenus?.length > 0) {
    return (
      <div style={{paddingBottom: '10px', width: 288}}>
        {filteredMenus?.map((data, index) => (
          <SideBarComp
            key={index}
            headingTitle={data.headingTitle}
            subMenus={data.subMenus}
          />
        ))}
      </div>
    );
  } else {
    return null;
  }
};

export default Sidebar;
