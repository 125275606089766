import React, {useEffect, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {object, string, number, array} from 'yup';
import RHFInputField from '../RHFInputField';
import RHFPicker from '../RHFPicker';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSelector} from 'react-redux';
import {createAdminUserApi} from 'services/ManageAdmin';
import {fetchAdmins} from 'store/slice/adminSlice';
import RHFPasswordField from '../RHPasswordField';
import {toast} from 'react-toastify';
import RHFTextInputField from '../RHFTextInputField';
import {TagInput} from 'component/input/TagInput';
import {Rating} from '@mui/material';
import {updateAssetApi} from 'services/Asset';
import {dispatch} from 'store/store';
import {updateAsset} from 'store/slice/assetSlice';
import RHFMultiplePicker from '../RHFMultiplePicker';
import DropDownMultiple from 'component/input/DropDownMultiple';
import {getCategory} from 'services/Attachement';

const validationSchema = object({
  title: string().required('Enter a valid title'),
  usage: string().required('Invalid'),
  category: array().required('Select at least one category'),
  description: string().required('Enter a valid description'),
  tags: array(string()).required('Enter valid tags').min(1),
  regionalTags: array(string()).required('Enter valid regional tags').min(1),
  timeOfDay: string().required('Enter valid time'),
  setting: string().required('Enter valid setting'),
  // state: string().required('Enter valid state'),
  // country: string().required('Enter valid country'),
  rating: number().min(0.5),
});

function AssetForm({onDone, asset, duration, resolution}) {
  const [loading, setLoading] = React.useState(false);

  const [usage, setUsage] = useState([
    {id: 1, label: 'Editorial', value: 'Editorial'},
    {id: 2, label: 'Commercial', value: 'Commercial'},
  ]);

  const [categories, setCategories] = useState([
    {id: 1, label: 'Editorial', value: 'Editorial'},
    {id: 2, label: 'Commercial', value: 'Commercial'},
  ]);

  const timeOfDay = [
    {id: 1, label: 'Dawn', value: 'Dawn'},
    {id: 2, label: 'Morning', value: 'Morning'},
    {id: 3, label: 'Noon', value: 'Noon'},
    {id: 4, label: 'Evening', value: 'Evening'},
    {id: 5, label: 'Dusk', value: 'Dusk'},
    {id: 6, label: 'Night', value: 'Night'},
  ];

  // @ts-ignore
  const {adminRoles} = useSelector(state => state?.adminRoles);

  const [tags, setTags] = useState(asset?.tags || []);
  const [regionalTags, setRegionalTags] = useState(asset?.regionalTags || []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: asset,
  });

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    setValue,
    watch,
    getFieldState,
    formState: {errors},
  } = methods;
  const onSubmit = async data => {
    console.log('CreateAdminForm data ', data);

    data['status'] = 1;
    setLoading(true);

    try {
      const res = await updateAssetApi({
        ...data,
        user: null,
        brief: null,
        assetFile: null,
      });
      dispatch(updateAsset({...data, ...getValues()}));
      if (res?.success) {
        toast.success('Asset updated successfully');
      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onArchieve = async () => {
    const data = getValues();
    console.log(data?.rating);
    console.log(data?.category);

    setLoading(true);
    try {
      const res = await updateAssetApi({
        ...data,
        user: null,
        brief: null,
        assetFile: null,
      });
      dispatch(updateAsset({...data, ...getValues()}));
      if (res?.success) {
        toast.success('Asset archieved successfully');
      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset(asset);
    setValue('rating', asset?.rating);
    setTags(asset?.tags);
    setRegionalTags(asset?.regionalTags);
  }, [asset]);

  useEffect(() => {
    getCategory().then(r => {
      // {id: 1, label: 'Editorial', value: 'Editorial'},
      setCategories(
        r?.data?.map((v, i) => {
          return {id: i, label: v?.title, value: v?.title};
        }),
      );
    });
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          gap: 21,
          flexDirection: 'row',
          width: '100%',
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') e.preventDefault();
        }}>
        <div
          style={{
            width: '487px',
            display: 'flex',
            gap: 9,
            flexDirection: 'column',
            minHeight: '341px',
          }}>
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
            }}>
            <p
              style={{
                width: 107,
              }}>
              Asset Title
            </p>
            <RHFInputField
              name="title"
              // @ts-ignore
              register={register}
              label="assetTitle"
              width="380px"
              placeholder="Enter Title here..."
            />
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <p
              style={{
                width: 107,
              }}>
              Usage
            </p>
            <RHFPicker
              name="usage"
              label="Select One"
              options={usage}
              width="180px"
            />
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <p
              style={{
                width: 107,
              }}>
              Category
            </p>
            <div>
              {errors?.category && (
                <p
                  style={{
                    color: 'red',
                    fontSize: 12,
                  }}>
                  {errors?.category?.message}
                </p>
              )}
              <DropDownMultiple
                name="category"
                label="Select"
                options={categories}
                width="180px"
                value={getValues('category') || []}
                setValues={e => {
                  setValue('category', e);
                }}
              />
            </div>
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
            }}>
            <p
              style={{
                width: 107,
              }}>
              Description
            </p>
            <RHFTextInputField
              name="description"
              label="Description"
              placeholder="Brief Description"
              register={register}
              width={'380px'}
              height="65px"
            />
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
            }}>
            <p
              style={{
                width: 107,
              }}>
              Tags
            </p>
            <div>
              {errors?.tags && (
                <p
                  style={{
                    color: 'red',
                    fontSize: 12,
                  }}>
                  {errors?.tags?.message}
                </p>
              )}
              <TagInput
                selectedTags={val => {
                  setTags(val);
                  setValue('tags', val);
                }}
                tags={tags}
                width={'380px'}
                height="69px"
                value={watch('tags')}
              />
            </div>
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
            }}>
            <p
              style={{
                width: 107,
              }}>
              Regional Tags
            </p>
            <div>
              {errors?.regionalTags && (
                <p
                  style={{
                    color: 'red',
                    fontSize: 12,
                  }}>
                  {errors?.regionalTags?.message}
                </p>
              )}
              <TagInput
                selectedTags={val => {
                  setRegionalTags(val);
                  console.log(val, 89);
                  setValue('regionalTags', val);
                }}
                value={watch('regionalTags')}
                tags={regionalTags}
                height="69px"
                width={'380px'}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            gap: 9,
            flexDirection: 'column',
            height: '341',
          }}>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <p
              style={{
                width: 107,
              }}>
              Time of Day
            </p>
            <RHFPicker
              name="timeOfDay"
              label="Select One"
              options={timeOfDay}
              width="180px"
            />
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <p
              style={{
                width: 107,
              }}>
              Setting
            </p>
            <RHFPicker
              name="setting"
              label="Select One"
              options={[
                {id: 1, label: 'Indoor', value: 'Indoor'},
                {id: 2, label: 'Outdoor', value: 'Outdoor'},
              ]}
              width="180px"
            />
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <p
              style={{
                width: 107,
              }}>
              State
            </p>
            <RHFInputField
              name="state"
              // @ts-ignore
              register={register}
              label="assetTitle"
              width="180px"
              placeholder="Enter State here..."
            />
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <p
              style={{
                width: 107,
              }}>
              Country
            </p>
            <RHFInputField
              name="country"
              // @ts-ignore
              register={register}
              label="assetTitle"
              width="180px"
              placeholder="Enter Country here..."
            />
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
            }}>
            <p
              style={{
                width: 107,
              }}>
              Tech Details
            </p>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div
                style={{
                  width: 165,
                  flexDirection: 'row',
                  display: 'flex',
                  fontSize: 10,
                }}>
                <p
                  style={{
                    width: 88,
                  }}>
                  Resolution
                </p>
                {resolution}
              </div>

              <div
                style={{
                  width: 165,
                  flexDirection: 'row',
                  display: 'flex',
                  fontSize: 10,
                }}>
                <p
                  style={{
                    width: 88,
                  }}>
                  Duration
                </p>
                {duration || 0} Sec
              </div>

              <div
                style={{
                  width: 165,
                  flexDirection: 'row',
                  display: 'flex',
                  fontSize: 10,
                }}>
                <p
                  style={{
                    width: 88,
                  }}>
                  Size
                </p>
                {parseFloat(asset?.size * 0.0000001 + '')?.toFixed(2)} MB
              </div>

              <div
                style={{
                  width: 165,
                  flexDirection: 'row',
                  display: 'flex',
                  fontSize: 10,
                }}>
                <p
                  style={{
                    width: 88,
                  }}>
                  Format
                </p>
                {asset?.assetTitle?.split('.')?.[1]}
              </div>

              <div
                style={{
                  width: 165,
                  flexDirection: 'row',
                  display: 'flex',
                  fontSize: 10,
                }}>
                <p
                  style={{
                    width: 88,
                  }}>
                  Frame Rate
                </p>
                {asset?.fps} FPS
              </div>
            </div>
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <p
              style={{
                width: 107,
              }}>
              Rating
            </p>
            <div>
              {errors?.rating && (
                <p
                  style={{
                    color: 'red',
                    fontSize: 12,
                  }}>
                  {errors?.rating?.message}
                </p>
              )}
              <Rating
                name="rating"
                //   defaultValue={parseInt(getValues()?.rating)}
                precision={0.5}
                onChange={(e, value) => setValue('rating', value)}
                value={watch('rating')}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: 5,
            }}>
            <SecondaryButton
              isLoading={loading}
              buttonLabel={'Archieve'}
              // @ts-ignore
              type="button"
              labelColor="white"
              onClick={() => {
                onArchieve();
              }}
            />
            <SecondaryButton
              color="#7729E5"
              isLoading={loading}
              buttonLabel={'Update'}
              // @ts-ignore
              type="submit"
              labelColor="white"
              onClick={() => {
                console.log(232);
                // handleSubmit(onSubmit)();
              }}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default AssetForm;
