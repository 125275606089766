import { getAllAssetsApi } from 'services/Asset';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';



const initialState = {
  adminDetail: [],
};

const assetSlice = createSlice({
  name: 'adminDetailsSlice',
  initialState,
  reducers: {
    clearAdminDetail: () => {
      return initialState;
    },
    setAdminDetail: (state,action) => {
        state.adminDetail = action.payload
    }
    
  },
 
});

const { actions, reducer } = assetSlice

// Reducer
export default reducer;

export const { setAdminDetail } = actions;