import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from 'component/templates/DashboardLayout';
import ViewAllButton from 'component/buttons/ViewAllButton';
import AanalyticsCardComp from 'component/AanalyticsCardComp';
import {
  countAllApplicationsApi,
  downloadAllApplicationsApi,
  downloadAllPaidShootersApi,
  downloadAllSubmissionsApi,
} from 'services/Application';
import {countAllAssetsApi} from 'services/Asset';
import {AgGridReact} from 'ag-grid-react';
import {
  getActivitiesApi,
  getAllBriefApplicationsApi,
  getBriefApplicationsApi,
} from 'services/Brief';

import InfiniteScroll from 'react-infinite-scroller';
import AllBriefApplication from 'pages/useractivity/AllBriefApplication';
import ContentSubmissions from 'pages/useractivity/ContentSubmissions';
import PaidShooters from 'pages/useractivity/PaidShooters';
import AllAssets from './AllAsset';
import {useNavigate} from 'react-router-dom';
import {NavConstant} from 'routes/NavConstant';
import {getTotalShootersApi, getTotalUsersApi} from 'services/Users';
import {useScrollTrigger} from '@material-ui/core';
import {Pagination} from '@mui/material';

export const Aanalytics = () => {
  const [assets, setAssets] = useState(0);
  const [shooters, setShooters] = useState(0);

  const [users, setUsers] = useState(0);
  const [activity, setActivites] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    countAllAssetsApi(null).then(res => setAssets(res?.data));

    getTotalUsersApi().then(res => setUsers(res?.data));
    getTotalShootersApi().then(res => setShooters(res?.data));

    getActivitiesApi().then(res => {
      console.log(res);
      setActivites(res?.data);
    });
  }, []);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(0);

  const getTitle = () => {
    switch (selected) {
      case 1:
        return 'Review Shooter Applications for all briefs';
      case 2:
        return 'Review Content Submissions for all briefs';
      case 3:
        return 'Paid shooters for all briefs';
      default:
        return 'Recent user activity ' + ' Count:  ' + activity?.length;
    }
  };

  const Row = data => {
    const date = new Date(data?.created);
    let title;

    if (data?.activity?.includes('uploaded')) {
      title = 'Uploaded';
    }
    if (data?.activity?.includes('request')) {
      title = 'Withdrawal Request';
    }
    if (data?.activity?.includes('applied')) {
      title = 'Applied';
    }
    if (data?.activity?.includes('applied for kyc')) {
      title = 'International Kyc';
    }
    if (data?.activity?.includes('Aadhar')) {
      title = 'Aadhar Kyc';
    }
    if (data?.activity?.includes('saved')) {
      title = 'Saved';
    }

    return (
      <div
        style={{
          marginTop: 10,
        }}>
        <p
          style={{
            fontFamily: 'Lato',
            fontStyle: 'italic',
            fontWeight: 300,
            fontSize: ' 16px',
            lineHeight: '14px',
            color: '#505050',
          }}>
          {date?.toLocaleDateString('en-GB')} | {date?.toLocaleTimeString()}
        </p>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
          }}>
          <p
            style={{
              fontFamily: 'Lato',
              fontSize: 16,
              color: '#505050',
              fontWeight: '600',
            }}>
            {title} -{' '}
          </p>
          <p>{` ${data?.email || data?.mobile}  ${data?.activity}  ${
            data?.title
          }
        `}</p>
        </div>
      </div>
    );
  };

  const RenderActivity = () => {
    return (
      <>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {}}
          hasMore={false}
          useWindow={true}
          style={{
            listStyleType: 'none',
          }}>
          {activity?.slice(50 * (page - 1), page * 50)?.map(chunk => (
            <li key={chunk}>{Row(chunk)}</li>
          ))}
        </InfiniteScroll>
        <Pagination
          count={parseInt(activity?.length / 50 + '') + 1}
          shape="rounded"
          onChange={(event, p) => {
            setPage(p);
          }}
          page={page}
        />
      </>
    );
  };

  return (
    <DashboardLayout>
      <div
        style={{
          display: 'flex',
          gap: 20,
          height: '180px',
          overflow: 'auto',
        }}>
        <AanalyticsCardComp
          count={assets}
          label={'Total Assets'}
          navTo={''}
          // style={{
          //   backgroundImage:
          //     selected == 1
          //       ? 'linear-gradient(55deg,rgba(100, 101, 227, 0.33),rgba(149, 80, 196, 0.33))'
          //       : '#C6C6C654',
          // }}
          onClick={() => {
            // navig
            // setSelected(1);
            navigate(`${NavConstant.ASSETS}`);
          }}
        />
        <AanalyticsCardComp
          count={users}
          label={'Sign Ups'}
          navTo={''}
          // style={{
          //   backgroundImage:
          //     selected == 2
          //       ? 'linear-gradient(55deg,rgba(100, 101, 227, 0.33),rgba(149, 80, 196, 0.33))'
          //       : '#C6C6C654',
          // }}
          onClick={() => {
            // setSelected(2);
            navigate(`${NavConstant.ALL_SIGNUPS}`);
          }}
        />
        <AanalyticsCardComp
          count={shooters}
          label={'Shooters'}
          navTo={''}
          // style={{
          //   backgroundImage:
          //     selected == 3
          //       ? 'linear-gradient(55deg,rgba(100, 101, 227, 0.33),rgba(149, 80, 196, 0.33))'
          //       : null,
          // }}
          onClick={() => {
            // setSelected(3);
            navigate(`${NavConstant.SHOOTERS}`);
          }}
        />
      </div>
      <div
        style={{
          height: '20%',
          // backgroundColor:"red",
          // padding: '20px 30px',
        }}>
        <RenderActivity />
      </div>
    </DashboardLayout>
  );
};
