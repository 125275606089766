import { createSlice } from '@reduxjs/toolkit';


const initialState = {
 
    adminDetails : {}

};


const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setAdminDetails :(state, action) => {

            state.adminDetails = action.payload
        },

        clearLoginSlice: () => {
            return initialState;
        },
    },

});

const { actions, reducer } = loginSlice

// Reducer
export default reducer;

export const {setAdminDetails } = actions;
