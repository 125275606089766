import fetchHandler from './FetchHandler';

export const createPlaylistApi = data => {
  return fetchHandler({
    method: 'post',
    url: 'admin/masterclass/playlist/create',
    data,
  });
};

export const updatePlaylistApi = data => {
  return fetchHandler({
    method: 'post',
    url: 'admin/masterclass/playlist/update',
    data,
  });
};

export const getPlaylistApi = () => {
  return fetchHandler({
    method: 'post',
    url: 'admin/masterclass/playlist/list',
    data: {},
  });
};

export const createEventApi = data => {
  return fetchHandler({
    method: 'post',
    url: 'admin/masterclass/event/create',
    data,
  });
};

export const updateEventApi = data => {
  return fetchHandler({
    method: 'post',
    url: 'admin/masterclass/event/update',
    data,
  });
};

export const getEventApi = () => {
  return fetchHandler({
    method: 'post',
    url: 'admin/masterclass/event/list',
    data: {},
  });
};

export const createCheatApi = data => {
  return fetchHandler({
    method: 'post',
    url: 'admin/masterclass/cheatsheet/create',
    data,
  });
};

export const updateCheatApi = data => {
  return fetchHandler({
    method: 'post',
    url: 'admin/masterclass/cheatsheet/update',
    data,
  });
};

export const getCheatApi = () => {
  return fetchHandler({
    method: 'post',
    url: 'admin/masterclass/cheatsheet/list',
    data: {},
  });
};

export const deleteCheatApi = data => {
  return fetchHandler({
    method: 'delete',
    url: 'admin/masterclass/cheatsheet/delete',
    data,
  });
};
