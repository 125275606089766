import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAllAdminsApi} from '../../services/ManageAdmin';

export const fetchAdmins = createAsyncThunk(
  'admins/fetchAdmins',
  async () => {
    const response = await getAllAdminsApi();
    return response;
  },
);

const initialState = {
  admins: [],
  page: 1,
  limit: 20,
  loading: true,
  hasMore: false,
  totalPages: 0,
  totalResults: 0,
};

const adminSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    addAdmin: (state, action) => {
      state.totalResults += 1;
      if (state.admins?.length) {
        const data = [action.payload, ...state.admins];
        if (state.admins?.length % 10 === 0) {
          state.totalPages += 1;
        }
        state.admins = data;
        return;
      }

      state.admins = [action.payload];
    },
    updateAdmin: (state, action) => {
      const updated = action.payload;
      if (state.admins) {
        const updatedAdmins = state.admins?.map(a => {
          if (a.id === updated.id) {
            return updated;
          }
          return a;
        });

        state.admins = updatedAdmins;
      }},
    deleteAdmin: (state, action) => {
    //   if (state.products?.length) {
    //     const filteredProds = state.products?.filter(
    //       product => product.id !== action.payload.id,
    //     );

    //     state.totalResults -= 1;
    //     state.products = filteredProds;
    //   }
    },
    clearAdmins: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAdmins.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        state.admins = data
        state.loading = false;
      })
      .addCase(fetchAdmins.pending, state => {
        state.loading = true;
      })
      .addCase(fetchAdmins.rejected, state => {
        state.loading = false;
      });
  },
});

const { actions, reducer } = adminSlice

// Reducer
export default reducer;

// Actions
export const {addAdmin, clearAdmins, deleteAdmin, updateAdmin} = actions;