import {ArrowCircleLeftOutlined} from '@mui/icons-material';
import {Action} from 'component/Action';
import {HeadLabel} from 'component/HeadLabel';
import AssetForm from 'component/forms/comman/AssetForm';
import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {NavConstant} from 'routes/NavConstant';
import {updateActiveBriefAPI} from 'services/Brief';
import {
  fetchAssets,
  fetchBriefAssets,
  searchAssets,
} from 'store/slice/assetSlice';
import {clearBriefs, updateBrief} from 'store/slice/briefSlice';
import {dispatch, useSelector} from 'store/store';
import {
  IconButton,
  Input,
  InputAdornment,
  Pagination,
  Rating,
} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import {GridSearchIcon} from '@mui/x-data-grid';
import {Edit} from 'component/Edit';
import {
  countAllAssetsApi,
  deleteAssetApi,
  exportAllAssetsApi,
} from 'services/Asset';
import {CircleTick} from 'component/CircleTick';
import debounce from 'lodash/debounce';
import SearchInput from 'component/input/SearchInput';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {Delete} from 'component/Delete';

const TaggedAsset = () => {
  const [isLoading, setIsLoading] = useState({});
  const [current, setCurrent] = useState();

  // Column Definitions: Defines & controls grid columns.
  // @ts-ignore
  const [colDefs, setColDefs] = useState([
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
      // renderCell: params =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      field: 'id',
      headerClass: 'super-app-theme--header',
      headerName: 'Asset Id',
      // cellRenderer: LinkComponent,
      width: 200,
      valueGetter: row => row?.data?.id,
    },
    {
      field: 'filename',
      headerClass: 'super-app-theme--header',
      headerName: 'File Name',
      // cellRenderer: LinkComponent,
      width: 150,
      valueGetter: row => row?.data?.title,
    },

    {
      field: 'Shooter',
      width: 100,
      valueGetter: row => row?.data?.user?.email || row?.data?.user?.mobile,
    },
    {
      field: 'shooter',
      headerName: 'Brief Title',
      headerClass: 'super-app-theme--header',
      width: 120,
      valueGetter: row => row?.data?.brief?.title,
    },
    {
      field: 'type',
      headerName: 'Type',
      headerClass: 'super-app-theme--header',
      width: 120,
      valueGetter: row => row?.data?.brief?.type,
    },
    {
      field: 'type',
      headerName: 'Usage',
      headerClass: 'super-app-theme--header',
      width: 120,
      valueGetter: row => row?.data?.usage,
    },
    {
      field: 'type',
      headerName: 'Category',
      headerClass: 'super-app-theme--header',
      width: 120,
      valueGetter: row => row?.data?.category?.join(','),
    },
    {
      field: 'Time Of Day',
      headerName: 'Time Of Day',
      headerClass: 'super-app-theme--header',
      width: 120,
      valueGetter: row => row?.data?.timeOfDay,
    },
    {
      field: 'type',
      headerName: 'Setting',
      headerClass: 'super-app-theme--header',
      width: 120,
      valueGetter: row => row?.data?.setting,
    },

    {
      field: 'shooter',
      headerName: 'Resuolution',
      headerClass: 'super-app-theme--header',
      width: 120,
      // valueGetter: row => row?.data?.size * 0.001 + 'MB',
    },
    {
      field: 'status',
      headerName: 'Rating',
      headerClass: 'super-app-theme--header',
      width: 160,
      cellRenderer: row => {
        return (
          <Rating
            name="rating"
            //   defaultValue={parseInt(getValues()?.rating)}
            precision={0.5}
            // onChange={(e, value) => setValue('rating', value)}
            value={row?.data?.rating}
          />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Premium',
      headerClass: 'super-app-theme--header',
      width: 80,
      cellRenderer: CircleTick,
      valueGetter: row => row?.data?.isPremium,
    },

    {
      field: 'Edit',
      headerName: 'Edit',
      width: 80,
      cellRenderer: Edit,
      onCellClicked: event => {
        setCurrent(event?.data);
        // navigate(`${NavConstant.EditBriefAssets}/${event?.data?.id}`);
      },
      valueGetter: params => {
        return params?.data?.user?.pro;
      },
    },
    {
      field: 'Edit',
      headerName: 'Delete',
      width: 80,
      cellRenderer: Delete,
      onCellClicked: event => {
        deleteAssetApi(event?.data).then(r =>
          dispatch(fetchAssets({page, status: 1})),
        );
        // navigate(`${NavConstant.EditBriefAssets}/${event?.data?.id}`);
      },
      valueGetter: params => {
        return params?.data?.user?.pro;
      },
    },
  ]);

  // @ts-ignore
  const {assets, loading} = useSelector(state => state?.asset);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [resolution, setResolution] = useState();
  const [duration, setDuration] = useState();

  useEffect(() => {
    // @ts-ignore

    countAllAssetsApi(1).then(r => setTotal(r?.data));
    return () => {
      dispatch(clearBriefs());
    };
  }, []);

  const [search, setSearch] = useState('');

  const searchEvent = value => {
    setSearch(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce((text: string) => {
      if (text === '') {
        dispatch(fetchAssets({page, status: 1}));
      } else {
        dispatch(searchAssets({key: text}));
      }
    }, 500),
    [],
  );

  useEffect(() => {
    dispatch(fetchAssets({page, status: 1}));
    setCurrent(null);
  }, [page]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  async function onCellEditRequest(event) {
    const oldData = event.data;
    const field = event.colDef.field;
    const newData = {...oldData};
    newData[field] = event.newValue;
    try {
      const res = await updateActiveBriefAPI(newData);
      // @ts-ignore
      if (res?.success) {
        dispatch(updateBrief(res?.data));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const gridRef = useRef();
  const navigate = useNavigate();

  const onFilterTextBoxChanged = useCallback(e => {
    gridRef.current.api.setGridOption('quickFilterText', e.target.value);
  }, []);

  useEffect(() => {
    document?.getElementById('imgTop')?.setAttribute('src', current?.assetFile);
    document?.getElementById('vidTop')?.setAttribute('src', current?.assetFile);
    document?.getElementById('srcTop')?.setAttribute('src', current?.assetFile);
  }, [current]);

  const downloadFile = async () => {
    var res;
    try {
      res = await exportAllAssetsApi(1);
      console.log(res);
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Tagged_Assets.csv');
      // document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DashboardLayout>
      <div>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 15,
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
            width: 1419,
          }}>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              gap: 15,
              alignItems: 'center',
            }}>
            <ArrowCircleLeftOutlined
              onClick={() => navigate(-1)}
              style={{
                // marginLeft: 20,
                cursor: 'pointer',
              }}
            />
            <HeadLabel label={'Tagged Assets'} />
          </div>
          <SecondaryButton
            buttonLabel={'Export'}
            onClick={downloadFile}
            // @ts-ignore
            type="submit"
          />

          {/* <ViewAllButton /> */}
        </div>
        {current ? (
          <div
            style={{
              minHeight: '365px',
              minWidth: '1419px',
              backgroundColor: 'rgba(200, 200, 200, 0.33)',
              margin: '10px 0px',
              borderRadius: 4,
              padding: 10,
              flexDirection: 'row',
              display: 'flex',
              gap: 24,
            }}>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                // gap: 20,
                width: 580,
                backgroundColor: '#EDEDED',
                borderRadius: 8,
                padding: 8,
                height: 326,
              }}>
              {!(current?.brief?.type == 'Video') ? (
                <img
                  id="imgTop"
                  style={{
                    height: 326,
                    width: 580,
                    borderRadius: 8,
                    border: '1px solid black',
                    objectFit: 'cover',
                  }}
                  onLoad={e => {
                    setResolution(
                      e.currentTarget.naturalWidth +
                        '*' +
                        e.currentTarget.naturalHeight,
                    );
                  }}
                  src={current?.assetFile}
                  onClick={() => {
                    document.getElementById('imgTop')?.requestFullscreen();
                  }}
                />
              ) : (
                <video
                  id="vidTop"
                  style={{
                    width: 580,
                    height: 326,
                    border: '1px solid black',
                    borderRadius: 8,
                  }}
                  key={current}
                  controls={true}
                  preload="metadata"
                  onLoadedMetadataCapture={e => {
                    setResolution(
                      e.currentTarget.videoWidth +
                        '*' +
                        e.currentTarget.videoHeight,
                    );
                    setDuration(e.currentTarget.duration);
                  }}>
                  <source
                    id="srcTop"
                    src={current?.assetFile}
                    // src="https://s3.eu-central-1.wasabisys.com/shotwotwasabitest/Warehouse%40cloud/Shotwot%20Originals/6602c01c67d9576b0e89e6f8/660d02b1734985e90355cbfd-2024-04-25/Asset/VID20240424134954.mp4?AWSAccessKeyId=J9BA04D5J9LHDCQ4URUX&Expires=1714047161&Signature=eTaV9bB4nf2Z23HT5xLA0635j2Q%3D"
                    //   type="video/mp4"
                  />
                </video>
              )}
            </div>
            <AssetForm
              asset={current}
              duration={duration}
              resolution={resolution}
            />
          </div>
        ) : null}
        <div
          style={{
            marginTop: '10px',
            width: 1419,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: 10,
              width: 1419,
              marginLeft: -20,
            }}>
            <SearchInput search={search} setSearch={searchEvent} />

            <p>{(page - 1) * 50 + ' to ' + page * 50 + ' of ' + total}</p>
          </div>
          <div
            className={'ag-theme-quartz'}
            style={{width: '1419px', height: '150vh'}}>
            <AgGridReact
              ref={gridRef}
              defaultColDef={{...defaultColDef}}
              suppressPaginationPanel={true}
              readOnlyEdit={true}
              getRowId={params => params.data.id}
              //   onCellEditRequest={onCellEditRequest}
              rowData={assets}
              columnDefs={colDefs}
              pagination={true}
              paginationPageSize={50}
              rowSelection="multiple"
              scrollbarWidth={9}
              alwaysShowHorizontalScroll={true}
              alwaysShowVerticalScroll={true}
            />
          </div>
          <Pagination
            count={parseInt(total / 50 + '') + 1}
            shape="rounded"
            onChange={(event, p) => {
              console.log(p);
              setPage(p);
            }}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TaggedAsset;
