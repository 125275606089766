import {AgGridReact} from 'ag-grid-react';
import {CircleTick} from 'component/CircleTick';
import {Edit} from 'component/Edit';
import React, {useEffect, useRef, useState} from 'react';
import {
  deleteCheatApi,
  getCheatApi,
  getEventApi,
  getPlaylistApi,
} from 'services/Playlist';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import {Delete} from 'component/Delete';
import {toast} from 'react-toastify';
import {dateComparator} from 'helper/comparator';

const AllCheatSheet = ({setCurrent}) => {
  const [playlist, setPlaylist] = useState([]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const gridRef = useRef();

  const deleteC = r => {
    deleteCheatApi(r?.data)
      .then(res => {
        toast.success('deleted!');
        console.log(playlist);
        getCheatApi().then(res => {
          //   setPlaylist(playlist?.filter(i => i?.id !== r?.data?.id));
          setPlaylist(res?.data);
        });
      })
      .catch(err => toast.error(err));
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
      // renderCell: params =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      field: 'title',
      headerClass: 'super-app-theme--header',
      headerName: 'Event Title',
      width: 200,
    },
    {
      field: 'type',
      headerClass: 'super-app-theme--header',
      headerName: 'Event Type',
      width: 200,
    },

    {
      field: 'title',
      headerClass: 'super-app-theme--header',
      headerName: 'Created',
      width: 140,
      valueGetter: row => {
        const date = new Date(row?.data?.created);
        return date?.toLocaleDateString('en-GB');
      },
      comparator: dateComparator,
    },

    {
      field: 'title',
      headerClass: 'super-app-theme--header',
      headerName: 'Edit',
      width: 80,
      cellRenderer: Edit,
      onCellClicked: r => {
        setCurrent(r?.data);
      },
    },
    {
      field: 'delete',
      headerClass: 'super-app-theme--header',
      headerName: 'Delete',
      width: 80,
      cellRenderer: Delete,
      onCellClicked: r => deleteC(r),
    },
  ]);

  useEffect(() => {
    getCheatApi().then(res => {
      setPlaylist(res?.data);
    });
  }, []);

  async function onCellEditRequest(event) {
    const oldData = event.data;
    const field = event.colDef.field;
    const newData = {...oldData};
    newData[field] = event.newValue;
    try {
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div
      style={{
        // display: 'flex',
        // borderRadius: '20px',
        marginTop: 20,
        // minHeight: '26%',
        // padding: '20px 20px',
        // backgroundColor: '#C6C6C654',
        // boxShadow: '1px 2px 3px #00000040',
        // width: '1176px',
      }}>
      <div
        className={'ag-theme-quartz'}
        style={{width: '100%', height: '50vh'}}>
        <AgGridReact
          ref={gridRef}
          defaultColDef={{...defaultColDef}}
          readOnlyEdit={true}
          getRowId={params => params.data.id}
          onCellEditRequest={onCellEditRequest}
          rowData={playlist}
          columnDefs={colDefs}
          //   pagination={true}
          //   paginationPageSize={100}
          rowSelection="multiple"
          scrollbarWidth={9}
          alwaysShowHorizontalScroll={true}
          alwaysShowVerticalScroll={true}
        />
      </div>
    </div>
  );
};

export default AllCheatSheet;
