import ViewAllButton from 'component/buttons/ViewAllButton';
import UsersDataTable from 'component/datatable/UsersDataTable';
import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useEffect} from 'react';
import {asColumn} from './AllSignupsDatatable';

export default function AllSignups() {
  return (
    <DashboardLayout>
      <UsersDataTable columns={asColumn} />
    </DashboardLayout>
  );
}
