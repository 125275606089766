import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {CustomInput} from '../input/Input';

export default function RHFInputField({
  name,
  register,
  width = '320px',
  height = '32px',
  isMultiline = false,
  ...inputProps
}) {
  const {control} = useFormContext();

  return (
    <Controller
      defaultValue={''}
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              color: '#FC3CB2',
              fontSize: '12px',
            }}>
            {error ? error.message : null}

            {isMultiline ? (
              <textarea
                style={{
                  width,
                  height, // Apply height style for multiline support
                  padding: '8px 12px',
                  boxSizing: 'border-box',
                  marginBottom: 10,
                  marginTop: 5,
                  border: '0px #B0B8C4',
                  borderRadius: '12px',
                  backgroundColor: '#f8f8f8',
                  fontSize: '16px',
                  resize: 'none',
                }}
                {...field}
                {...inputProps}
                onChange={field.onChange}
                {...register(name, {required: true})}
              />
            ) : (
              <input
                style={{
                  width,
                  height,
                  padding: '8px 12px',
                  boxSizing: 'border-box',
                  // marginBottom: 10,
                  // marginTop: 5,
                  border: '0px #B0B8C4',
                  borderRadius: '6px',
                  backgroundColor: 'white',
                  fontSize: '14px',
                  resize: 'none',
                }}
                {...field}
                {...inputProps}
                onChange={field.onChange}
                {...register(name, {required: true})}
              />
            )}
          </div>
        );
      }}
    />
  );
}
