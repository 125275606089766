// @ts-nocheck
import {
  ArrowBack,
  ArrowCircleLeft,
  ArrowCircleLeftOutlined,
} from '@mui/icons-material';
import BackButtonSvg from 'assets/BackButtonSvg';
import CustomButton from 'component/buttons/CustomButton';
import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  briefApplicationActionApi,
  getbriefApplicationApi,
} from 'services/Brief';

export default function BriefApplicationPage() {
  const {id: briefApplicationId} = useParams(); // Use useParams to access the route parameters
  const [isLoading, setIsLoading] = useState({});
  const navigate = useNavigate();

  const [applicationData, setApplicationData] = useState();

  const briefActions = [
    {
      id: 1,
      name: 'Appli',
      status: 1,
      bgColor: '#9550C4',
    },
    {
      id: 2,
      name: 'Shortlist',
      status: 2,
      bgColor: '#FFA500',
    },

    {
      id: 4,
      name: 'Accept',
      status: 4,
      bgColor: '#3B981A',
    },
    {
      id: 3,
      name: 'Reject',
      status: 3,
      bgColor: '#B50000',
    },
    {
      id: 5,
      name: 'Submitt',
      status: 5,
      bgColor: '#9550C4',
    },
    {
      id: 6,
      name: 'Content Reject',
      status: 6,
      bgColor: '#2D2140',
    },
    {
      id: 7,
      name: 'Content Accept',
      status: 7,
      bgColor: '#FF7200',
    },
  ];

  useEffect(() => {
    if (briefApplicationId) {
      getbriefApplicationApi(briefApplicationId).then(res => {
        if (res?.data) {
          console.log(res?.data);
          setApplicationData(res?.data);
        }
      });
    }
  }, [briefApplicationId]);

  const HeadLabel = ({label}) => {
    return (
      <div
        style={{
          fontFamily: 'Poppins',
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '30px',
          letterSpacing: '0em',
          textAlign: 'left',
        }}>
        {label}
      </div>
    );
  };
  const CustomContainer = ({children}) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '20px',
          gap: 20,
          minHeight: '5%',
          height: 'auto',
          padding: '20px 20px',
        }}>
        {children}
      </div>
    );
  };
  const BtnContainer = ({children}) => {
    return (
      <div
        style={{
          display: 'flex',

          borderRadius: '20px',
          gap: 10,
          minHeight: '5%',
          height: 'auto',
          padding: '20px 20px',

          justifyContent: 'end',
        }}>
        {children}
      </div>
    );
  };
  const leftLabelStyle = {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    width: '30%',
  };
  const LabelPair = ({leftLabel, rightLabel, lineHeight = '24px'}) => {
    const rightLabelStyle = {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '24px',
      width: '70%',
    };

    return (
      <div style={{display: 'flex', width: '100%', marginTop: 10}}>
        {/* @ts-ignore */}
        <span style={{...leftLabelStyle, lineHeight}}>{leftLabel}</span>

        {leftLabel?.toLowerCase().includes('note') ? (
          <span
            style={{
              ...rightLabelStyle,
              border: '2px solid #45454550',
              borderRadius: 8,
              padding: 12,
            }}>
            {rightLabel}
          </span>
        ) : Array.isArray(rightLabel) ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            {rightLabel?.map(data => {
              return <span style={{...rightLabelStyle}}>{data}</span>;
            })}
          </div>
        ) : (
          <span style={{...rightLabelStyle}}>{rightLabel}</span>
        )}
      </div>
    );
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleActions = async data => {
    setIsLoading(prevLoading => ({...prevLoading, [data.id]: true}));
    let rawPayload = {
      status: data?.status,
      id: briefApplicationId,
      userId: applicationData?.user?.id,
      briefId: applicationData?.brief?.id,
    };

    try {
      const res = await briefApplicationActionApi(rawPayload);
      if (res?.success) {
        setApplicationData(prev => {
          return {...prev, status: res?.data?.status};
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(prevLoading => ({...prevLoading, [data.id]: false}));
    }
  };

  const getStatus = () => {
    switch (applicationData?.status) {
      case 1:
        return 'Applied';
      case 2:
        return 'Shortlisted';
      case 3:
        return 'Rejected';
      case 4:
        return 'Accepted';
      case 5:
        return 'Content Submitted';
      case 6:
        return 'Content Shortlisted';
      case 7:
        return 'Content Rejected';
      case 8:
        return 'Content Approved';
      default:
        return '';
    }
  };

  return (
    <DashboardLayout>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 15,
          alignItems: 'center',
        }}>
        <ArrowCircleLeftOutlined
          onClick={handleGoBack}
          style={{
            marginLeft: 20,
            cursor: 'pointer',
          }}
        />
        <HeadLabel label={'Shooter Details'} />
      </div>
      <CustomContainer>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 20,
          }}>
          <div
            style={{
              width: '50%',
              overflow: 'hidden',
            }}>
            <LabelPair
              leftLabel={'UserName'}
              rightLabel={applicationData?.user?.username}
            />
            <LabelPair
              leftLabel={'Email'}
              rightLabel={applicationData?.user?.email}
            />
            <LabelPair
              leftLabel={'Mobile'}
              rightLabel={applicationData?.user?.mobile}
            />

            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                marginTop: 8,
              }}>
              <span
                style={{
                  fontSize: '14px',
                  justifyContent: 'center',
                  ...leftLabelStyle,
                  lineHeight: '30px',
                }}>
                Instagram
              </span>
              <a
                style={{fontSize: 14, overflow: 'hidden', width: '70%'}}
                href={
                  'https://www.instagram.com/' + applicationData?.instagram
                }>
                {applicationData?.instagram}
              </a>
            </div>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                marginTop: 8,
              }}>
              <span
                style={{
                  fontSize: '14px',
                  justifyContent: 'center',
                  marginTop: 8,
                  ...leftLabelStyle,
                  lineHeight: '30px',
                }}>
                Facebook
              </span>
              <a
                style={{fontSize: 14, overflow: 'hidden', width: '70%'}}
                href={'https://www.facebook.com/' + applicationData?.facebook}>
                {applicationData?.facebook}
              </a>
            </div>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                marginTop: 8,
              }}>
              <span
                style={{
                  fontSize: '14px',
                  justifyContent: 'center',
                  marginTop: 8,
                  ...leftLabelStyle,
                  lineHeight: '30px',
                }}>
                Linkedin
              </span>
              <a
                style={{fontSize: 14, overflow: 'hidden', width: '70%'}}
                href={
                  'https://www.linkedin.com/in/' + applicationData?.linkedin
                }>
                {applicationData?.linkedin}
              </a>
            </div>
          </div>

          <div
            style={{
              width: '50%',
            }}>
            <LabelPair
              leftLabel={'Brief ID'}
              rightLabel={applicationData?.brief?.id}
            />
            <LabelPair
              leftLabel={'Brief Title'}
              rightLabel={applicationData?.brief?.title}
            />
            <LabelPair
              leftLabel={'Total Assets'}
              rightLabel={applicationData?.brief?.assets}
            />
            <LabelPair leftLabel={'Status'} rightLabel={getStatus()} />
            <LabelPair
              leftLabel={'Pro Shooter'}
              rightLabel={
                applicationData?.user?.pro ? 'Subcribed' : 'UnSubscribed'
              }
            />
          </div>
        </div>
      </CustomContainer>

      <CustomContainer>
        <HeadLabel label={'Application Details '} />
        <LabelPair
          leftLabel={'Date'}
          rightLabel={new Date(applicationData?.created)?.toLocaleString()}
        />
        <LabelPair
          leftLabel={'Time'}
          rightLabel={new Date(applicationData?.created)?.toLocaleTimeString()}
        />
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            marginTop: 8,
          }}>
          <span
            style={{
              fontSize: '14px',
              justifyContent: 'center',
              ...leftLabelStyle,
              lineHeight: '30px',
              width: '26%',
            }}>
            Showreel
          </span>
          <ul
            style={{
              listStyle: 'none',
            }}>
            {applicationData?.reel?.map(i => (
              <li>
                <a style={{fontSize: 14, overflow: 'hidden'}} href={i}>
                  {i}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <LabelPair
          leftLabel={'Treatment note'}
          rightLabel={applicationData?.note}
          lineHeight={'140px'}
        />
      </CustomContainer>

      <BtnContainer>
        {briefActions
          ?.filter(d => {
            switch (d?.id) {
              case 2:
                return true;
              case 3:
                return true;
              case 4:
                return true;
              default:
                return false;
            }
          })
          .map(data => {
            return (
              <CustomButton
                key={data?.id}
                onClick={() => handleActions(data)}
                buttonLabel={data?.name}
                bgColor={data?.bgColor}
                labelColor="#FFFFFF"
                isLoading={isLoading[data.id]}
              />
            );
          })}
      </BtnContainer>
    </DashboardLayout>
  );
}
