// RHFPasswordField.jsx
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PasswordInput from 'component/input/PasswordInput';

export default function RHFPasswordField({name, width, style, ...inputProps}) {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <PasswordInput
          style={{
            ...style,
          }}
          password={field.value}
          setPassword={value => field.onChange(value)}
          {...inputProps}
          errorMessage={error?.message || ''}
        />
      )}
    />
  );
}
