import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {object, string, number} from 'yup';
import RHFInputField from '../RHFInputField';
import RHFPicker from '../RHFPicker';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSelector} from 'react-redux';
import {createAdminUserApi} from 'services/ManageAdmin';
import {fetchAdmins} from 'store/slice/adminSlice';
import RHFPasswordField from '../RHPasswordField';
import {toast} from 'react-toastify';

const validationSchema = object({
  email: string().email('Invalid email address').required('Email is required'),
  role: number().required('Admin Role is required'),
  password: string().required('Password is required').min(8).max(15),
});

function CreateAdminForm({onDone}) {
  const [loading, setLoading] = React.useState(false);

  // @ts-ignore
  const {adminRoles} = useSelector(state => state?.adminRoles);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {handleSubmit, register} = methods;

  const onSubmit = async data => {
    console.log('CreateAdminForm data ', data);
    setLoading(true);
    try {
      await createAdminUserApi(data);
      toast.success('New User created successfully');
    } catch (error) {
      toast.error(error);
      console.log(error);
    } finally {
      setLoading(false);
      onDone(data);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: '522px',
          display: 'flex',
          gap: 10,
          flexDirection: 'column',
          marginTop: '20px',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <RHFInputField
            name="firstname"
            // @ts-ignore
            register={register}
            label="First name"
            placeholder="First name"
            width="254px"
          />

          <RHFInputField
            name="lastname"
            // @ts-ignore
            register={register}
            label="Last name"
            placeholder="Last name"
            width="254px"
          />
        </div>
        <RHFInputField
          name="email"
          // @ts-ignore
          register={register}
          label="Email"
          width="522px"
          placeholder="Email"
        />

        <RHFPasswordField
          name={'password'}
          style={{
            width: '498px',
            height: '32px',
          }}
          placeholder="Password"
        />
        <RHFPicker
          name="role"
          label="Admin Role"
          options={adminRoles}
          width="522px"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '30px',
          }}>
          <SecondaryButton
            isLoading={loading}
            buttonLabel={'Create'}
            // @ts-ignore
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default CreateAdminForm;
