import {yupResolver} from '@hookform/resolvers/yup';
import {ArrowCircleLeftOutlined} from '@mui/icons-material';
import {LinearProgress} from '@mui/material';
import axios from 'axios';
import {HeadLabel} from 'component/HeadLabel';
import HeadingComp from 'component/HeadingComp';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {RHFInputField} from 'component/forms';
import RHFTextInputField from 'component/forms/RHFTextInputField';
import {SingleImageUpload} from 'component/images/SingleImageUpload';
import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useEffect, useRef, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import ReactModal from 'react-modal';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  getAttahcement,
  getCategory,
  getPricing,
  postAttahcement,
  postCategory,
  postPricing,
} from 'services/Attachement';
import * as yup from 'yup';

const customStyles = {
  content: {
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0px',
    padding: -20,
    // backdropFilter: 'blur(1000px)',

    overlay: {
      backdropFilter: 'blur(10000px)',
    },
  },
};

function Category() {
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({});

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    setValue,
    register,
    control,
    getValues,
    setError,
    reset,
  } = methods;

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [current, setCurrent] = React.useState({});
  const [categories, setCategories] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [progress, setProgress] = React.useState(0);

  const api = async (file, url) => {
    await axios({
      url: url,
      method: 'put',
      data: file,
      headers: {
        'Content-Type': file.type,
        'Content-Encoding': 'base64',
      },
      onUploadProgress: function (progressEvent) {
        console.log(progressEvent);
        setProgress(prev => prev + progressEvent?.bytes);
      },
      validateStatus: function (status) {
        return status <= 300; // Resolve only if the status code is less than 500
      },
    });
  };

  const onSubmit = async data => {
    let f = null;
    if (file?.size > 0) {
      f = {
        name: file?.name,
        type: file?.type,
      };
    }
    postCategory({
      ...data,
      file: f,
    }).then(async r => {
      toast.success('category updated');

      if (file?.size > 0) {
        await api(file, r?.data?.url);
      }
    });
  };

  useEffect(() => {
    getCategory().then(r => {
      setCategories(r?.data);
    });
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setProgress(0);
    reset({
      title: '',
      description: '',
      thumbnail: '',
    });
    setFile();
    setIsOpen(false);
    getCategory().then(r => {
      setCategories(r?.data);
    });
  }

  return (
    <DashboardLayout>
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}>
        <div
          style={{
            display: 'flex',
            borderRadius: '6px',
            minHeight: '26%',
            padding: '20px 20px',
            backgroundColor: '#C6C6C654',
            boxShadow: '1px 2px 3px #00000040',
            marginTop: 20,
            flexDirection: 'column',
            // width: 1044,
          }}>
          {progress !== 0 ? (
            <LinearProgress
              variant="determinate"
              value={parseInt((progress * 100) / file?.size + '')}
              sx={{
                backgroundColor: '#D9D9D9',
                span: {
                  background: 'linear-gradient(to right, #FC3CB2, #00C0FB)',
                },
                height: '10px',
                'border-radius': '25px',
                marginTop: 5,
                marginBottom: 5,
              }}
            />
          ) : null}
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                flexDirection: 'row',
                display: 'flex',
                gap: 40,
              }}>
              <div
                style={{
                  width: '680px',
                  display: 'flex',
                  gap: 10,
                  flexDirection: 'column',
                  marginTop: '20px',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <p>Category Title</p>
                  <RHFInputField
                    name="title"
                    // @ts-ignore
                    register={register}
                    label=""
                    placeholder="Title"
                    width="540px"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <p>Category Description</p>

                  <RHFTextInputField
                    name="description"
                    // @ts-ignore
                    register={register}
                    label="Last name"
                    placeholder="Description"
                    width="540px"
                    height="200px"
                  />
                </div>
              </div>
              <div
                style={{
                  borderLeft: '1px solid',
                  borderColor: '#00000050',
                  //   padding: '10px 20px',
                  //    backgroundColor:"green",
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}></div>
              <div
                style={{
                  //   gap: 20,
                  //   justifyContent: 'center',
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <SingleImageUpload
                  image={file}
                  setImage={setFile}
                  height={149}
                  width={149}
                />
                <p>Add Thumbnail</p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '30px',
                    flexDirection: 'row',
                    gap: 12,
                  }}>
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    onClick={e => {
                      reset({
                        title: '',
                        description: '',
                        thumbnail: '',
                      });
                      setFile();
                      e?.preventDefault();
                    }}
                    // @ts-ignore
                    type="button"
                    color="#555555"
                    labelColor="white"
                  />
                  <SecondaryButton
                    buttonLabel={getValues('id') ? 'Update' : 'Create'}
                    color="#7729E5"
                    labelColor="white"
                    // @ts-ignore
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ReactModal>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 15,
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 925,
          marginBottom: 16,
        }}>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 15,
            alignItems: 'center',
          }}>
          <ArrowCircleLeftOutlined
            onClick={() => navigate(-1)}
            style={{
              // marginLeft: 20,
              cursor: 'pointer',
            }}
          />
          <HeadLabel label={'Category'} />
        </div>
        <SecondaryButton
          buttonLabel={'Create New'}
          onClick={openModal}
          // @ts-ignore
          type="button"
        />
      </div>
      <div
        style={{
          gap: 12,
          display: 'flex',
          flexDirection: 'column',
        }}>
        {categories?.map(item => (
          <div
            style={{
              display: 'flex',
              padding: 10,
              backgroundColor: 'rgba(200, 200, 200, 0.33)',
              borderRadius: 6,
              width: '905px',
              flexDirection: 'row',
              gap: 12,
            }}>
            <img
              style={{
                height: 214,
                width: 214,
                zIndex: 10,
                borderRadius: 10,
                objectFit: 'cover',
              }}
              // className="image"
              alt="cardImage"
              src={'https://shotwot-test.b-cdn.net/' + item?.thumbnail}
            />
            <div
              style={{
                flexDirection: 'column',
                gap: 12,
                display: 'flex',
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: 642,
                  justifyContent: 'space-between',
                }}>
                <p
                  style={{
                    fontWeight: '600',
                  }}>
                  Category Title
                </p>
                <p
                  style={{
                    width: 445,
                  }}>
                  {item?.title}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: 642,
                  justifyContent: 'space-between',
                  height: 110,
                  overflow: 'hidden',
                }}>
                <p
                  style={{
                    fontWeight: '600',
                  }}>
                  Category Description
                </p>
                <p
                  style={{
                    width: 445,
                  }}>
                  {item?.description}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 400,
                }}>
                <p style={{}}>Asset Count</p>
                <p>{item?.count}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  justifySelf: 'flex-end',
                }}>
                <SecondaryButton
                  buttonLabel={'Edit'}
                  color="#7729E5"
                  labelColor="white"
                  onClick={() => {
                    reset(item);
                    setFile(
                      'https://shotwot-test.b-cdn.net/' +
                        categories?.[0]?.thumbnail,
                    );
                    openModal();
                  }}
                  // @ts-ignore
                  type="submit"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </DashboardLayout>
  );
}

export default Category;
