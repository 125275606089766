import {Icon, Input, SearchIcon} from 'native-base';
import * as React from 'react';

export default function SearchInput({
  search,
  setSearch,
  errorMessage,
  onChange = null,
}) {
  return (
    <>
      {errorMessage && (
        <p style={{color: '#FC3CB2', marginLeft: 4, fontSize: '12px'}}>
          {errorMessage}
        </p>
      )}
      <Input
        mx="3"
        placeholder="Search"
        InputLeftElement={
          <SearchIcon
            style={{
              marginLeft: 10,
            }}
          />
        }
        w="35%"
        style={{}}
        onChangeText={e => {
          setSearch(e);
        }}
      />
      {/* <Input
        placeholder="Search"
        id="outlined-adornment-password"
        type={'text'}
        value={search}
        onChange={onchange != null ? onChange : e => setSearch(e.target.value)}
        endAdornment={
          <InputAdornment position="start">
            <IconButton type="button" sx={{p: '10px'}} aria-label="search">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      /> */}
    </>
  );
}

const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
