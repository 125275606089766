import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from 'component/templates/DashboardLayout';
import ViewAllButton from 'component/buttons/ViewAllButton';
import AanalyticsCardComp from 'component/AanalyticsCardComp';
import {
  countAllApplicationsApi,
  downloadAllApplicationsApi,
  downloadAllPaidShootersApi,
  downloadAllSubmissionsApi,
} from 'services/Application';
import {countAllAssetsApi} from 'services/Asset';
import {AgGridReact} from 'ag-grid-react';
import {
  getActivitiesApi,
  getAllBriefApplicationsApi,
  getBriefApplicationsApi,
} from 'services/Brief';
import AllBriefApplication from './AllBriefApplication';
import ContentSubmissions from './ContentSubmissions';
import PaidShooters from './PaidShooters';
import InfiniteScroll from 'react-infinite-scroller';
import {Pagination} from '@mui/material';

export const UserActivity = () => {
  const [selected, setSelected] = useState(0);

  const [applications, setApplications] = useState(0);
  const [page, setPage] = useState(1);
  const [activity, setActivites] = useState([]);

  const [state, setState] = useState({
    applications: 0,
    submissions: 0,
    accepted: 0,
  });

  const exportCsv = () => {
    switch (selected) {
      case 1:
        downloadAllApplicationsApi().then(res => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Brief_Applications.csv');
          // document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });

        break;
      case 2:
        downloadAllSubmissionsApi().then(res => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Submissions.csv');
          // document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });

        break;
      case 3:
        downloadAllPaidShootersApi().then(res => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Paid_Shooters.csv');
          // document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    countAllApplicationsApi().then(res => {
      setState({
        applications: res?.data?.applications || 0,
        submissions: res?.data?.submissions || 0,
        accepted: res?.data?.accepted || 0,
      });
    });

    getActivitiesApi().then(res => {
      console.log(res);
      setActivites(res?.data);
    });
  }, []);

  useEffect(() => {
    const getData = async () => {
      switch (selected) {
        case 1:
          return await getAllBriefApplicationsApi();
        default:
          break;
      }
    };
  }, [selected]);

  const getTitle = () => {
    switch (selected) {
      case 1:
        return 'Review Shooter Applications for all briefs';
      case 2:
        return 'Review Content Submissions for all briefs';
      case 3:
        return 'Paid shooters for all briefs';
      default:
        return 'Recent user activity ' + ' Count:  ' + activity?.length;
    }
  };

  const Row = data => {
    const date = new Date(data?.created);
    let title;

    if (data?.activity?.includes('uploaded')) {
      title = 'Uploaded';
    }
    if (data?.activity?.includes('request')) {
      title = 'Withdrawal Request';
    }
    if (data?.activity?.includes('applied')) {
      title = 'Applied';
    }
    if (data?.activity?.includes('applied for kyc')) {
      title = 'International Kyc';
    }
    if (data?.activity?.includes('Aadhar')) {
      title = 'Aadhar Kyc';
    }
    if (data?.activity?.includes('saved')) {
      title = 'Saved';
    }

    return (
      <div
        style={{
          marginTop: 10,
        }}>
        <p
          style={{
            fontFamily: 'Lato',
            fontStyle: 'italic',
            fontWeight: 300,
            fontSize: ' 16px',
            lineHeight: '14px',
            color: '#505050',
          }}>
          {date?.toLocaleDateString('en-GB')} | {date?.toLocaleTimeString()}
        </p>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
          }}>
          <p
            style={{
              fontFamily: 'Lato',
              fontSize: 16,
              color: '#505050',
              fontWeight: '600',
            }}>
            {title} -{' '}
          </p>
          <p>{` ${data?.email || data?.mobile}  ${data?.activity}  ${
            data?.title
          }
        `}</p>
        </div>
      </div>
    );
  };

  const RenderActivity = () => {
    return (
      <>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {}}
          hasMore={false}
          useWindow={true}
          style={{
            listStyleType: 'none',
          }}>
          {activity?.slice(50 * (page - 1), page * 50)?.map(chunk => (
            <li key={chunk}>{Row(chunk)}</li>
          ))}
        </InfiniteScroll>
        <Pagination
          count={parseInt(activity?.length / 50 + '') + 1}
          shape="rounded"
          onChange={(event, p) => {
            setPage(p);
          }}
          page={page}
        />
      </>
    );
  };

  return (
    <DashboardLayout>
      <div
        style={{
          display: 'flex',
          gap: 20,
          // height: '20%',
          overflow: 'auto',
          height: '185px',
        }}>
        <AanalyticsCardComp
          count={state?.applications}
          label={'Brief Applications'}
          navTo={''}
          // style={{
          //   backgroundImage:
          //     selected == 1
          //       ? 'linear-gradient(55deg,rgba(100, 101, 227, 0.33),rgba(149, 80, 196, 0.33))'
          //       : '#C6C6C654',
          // }}
          onClick={() => {
            setSelected(1);
          }}
        />
        <AanalyticsCardComp
          count={state?.submissions}
          label={'Content Submissions '}
          navTo={''}
          // style={{
          //   backgroundImage:
          //     selected == 2
          //       ? 'linear-gradient(55deg,rgba(100, 101, 227, 0.33),rgba(149, 80, 196, 0.33))'
          //       : '#C6C6C654',
          // }}
          onClick={() => {
            setSelected(2);
          }}
        />
        <AanalyticsCardComp
          count={state?.accepted}
          label={'Paid Shooters'}
          navTo={''}
          // style={{
          //   backgroundImage:
          //     selected == 3
          //       ? 'linear-gradient(55deg,rgba(100, 101, 227, 0.33),rgba(149, 80, 196, 0.33))'
          //       : null,
          // }}
          onClick={() => {
            setSelected(3);
          }}
        />
      </div>
      <div
        style={{
          height: '20%',
          // backgroundColor:"red",
          // padding: '20px 30px',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 0px',
          }}>
          <p
            style={{
              fontSize: '20px',
              fontWeight: 500,
              lineHeight: '21px',
            }}>
            {getTitle()}
          </p>
          {selected == 0 ? null : (
            <p
              onClick={exportCsv}
              style={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '21px',
              }}>
              Export CSV
            </p>
          )}
        </div>
        {selected == 1 ? <AllBriefApplication /> : null}
        {selected == 2 ? <ContentSubmissions /> : null}
        {selected == 3 ? <PaidShooters /> : null}
        {selected == 0 && <RenderActivity />}
      </div>
    </DashboardLayout>
  );
};
