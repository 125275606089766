import React from 'react';
import './buttons.scss';

export default function SecondaryButton({
  color = '#D9D9D9',
  labelColor = '#014155',
  buttonLabel = 'Click',
  onClick,
  isLoading = false,
  width = '110px',
  fontStyle,
  type,
}) {
  return (
    <button
      onClick={onClick}
      className="submit-btn"
      type={type}
      style={{
        height: '40px',
        width,
        backgroundColor: color,

        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',

        color: labelColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',

        // font styles
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '14px',
        textTransform: 'capitalize',
        ...fontStyle,
      }}>
      {isLoading ? <div className="loader" /> : buttonLabel}
    </button>
  );
}
