import React from 'react'

export default function ErrorBlock({
    visible = false,
    label
}) {
    
    

    return (

        visible &&     
        <span
            style={{
                color: '#FC3CB2',
                justifyContent: "flex-start",
                display: 'flex',
                marginBottom: 10,
                fontWeight: 300,
                fontSize: 14

            }}>
            {label ? label : "NA"}
        </span>
    )
}
