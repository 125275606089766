import {getAllBriefsApi, getDraftBriefsApi} from 'services/Brief';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getWithdrawsApi} from 'services/WithDraws';

export const fetchWithdraws = createAsyncThunk(
  'users/fetchWithdraws',
  // @ts-ignore
  async () => {
    const response = await getWithdrawsApi({s: 0, l: 0});
    return response;
  },
);

const initialState = {
  withdraws: [],
};

const briefSlice = createSlice({
  name: 'withdraws',
  initialState,
  reducers: {
    updateWithdraw: (state, action) => {
      const updatedBrief = action.payload;
      if (state.withdraws) {
        const updatedBriefs = state.withdraws?.map(brief => {
          if (brief.id === updatedBrief.id) {
            return updatedBrief;
          }
          return brief;
        });

        state.withdraws = updatedBriefs;
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchWithdraws.fulfilled, (state, action) => {
      const data = action?.payload?.data;
      state.withdraws = data;
    });
  },
});

const {actions, reducer} = briefSlice;

// Reducer
export default reducer;

export const {updateWithdraw} = actions;
