import Terms from '../../component/terms';
import Logo from '../../component/logo/logo';
import './progress.scss';
import React, {useState, useEffect, useCallback, useRef} from 'react';
import bunnyHandler from '../../services/FetchHandler';
import axios from 'axios';
import Button from '@mui/material/Button';
import {LinearProgress} from '@mui/material';
import ThumbsUp from '../../assets/thumbsUp';
import Warning from '../../assets/warning';
import './temp.scss';

const TrackProgress = ({files, totalSize}) => {
  const [selectedFiles, setSelectedFiles] = useState(files);
  const [length, setLength] = useState(totalSize || 1);
  const [track, setTrack] = useState(0);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const [started, setStarted] = useState(false);
  const [width, setWidth] = useState(0);
  const dateObj = new Date();
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  const today = new Date();

  const date = new Date();
  const hour = date.getHours();
  const min = date.getMinutes();
  console.log(hour, min);

  // Getting full month name (e.g. "September")
  const month = today.toLocaleString('en-EN', {month: 'long'});

  const newDate = year + '/' + month + '/' + day + '/' + hour + ':' + min;

  const api = useCallback(file => {
    axios({
      url: `https://warehouse-test.s3.eu-central-1.wasabisys.com/warehouse.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=CYH4MC8ZAIQCNKXS0I9C%2F20240118%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20240118T124101Z&X-Amz-Expires=100000&X-Amz-Security-Token=TOKEN&X-Amz-SignedHeaders=content-encoding%3Bhost&x-id=PutObject&X-Amz-Signature=cfa9274ccac3c9a3909607dae88cbff27c24c4222875e700be2e93108a2c59af`,
      method: 'put',
      data: file,
      headers: {
        'Content-Type': 'video/mp4',
        'Content-Encoding': 'base64',
      },
      onUploadProgress: function (progressEvent) {
        setTrack(prev => prev + progressEvent?.bytes);
        console.log(progressEvent);
        if (progressEvent.progress === 1) setProgress(prev => prev + 1);
      },
      validateStatus: function (status) {
        console.log(status);
        return status <= 300; // Resolve only if the status code is less than 500
      },
    }).catch(function (error) {
      console.log(error);
      setError(true);
    });
  }, []);

  useEffect(() => {
    setWidth(parseInt((track * 100) / length));

    if (started === false) {
      setStarted(true);
      for (let file of selectedFiles) {
        api(file);
      }
    }

    return () => {};
  }, [track]);

  return (
    <div
      style={{
        flexDirection: 'column',
        height: '100vh',
        display: 'flex',
      }}>
      <div
        style={{
          display: 'flex',
          height: '80vh',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <div style={{width: '50%', paddingLeft: '10%'}}>
          <Logo />
        </div>
        <div
          style={{
            width: '50%',
            marginRight: '10%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          <div className="cir" style={{}}>
            {parseInt((track * 100) / length) > 100
              ? 100
              : parseInt((track * 100) / length)}
            %
          </div>
          <LinearProgress
            variant="determinate"
            value={width}
            sx={{
              backgroundColor: '#D9D9D9',
              span: {background: 'linear-gradient(to right, #FC3CB2, #00C0FB)'},
              height: '50px',
              'border-radius': '25px',
              marginTop: 5,
              marginBottom: 5,
            }}
          />
          <Button
            component="label"
            variant="contained"
            sx={{boxShadow: 24}}
            style={{
              backgroundColor: '#FC3CB2',
              color: 'white',
              marginLeft: '85%',
              marginTop: '10px',
              fontFamily: 'Poppins',
              fontSize: 18,
            }}
            onClick={() => {
              // window.location.reload(false);
              window.stop();
              setError(true);
            }}>
            Cancel
          </Button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Terms />
      </div>
      {progress >= selectedFiles?.length && progress !== 0 && (
        <div className="modal">
          <div style={{marginBottom: 50}}>
            <ThumbsUp />
          </div>
          <p>Wohoo !!</p>
          <p>All files Uploaded Successfully</p>
          <Button
            component="label"
            variant="contained"
            sx={{boxShadow: 24}}
            style={{
              backgroundColor: '#D9D9D9',
              color: '#014155',
              fontFamily: 'Poppins',
              fontSize: 18,
              marginTop: 50,
            }}
            onClick={() => {
              window.location.reload(false);
            }}>
            {'Upload More'}
          </Button>
        </div>
      )}
      {error && (
        <div className="modal">
          <div style={{marginBottom: 50}}>
            <Warning />
          </div>
          <p>Ops !</p>
          <p>Upload interrupted</p>
          <Button
            component="label"
            variant="contained"
            sx={{boxShadow: 24}}
            style={{
              backgroundColor: '#D9D9D9',
              color: '#014155',
              fontFamily: 'Poppins',
              fontSize: 18,
              marginTop: 50,
            }}
            onClick={() => {
              window.location.reload(false);
            }}>
            {'Try Again'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TrackProgress;
