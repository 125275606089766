import {initializeApp} from 'firebase/app';

import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth';
import {toast} from 'react-toastify';

const firebaseConfig = {
  apiKey: 'AIzaSyBDTkwcCvhQQfQ4jnT9J32wnAaY1SNTFI4',
  authDomain: 'shotwot-prod.firebaseapp.com',
  projectId: 'shotwot-prod',
  storageBucket: 'shotwot-prod.appspot.com',
  messagingSenderId: '538773713349',
  appId: '1:538773713349:web:752922ef64649cd2e0dbac',
  measurementId: 'G-S633WGBSGX',
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const logInWithEmailAndPassword = async (email, password) => {
  try {
    var result = await signInWithEmailAndPassword(auth, email, password);
    return result;
  } catch (err) {
    toast.error('Invalid Email Or Password');
    console.error(err);
    return false;
  }
};

export const sendPasswordReset = async email => {
  try {
    var result = await sendPasswordResetEmail(auth, email);
    toast.success('Reset email was sent successfully!');
    return result;
  } catch (err) {
    console.error(err);
    toast.error(err);
    return false;
  }
};
