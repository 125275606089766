import React from 'react'

export default function PrimaryButton({

    buttonLabel,
    onClick
}) {
    return (
        <button
            onClick={onClick}

            style={{
                height: "40px",
                width: "110px",
                backgroundColor: '#D9D9D9',
                color: '#014155',
                border:"none",
                borderRadius: "5px",
                cursor: "pointer",

                // font styles
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: "20px",
                textTransform: "capitalize",

       
                boxShadow:"1px 2px 4px #000000",
                
            }}
        >
            {buttonLabel ? buttonLabel : "NA"}
        </button>
    )
}



