import React, {useRef} from 'react';
import './styles.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {UploadContainer} from './UploadContainer';
import {constants} from 'helper/constant';
import DustbinSvg from 'assets/DustbinSvg';
import {Delete, DeleteRounded} from '@mui/icons-material';
import {red} from '@mui/material/colors';
import {IconButton} from '@material-ui/core';

export default function ImageSlider({images, setImages, remove}) {
  const sliderRef = useRef(null);
  const scrollAmount = 100;

  return (
    <div className="Slider">
      {/* <button
        className="nav-btn"
        type="button"
        onClick={e => {
          const container = sliderRef.current;
          container.scrollLeft -= scrollAmount;
          e.stopPropagation();
        }}>
        <ChevronLeftIcon />
      </button> */}
      <div className="images-container" ref={sliderRef}>
        {images.map((image, index) => {
          return (
            <>
              {typeof image === 'string' || image instanceof String ? (
                <div style={{position: 'relative'}}>
                  <img
                    className="image"
                    alt="cardImage"
                    src={constants.CDN + image}
                  />
                  <IconButton
                    style={{
                      position: 'absolute',
                      right: 5,
                      top: 5,
                      zIndex: 200,
                      color: 'black',
                      backgroundColor: '#EDEDED',
                      height: 20,
                      width: 20,
                      borderRadius: 6,
                      fontSize: 35,
                    }}
                    onClick={() => {
                      remove(image);
                    }}>
                    <Delete color="black" fontSize="medium" />
                  </IconButton>
                </div>
              ) : (
                <div style={{position: 'relative'}}>
                  <img
                    className="image"
                    alt="sliderImage"
                    key={index}
                    src={URL.createObjectURL(image)}
                    style={{
                      objectFit: 'cover',
                    }}
                  />
                  <IconButton
                    style={{
                      position: 'absolute',
                      right: 5,
                      top: 5,
                      zIndex: 200,
                      color: 'black',
                      backgroundColor: '#EDEDED',
                      height: 20,
                      width: 20,
                      borderRadius: 6,
                      fontSize: 35,
                    }}
                    onClick={() => {
                      remove(image);
                    }}>
                    <Delete color="black" fontSize="medium" />
                  </IconButton>
                </div>
              )}
            </>
          );
        })}

        <UploadContainer images={images} setImages={setImages} />
      </div>
      {/* <button
        className="nav-btn"
        type="button"
        onClick={e => {
          const container = sliderRef.current;
          container.scrollLeft += scrollAmount;
        }}>
        <ChevronRightIcon />
      </button> */}
    </div>
  );
}
