import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllShootersApi, getAllUsersApi, searchUsersApi } from '../../services/Users';

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  // @ts-ignore
  async ({ created = null, skip = 0, from= null, to = null, pro = false  }) => {
    const response = await getAllUsersApi(created, skip, from, to, pro);
    return response;
  },
);

export const fetchShooters = createAsyncThunk(
  'users/fetchShooters',
  // @ts-ignore
  async ({ created = null, skip = 0, from= null, to = null  }) => {
    const response = await getAllShootersApi(created, skip, from, to);
    return response;
  },
);

export const searchUsers = createAsyncThunk(
  'users/searchUsers',
  // @ts-ignore
  async ({ key, pro = null }) => {
    const response = await searchUsersApi(key, pro);
    return response;
  },
);

const initialState = {
  users: [],
  shooters: [],
  loading: true,
  hasMore: false,
  totalResults: 0,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUsers: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        state.loading = false;
        state.users = data;
      }).addCase(fetchShooters.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        state.shooters = data;
      })
      .addCase(fetchUsers.pending, state => {
        state.loading = true;
      })
      .addCase(fetchUsers.rejected, state => {
        state.loading = false;
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        state.loading = false;
        state.users = data;
      })
      .addCase(searchUsers.pending, state => {
        state.loading = true;
      })
      .addCase(searchUsers.rejected, state => {
        state.loading = false;
      });
  },
});

const { actions, reducer } = userSlice

// Reducer
export default reducer;

// Actions
export const { clearUsers } = actions;