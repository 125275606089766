// Navbar.jsx

import React from 'react';
import './navbar.scss';
import Logo from 'component/logo/logo';
import HomeSvg from 'assets/HomeSvg';
import AccountMenuComp from 'component/navbar/AccountMenuComp';
import {useNavigate} from 'react-router-dom';
import {NavConstant} from 'routes/NavConstant';

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="navbar">
      <div
        className="logo-container"
        onClick={() => {
          navigate(`${NavConstant.HOME}`);
        }}>
        <div className="logo">
          <Logo
            height={'10px'}
            width={'14px'}
            firstColor={'#00C0FB'}
            secondColor={'#FC3CB2'}
            fontSize={'30px'}
            fontColor="#505050"
          />
        </div>
        <div
          className="home-svg-container"
          onClick={() => {
            navigate(`${NavConstant.HOME}`);
          }}>
          <HomeSvg size="22px" />
        </div>
      </div>
      <div className="user-info-container">
        <AccountMenuComp username={'UserName'} />
      </div>
    </div>
  );
};

export default Navbar;
