import React from 'react';

function EditShooterAgreement() {
  return (
    <div>
      <input type="text" />
    </div>
  );
}

export default EditShooterAgreement;
