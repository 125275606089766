import {yupResolver} from '@hookform/resolvers/yup';
import {ArrowCircleLeftOutlined} from '@mui/icons-material';
import {Divider} from '@mui/material';
import {HeadLabel} from 'component/HeadLabel';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {RHFInputField} from 'component/forms';
import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {NavConstant} from 'routes/NavConstant';
import {NotifyUserApi, deleteUserApi} from 'services/ManageAdmin';
import {getUserApi, getUserStatsApi, updateUserApi} from 'services/Users';
import {object, string} from 'yup';

function UserProfile() {
  const {id} = useParams();

  const [user, setUser] = useState();
  const [userStats, setUserStats] = useState();
  const [restricted, setRestricted] = useState(user?.restricted);
  const [blocked, setBlocked] = useState(user?.blocked);
  const [loading, setLoading] = useState(false);

  const validationSchema = object({
    mobile: string().min(12).max(12),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: user,
  });

  const checkKeyDown = e => {
    console.log(e);
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const {
    handleSubmit,
    setValue,
    register,
    control,
    getValues,
    setError,
    reset,
  } = methods;

  const [badge, setBadge] = React.useState({});

  const badges = [
    {title: 'Riser', level: 1, uploads: 150, image: 'RISER.png'},
    {title: 'Slinger', level: 2, uploads: 300, image: 'SLINGER.png'},
    {title: 'Explorer', level: 3, uploads: 500, image: 'EXPLORER.png'},
    {title: 'Seeker', level: 4, uploads: 750, image: 'SEEKER.png'},
    {title: 'Hunter', level: 5, uploads: 1000, image: 'HUNTER.png'},
    {title: 'SharpShooter', level: 6, uploads: 1500, image: 'SHARPSHOOTER.png'},
    {title: 'Hitman', level: 7, uploads: 2500, image: 'HITMAN.png'},
    {title: 'Captain', level: 8, uploads: 3000, image: 'CAPTAIN.png'},
    {title: 'Sniper', level: 9, uploads: 5000, image: 'SNIPER.png'},
    {title: 'Commander', level: 10, uploads: 7500, image: 'COMMANDER.png'},
    {title: 'Legend', level: 14, uploads: 10000, image: 'LEGEND.png'},
    {title: 'Master', level: 15, uploads: 15000, image: 'MASTER.png'},
  ];

  useEffect(() => {
    getUserApi(id).then(res => {
      setUser(res?.data);
      setRestricted(res?.data?.restricted);
      reset(res?.data);
    });
    getUserStatsApi(id).then(res => {
      setUserStats(res?.data);
      switch (true) {
        case res?.data?.uploads <= 100:
          return setBadge(badges[0]);
        case res?.data?.uploads < 200:
          return setBadge(badges[1]);
        case res?.data?.uploads < 300:
          return setBadge(badges[2]);
        case res?.data?.uploads < 500:
          return setBadge(badges[3]);
        case res?.data?.uploads < 750:
          return setBadge(badges[4]);
        case res?.data?.uploads < 1000:
          return setBadge(badges[5]);
        case res?.data?.uploads < 1500:
          return setBadge(badges[6]);
        case res?.data?.uploads < 2000:
          return setBadge(badges[7]);
        case res?.data?.uploads < 2500:
          return setBadge(badges[8]);
        case res?.data?.uploads < 3000:
          return setBadge(badges[9]);
        case res?.data?.uploads < 4000:
          return setBadge(badges[10]);
        case res?.data?.uploads < 5000:
          return setBadge(badges[11]);
        case res?.data?.uploads < 7500:
          return setBadge(badges[12]);
        case res?.data?.uploads < 10000:
          return setBadge(badges[13]);
        case res?.data?.uploads < 15000:
          return setBadge(badges[14]);
        default:
          break;
      }
    });
  }, []);

  const onSubmit = async data => {
    setLoading(true);
    data.restricted = restricted;
    data.kyc = user?.kyc;
    data.pro = user?.pro;

    try {
      const res = await updateUserApi(data);
      toast.success('Updated Successfully');
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 15,
          alignItems: 'center',
          marginBottom: 20,
        }}>
        <ArrowCircleLeftOutlined
          onClick={() => navigate(-1)}
          style={{
            cursor: 'pointer',
          }}
        />
        <HeadLabel label={'User Profile'} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 20,
        }}>
        <p
          style={{
            fontSize: '20px',
            fontWeight: 525,
            lineHeight: '21px',
          }}>
          General
        </p>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={e => checkKeyDown(e)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <div
              style={{
                width: 670,
                gap: 10,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                UID
                <RHFInputField
                  name="id"
                  label="Id"
                  // placeholder="Please enter firstname here..."
                  width="525px"
                  //   value={user?.id}
                  disabled={true}
                  register={register}
                />
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                First Name
                <RHFInputField
                  name="firstname"
                  label="Firstname"
                  // placeholder="Please enter firstname here..."
                  width="525px"
                  //   value={user?.firstname}
                  register={register}
                />
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                Last Name
                <RHFInputField
                  name="lastname"
                  label="Lastname"
                  // placeholder="Please enter lastname here..."
                  width="525px"
                  register={register}
                  //   value={user?.lastname}
                />
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                Email ID
                <RHFInputField
                  name="email"
                  label="Email"
                  // placeholder="Please enter email here..."
                  width="525px"
                  register={register}
                  //   value={user?.email}
                />
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                Phone Number
                <RHFInputField
                  name="mobile"
                  label="Mobile"
                  // placeholder="Enter Mobile Number"
                  width="525px"
                  register={register}
                  //   value={user?.mobile}
                />
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                City
                <RHFInputField
                  name="location.city"
                  label="City"
                  // placeholder="Please enter city here..."
                  width="525px"
                  register={register}
                  //   value={user?.location?.city}
                />
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                State
                <RHFInputField
                  name="location.state"
                  label="State"
                  // placeholder="Please enter state here..."
                  width="525px"
                  register={register}
                />
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                Country
                <RHFInputField
                  name="location.country"
                  label="Country"
                  // placeholder="Please enter country here..."
                  width="525px"
                  register={register}
                />
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                Created at
                <p
                  style={{
                    fontSize: '14px',
                    width: '525px',
                    justifyContent: 'flex-start',
                  }}>
                  {new Date(user?.created)?.toLocaleDateString('en-GB')}
                </p>
              </div>
            </div>
            <div
              style={{
                flexDirection: 'column',
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img
                src={'https://shotwot-test.b-cdn.net/' + user?.profileimage}
                style={{
                  height: 200,
                  width: 200,
                  backgroundColor: '#EDEDED',
                  border: '1px solid #BFBFBF',
                  borderRadius: 4,
                  overflow: 'hidden',
                  objectFit: 'cover',
                }}
              />
              <p
                style={{
                  marginBottom: 20,
                  marginTop: 5,
                }}>
                Profile Photo
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    width: 70,
                  }}>
                  Restrict
                </div>
                <input
                  type="checkbox"
                  checked={restricted}
                  onClick={e => setRestricted(e.currentTarget.checked)}
                  style={{margin: 12}}
                />
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    width: 70,
                    fontSize: '14px',
                    justifyContent: 'center',
                    border: '1px solid #D1D5DB',
                    textAlign: 'center',
                    width: '100px',
                    borderRadius: 4,
                    cursor: 'pointer',
                  }}
                  onClick={async () => {
                    try {
                      console.log(user?.id);
                      const res = await deleteUserApi(user?.id);
                      if (res?.success) {
                        toast.success('User Deleted');
                        navigate(`${NavConstant.ALL_SIGNUPS}`);
                      } else {
                        toast.error(res?.error);
                      }
                    } catch (error) {
                      toast.error(error);
                    }
                  }}>
                  Delete
                </div>
                {/* <input
                  type="checkbox"
                  checked={blocked}
                  style={{margin: 12}}
                  onClick={e => setBlocked(e.currentTarget.checked)}
                /> */}
              </div>
            </div>
          </div>
          <Divider
            style={{
              color: 'rgba(0, 0, 0, 0.47)',
              marginTop: 20,
              marginBottom: 20,
            }}
          />
          <p
            style={{
              fontSize: '20px',
              fontWeight: 525,
              lineHeight: '21px',
              marginBottom: 10,
            }}>
            Shooter Dashboard
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 20,
            }}>
            <div
              style={{
                width: 650,
                gap: 10,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                Verification Status
                <div
                  style={{
                    width: '425px',
                  }}
                  onClick={async () => {
                    setUser({
                      ...user,
                      kyc: !user?.kyc,
                    });

                    try {
                      const res = await updateUserApi({
                        ...user,
                        kyc: !user?.kyc,
                      });
                      toast.success('Kyc Status updated!');
                      user?.kyc == false
                        ? await NotifyUserApi({
                            id: user?.id,
                            title: 'Approved',
                            message:
                              'Congratulations, Your KYC verification is approved. ',
                          })
                        : await NotifyUserApi({
                            id: user?.id,
                            title: 'Rejected',
                            message:
                              'Your KYC verification is rejected, Please try again. ',
                          });
                    } catch (error) {
                      toast.error(error);
                    } finally {
                    }
                  }}>
                  <p
                    style={{
                      fontSize: '14px',
                      justifyContent: 'center',
                      border: '1px solid #D1D5DB',
                      textAlign: 'center',
                      width: '100px',
                      borderRadius: 4,
                      cursor: 'pointer',
                    }}>
                    {user?.kyc ? 'Verified' : 'UnVerified'}
                  </p>
                </div>
              </div>

              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                Verification Method
                <div
                  style={{
                    width: '425px',
                  }}>
                  <p
                    style={{
                      fontSize: '14px',
                      justifyContent: 'center',
                      border: '1px solid #D1D5DB',
                      textAlign: 'center',
                      width: '130px',
                      borderRadius: 4,
                    }}>
                    {user?.userKyc?.id == '000000000000000000000000'
                      ? 'Other Method'
                      : 'Adhaar'}
                  </p>
                </div>
              </div>

              <div
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  // justifyContent: 'space-between',
                  // alignItems: 'center',
                }}>
                Shooter Portfolio
                <p
                  style={{
                    fontSize: '14px',
                    justifyContent: 'center',
                    marginTop: 8,
                  }}>
                  Showreel
                </p>
                {user?.showreel?.map(v => (
                  <a
                    style={{
                      width: '500px',
                      height: '32px',
                      padding: '8px 12px',
                      boxSizing: 'border-box',
                      marginBottom: 5,
                      marginTop: 5,
                      border: '0px #B0B8C4',
                      borderRadius: '6px',
                      backgroundColor: 'white',
                      fontSize: '14px',
                      resize: 'none',
                      overflow: 'hidden',
                    }}
                    href={v}>
                    {v}
                  </a>
                ))}
                <p
                  style={{
                    fontSize: '14px',
                    justifyContent: 'center',
                    marginTop: 8,
                  }}>
                  Instagram
                </p>
                <a
                  style={{
                    width: '500px',
                    height: '32px',
                    padding: '8px 12px',
                    boxSizing: 'border-box',
                    marginBottom: 5,
                    marginTop: 5,
                    border: '0px #B0B8C4',
                    borderRadius: '6px',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    resize: 'none',
                    overflow: 'hidden',
                  }}
                  href={'https://www.instagram.com/' + user?.instagram}>
                  {user?.instagram}
                </a>
                <p
                  style={{
                    fontSize: '14px',
                    justifyContent: 'center',
                    marginTop: 8,
                  }}>
                  Facebook
                </p>
                <a
                  style={{
                    width: '500px',
                    height: '32px',
                    padding: '8px 12px',
                    boxSizing: 'border-box',
                    marginBottom: 5,
                    marginTop: 5,
                    border: '0px #B0B8C4',
                    borderRadius: '6px',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    resize: 'none',
                    overflow: 'hidden',
                  }}
                  href={'https://www.facebook.com/' + user?.facebook}>
                  {user?.facebook}
                </a>
                <p
                  style={{
                    fontSize: '14px',
                    justifyContent: 'center',
                    marginTop: 8,
                  }}>
                  Linkedin
                </p>
                <a
                  style={{
                    width: '500px',
                    height: '32px',
                    padding: '8px 12px',
                    boxSizing: 'border-box',
                    marginBottom: 5,
                    marginTop: 5,
                    border: '0px #B0B8C4',
                    borderRadius: '6px',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    resize: 'none',
                    overflow: 'hidden',
                  }}
                  href={'https://www.linkedin.com/in/' + user?.linkedin}>
                  {user?.linkedin}
                </a>
              </div>
            </div>
            <div
              style={{
                gap: 10,
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  //   justifyContent: 'space-between',
                  //   alignItems: 'center',
                }}>
                <div
                  style={{
                    width: 200,
                    display: 'flex',
                  }}>
                  Pro-Shooter Status
                </div>
                <div
                  onClick={async () => {
                    try {
                      const res = await updateUserApi({
                        ...user,
                        pro: !user?.pro,
                      });
                      toast.success('Pro Shooter Status updated!');
                      setUser({
                        ...user,
                        pro: !user?.pro,
                      });
                    } catch (error) {
                      toast.error(error);
                    } finally {
                    }
                  }}
                  style={{
                    background:
                      'linear-gradient(99.06deg, rgba(255, 147, 60, 0.95) -67.61%, rgba(255, 255, 255, 0.95) 137.61%)',
                    opacity: '95%',
                    borderRadius: 6,
                    padding: '0px 5px',
                    gap: 6,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  {user?.pro ? 'Subscribed' : 'UnSubscribed'}
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.25 4.25H11.75"
                      stroke="#16082B"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                    />
                    <path
                      d="M15.29 3.98754C15.4142 4.14281 15.4202 4.3617 15.3046 4.52351L8.36166 14.2437C8.18441 14.4918 7.81559 14.4918 7.63834 14.2437L0.695364 4.52351C0.579786 4.3617 0.585751 4.14281 0.70997 3.98754L3.36656 0.666803C3.4509 0.561374 3.5786 0.5 3.71361 0.5H12.2864C12.4214 0.5 12.5491 0.561373 12.6334 0.666802L15.29 3.98754Z"
                      stroke="#16082B"
                      stroke-miterlimit="10"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}>
                <div
                  style={{
                    width: 200,
                    display: 'flex',
                  }}>
                  Pro-Shooter Badge
                </div>
                <div
                  style={{
                    background:
                      'linear-gradient(99.06deg, rgba(255, 147, 60, 0.95) -67.61%, rgba(255, 255, 255, 0.95) 137.61%)',
                    opacity: '95%',
                    borderRadius: 6,
                    padding: '0px 5px',
                    gap: 6,
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  {badge?.title}
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}>
                <div
                  style={{
                    width: 200,
                    display: 'flex',
                  }}>
                  Brief Application
                </div>
                {userStats?.total}
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}>
                <div
                  style={{
                    width: 200,
                    display: 'flex',
                  }}>
                  Ongoing Briefs
                </div>
                {userStats?.ongoing}
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}>
                <div
                  style={{
                    width: 200,
                    display: 'flex',
                  }}>
                  Content Accepted
                </div>
                {userStats?.accepted}
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}>
                <div
                  style={{
                    width: 200,
                    display: 'flex',
                  }}>
                  Content Rejected
                </div>
                {userStats?.rejected}
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}>
                <div
                  style={{
                    width: 200,
                    display: 'flex',
                  }}>
                  Total Assets Uploaded
                </div>
                {userStats?.uploads}
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}>
                <div
                  style={{
                    width: 200,
                    display: 'flex',
                  }}>
                  Rewards Earned
                </div>
                {userStats?.earned}
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}>
                <div
                  style={{
                    width: 200,
                    display: 'flex',
                  }}>
                  Wallet Balance
                </div>
                {userStats?.balance}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '30px',
              gap: 10,
            }}>
            {/* <button
              // isLoading={loading}
              // @ts-ignore
              //   type="button"
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              style={{
                backgroundColor: '#8A8395',
                // borderColor: '#8A8395',
                borderRadius: 4,
                borderWidth: 0,
                width: 163,
                height: 40,
                color: 'white',
              }}
              id="new">
              Save Changes
            </button> */}
            <SecondaryButton
              isLoading={loading}
              buttonLabel={'Save Changes'}
              labelColor="white"
              width="150px"
              // @ts-ignore
              type="submit"
              color={'#8A8395'}
            />
            <button
              // isLoading={loading}
              // @ts-ignore
              type="button"
              onClick={() => {
                window.location.reload();
              }}
              style={{
                backgroundColor: '#7729E5',
                // borderColor: '#8A8395',
                borderRadius: 4,
                borderWidth: 0,
                width: 80,
                height: 40,
                color: 'white',
              }}
              id="new">
              Cancel
            </button>
          </div>
        </form>
      </FormProvider>
    </DashboardLayout>
  );
}

export default UserProfile;
