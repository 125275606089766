import React from 'react';
import './buttons.scss';

export default function CustomButton({
  bgColor = '#D9D9D9',
  width="110px",
  height="40px",
  labelColor="#014155",
  buttonLabel = 'Click',
  onClick,
  isLoading = false,
}) {
  return (
    <button
      onClick={onClick}
      className="submit-btn"
      style={{
        height: height,
        width: width,
        backgroundColor: bgColor,

        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',

        color: labelColor ,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',

        // font styles
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '16px',
        textTransform: 'capitalize',
        letterSpacing:"1px"
      }}>
      {isLoading ? <div className="loader" /> : buttonLabel}
    </button>
  );
}
