import {AgGridReact} from 'ag-grid-react';
import {dateComparator} from 'helper/comparator';
import React, {useEffect, useRef, useState} from 'react';
import {getAllSubmissionsApi, getPaidShootersApi} from 'services/Brief';

function PaidShooters() {
  const [colDefs, setColDefs] = useState([
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
    },
    // {
    //   field: 'Date',
    //   width: 100,
    //   valueGetter: p => {
    //     const date = new Date(p?.data?.transaction?.[0]?.created);
    //     return date?.toLocaleDateString('en-GB');
    //   },
    //   comparator: dateComparator,
    //   // cellRenderer: LinkComponentType,
    // },
    // {
    //   field: 'Time',
    //   width: 100,
    //   valueGetter: p => {
    //     const time = new Date(p?.data?.transaction?.[0]?.created);
    //     return time?.toLocaleTimeString();
    //   },
    //   // cellRenderer: LinkComponentType,
    // },
    // {
    //   field: 'type',
    //   width: 100,
    //   valueGetter: p => p?.data?.brief?.type,
    //   // cellRenderer: LinkComponentType,
    // },
    // {
    //   field: 'Brief Title',
    //   width: 120,
    //   valueGetter: p => p?.data?.brief?.title,
    //   // cellRenderer: LinkComponentType,
    // },
    {
      field: 'Shooter Name',
      width: 120,
      valueGetter: p =>
        p?.data?.user?.firstname + ' ' + p?.data?.user?.lastname,
      // cellRenderer: LinkComponentType,
    },
    {
      field: 'User (Email Or Mobile)',
      width: 180,
      valueGetter: p => p?.data?.user?.email || p?.data?.user?.mobile,
      // cellRenderer: LinkComponentType,
    },
    {
      field: 'Payout Amount',
      width: 120,
      valueGetter: p => p?.data?.paid,
    },
    {
      field: 'Wallet Balance',
      width: 120,
      valueGetter: p => p?.data?.total?.coins,
    },
  ]);

  const gridRef = useRef();

  const [data, setData] = useState([]);

  useEffect(() => {
    getPaidShootersApi().then(res => {
      setData(res?.data);
    });
  }, []);

  return (
    <div className={'ag-theme-quartz'} style={{width: '100%', height: '500px'}}>
      <AgGridReact
        ref={gridRef}
        defaultColDef={{resizable: true, sortable: true, filter: true}}
        readOnlyEdit={true}
        getRowId={params => params.data.id}
        // onCellEditRequest={onCellEditRequest}
        // onRowClicked={p => {
        //   window.open(
        //     `${window.location.origin}/brief-submission/${p?.data?.id}`,
        //   );
        // }}
        onRowClicked={p => {
          window.open(`${window.location.origin}/users/${p?.data?.userId}`);
        }}
        rowData={data || []}
        columnDefs={colDefs}
        pagination={true}
        paginationPageSize={100}
        rowSelection="multiple"
        scrollbarWidth={9}
        alwaysShowHorizontalScroll={true}
        alwaysShowVerticalScroll={true}
      />
    </div>
  );
}

export default PaidShooters;
