import DustbinSvg from 'assets/DustbinSvg';
import PencilSvg from 'assets/PencilSvg';
import HeadingComp from 'component/HeadingComp';
import {CreateAdminForm} from 'component/forms';
import CreatePlaylistForm from 'component/forms/comman/CreatePlaylistForm';
import React, {useRef, useState} from 'react';

export default function CreatePlaylist({playlist = {}}) {
  return (
    <div
      style={{
        display: 'flex',
        borderRadius: '20px',
        marginTop: 20,
        minHeight: '26%',
        padding: '20px 20px',
        backgroundColor: '#C6C6C654',
        boxShadow: '1px 2px 3px #00000040',
        width: '1176px',
      }}>
      <CreatePlaylistForm onDone={() => {}} playlist={playlist} />
    </div>
  );
}
