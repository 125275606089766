import {getAllBriefsApi, getDraftBriefsApi} from 'services/Brief';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getEventApi, getPlaylistApi} from 'services/Playlist';

export const fetchPlaylists = createAsyncThunk(
  'users/fetchPlaylists',
  // @ts-ignore
  async () => {
    const response = await getPlaylistApi();
    return response;
  },
);

export const fetchEvents = createAsyncThunk(
  'users/events',
  // @ts-ignore
  async () => {
    const response = await getEventApi();
    return response;
  },
);

const initialState = {
  playlists: [],
  // events: [],
  loading: true,
  hasMore: false,
  totalResults: 0,
};

const briefSlice = createSlice({
  name: 'playlists',
  initialState,
  reducers: {
    clearPlaylist: () => {
      return initialState;
    },
    deletePlaylist: (state, action) => {
      console.log(action.payload);
      if (state.playlists?.length) {
        const filteredBriefs = state.playlists?.filter(
          brief => brief.id !== action.payload.id,
        );

        state.totalResults -= 1;
        state.playlists = filteredBriefs;
      }
    },
    updatePlaylist: (state, action) => {
      const updatedBrief = action.payload;
      if (state.playlists) {
        const updatedBriefs = state.playlists?.map(brief => {
          if (brief.id === updatedBrief.id) {
            return updatedBrief;
          }
          return brief;
        });

        state.playlists = updatedBriefs;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlaylists.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        state.loading = false;
        state.playlists = data;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        state.loading = false;
        state.playlists = data;
      })
      .addCase(fetchPlaylists.pending, state => {
        state.loading = true;
      })
      .addCase(fetchPlaylists.rejected, state => {
        state.loading = false;
      });
  },
});

const {actions, reducer} = briefSlice;

// Reducer
export default reducer;

export const {clearPlaylist, updatePlaylist, deletePlaylist} = actions;
