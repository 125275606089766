function getFullName(params) {
  console.log(params?.value);
  return ``;
}

const asColumn = [
  // "profileimage": "",
  // "bio": "",
  {
    headerName: 'S.N',
    width: 60,
    valueGetter: 'node.rowIndex + 1',
  },
  {field: 'id', headerName: 'UID', width: 200},
  {field: 'firstname', headerName: 'First name', width: 150},
  {field: 'lastname', headerName: 'Last name', width: 150},
  {field: 'email', headerName: 'Email', width: 200},
  {field: 'mobile', headerName: 'Mobile no', width: 150},
  {
    field: 'city',
    headerName: 'City',
    width: 150,
    valueGetter: ({row}) => row?.location?.city,
  },
  {
    field: 'state',
    headerName: 'State',
    width: 150,
    valueGetter: ({row}) => row?.location?.state,
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 150,
    valueGetter: ({row}) => row?.location?.country,
  },
  {field: 'active', headerName: 'Active', width: 120, type: 'boolean'},
  {field: 'created', headerName: 'Created', width: 180},
];

export {asColumn};
