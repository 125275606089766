import React, {useEffect} from 'react';
import DashboardLayout from 'component/templates/DashboardLayout';
import {getSignInAdminDetails} from 'services/Login';
import {CreateBriefForm} from 'component/forms';
import {useLocation} from 'react-router-dom';

export const CreateBrief = () => {
  const props = useLocation();
  return (
    <DashboardLayout>
      <CreateBriefForm onDone={() => {}} brief={props.state?.data} />
    </DashboardLayout>
  );
};
