import React, {useRef, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {object, string} from 'yup';
import RHFInputField from '../RHFInputField';
import RHFPicker from '../RHFPicker';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSelector} from 'react-redux';
import {createAdminUserApi} from 'services/ManageAdmin';
import {fetchAdmins} from 'store/slice/adminSlice';
import RHFPasswordField from '../RHPasswordField';
import {IconButton, LinearProgress} from '@mui/material';
import DustbinSvg from 'assets/DustbinSvg';
import {AddCircleOutlineRounded} from '@mui/icons-material';
import {
  createCheatApi,
  createEventApi,
  createPlaylistApi,
  updateCheatApi,
} from 'services/Playlist';
import {toast} from 'react-toastify';
import {SingleImageUpload} from 'component/images/SingleImageUpload';
import axios from 'axios';
import {constants} from 'helper/constant';
import RHFDateTime from '../RHFDateTime';
import Upload from 'assets/upload';

const validationSchema = object({});

function CreateCheatSheetForm({onDone, playlist = {}}) {
  const [loading, setLoading] = React.useState(false);
  const [inputs, setInputs] = useState(['']); // Initial state with one input

  const [file, setFile] = useState(
    playlist?.thumbnail ? constants.CDN + playlist?.thumbnail : null,
  );

  const [imageFile, setImageFile] = useState(
    playlist?.image ? constants.CDN + playlist?.image : null,
  );

  const [progress, setProgress] = useState(0);

  // @ts-ignore
  const {adminRoles} = useSelector(state => state?.adminRoles);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: playlist,
  });

  const {handleSubmit, register, reset} = methods;
  const type = [
    {id: 1, label: 'Audio', value: 'Audio'},
    {id: 2, label: 'Video', value: 'Video'},
    {id: 2, label: 'Images', value: 'Images'},
  ];

  const FormComp = ({label, inputComp}) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          //   alignItems: "center",
          width: '906px',
        }}>
        <div
          style={{
            width: '192px',
            // marginTop: 20,
          }}>
          {label}
        </div>
        {inputComp}
      </div>
    );
  };

  const api = async (f, url) => {
    await axios({
      url: url,
      method: 'put',
      data: f,
      headers: {
        'Content-Type': f?.type,
        'Content-Encoding': 'base64',
      },
      onUploadProgress: function (progressEvent) {
        setProgress(prev => prev + progressEvent?.bytes);
      },
      validateStatus: function (status) {
        toast.success('file uploaded');
        return status <= 300; // Resolve only if the status code is less than 500
      },
    });
  };

  const inputFiles = useRef(null);

  const handleClick = event => {
    inputFiles.current.click();
  };

  const onChangeFile = async event => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (file) setFile(file);
  };

  const onSubmit = async data => {
    console.log('CreateAdminForm data ', data);
    setLoading(true);
    try {
      let res;
      if (Object.keys(playlist)?.length == 0) {
        res = await createCheatApi({
          ...data,
          file: {name: file?.name, type: file?.type},
        });
      } else {
        res = await updateCheatApi({
          ...data,
          file: {name: file?.name, type: file?.type},
        });
      }
      if (res?.success) {
        playlist == {}
          ? toast.success('created cheatsheet!')
          : toast.success('updated cheatsheet!');
        const res2 = await api(file, res?.data?.url);
      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      toast.error(error);

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <div style={{width: '100%'}}>
        {progress !== 0 ? (
          <LinearProgress
            variant="determinate"
            value={parseInt((progress * 100) / file?.size + '')}
            sx={{
              backgroundColor: '#D9D9D9',
              span: {
                background: 'linear-gradient(to right, #FC3CB2, #00C0FB)',
              },
              height: '10px',
              'border-radius': '25px',
              marginTop: 5,
              marginBottom: 5,
            }}
          />
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{flexDirection: 'row', display: 'flex', gap: 73}}>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                width: 906 - 73,
                gap: 12,
              }}>
              <FormComp
                label="Cheat Sheet Name"
                inputComp={
                  <RHFInputField
                    name="title"
                    // @ts-ignore
                    register={register}
                    label="title"
                    placeholder="Please enter title here..."
                    width="641px"
                  />
                }
              />
              <FormComp
                label="Type"
                inputComp={
                  <RHFPicker
                    name="type"
                    label="Select One"
                    options={type}
                    width="180px"
                  />
                }
              />

              <FormComp
                label="Add Cheat Sheet"
                inputComp={
                  <>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                        // padding: '15px 12px',
                        backgroundColor: 'white',
                        width: '388px',
                        padding: '6px 12px',
                        borderRadius: 6,
                        cursor: 'pointer',
                        overflow: 'hidden',
                        gap: 19,
                        height: '28px',
                      }}
                      onClick={handleClick}>
                      {file?.name || 'Upload a Pdf'}
                      <Upload size={20} />
                    </div>
                    <input
                      type="file"
                      style={{display: 'none'}}
                      id="upload"
                      ref={inputFiles}
                      onChange={onChangeFile}
                      disabled={false}
                    />
                  </>
                }
              />
            </div>
            {/* <div
              style={{
                borderLeft: '1px solid ',
                borderColor: '#00000050',
                padding: '0px 73px',
                display: 'flex',
                flexDirection: 'column',
                gap: 6,
              }}>
              <div
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}>
                <SingleImageUpload image={file} setImage={setFile} />
                Add Thumbnail
              </div>
            </div> */}
          </div>
        </form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '30px',
            marginRight: 24,
            flexDirection: 'row',
            gap: 5,
          }}>
          <SecondaryButton
            onClick={() => {
              reset(playlist);
              setFile(
                playlist?.thumbnail
                  ? constants.CDN + playlist?.thumbnail
                  : null,
              );
            }}
            buttonLabel={'Discard'}
            color="#BFBFBF"
            labelColor="white"
            type="button"
            width="86px"
          />

          <SecondaryButton
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
            buttonLabel={Object.keys(playlist)?.length > 0 ? 'Edit' : 'Create'}
            color="#7729E5"
            labelColor="white"
            type="button"
            width="86px"
          />
        </div>
      </div>
    </FormProvider>
  );
}

export default CreateCheatSheetForm;
