import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {object, string, number, bool} from 'yup';
import RHFInputField from '../RHFInputField';
import RHFPicker from '../RHFPicker';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSelector} from 'react-redux';
import {createAdminUserApi, updateAdminUserApi} from 'services/ManageAdmin';
import {fetchAdmins} from 'store/slice/adminSlice';
import RHFPasswordField from '../RHPasswordField';
import {toast} from 'react-toastify';
import HeadingComp from 'component/HeadingComp';

const validationSchema = object({
  email: string().email('Invalid email address').required('Email is required'),
  role: number().required('Admin Role is required'),
  id: string(),
  active: bool(),
  firstname: string(),
  lastname: string(),
});

function UpdateAdminForm({onDone, initial}) {
  const [loading, setLoading] = React.useState(false);
  const [notify, setNotify] = React.useState(false);

  // @ts-ignore
  const {adminRoles} = useSelector(state => state?.adminRoles);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initial,
  });

  const {handleSubmit, register, reset} = methods;

  const onSubmit = async data => {
    console.log('CreateAdminForm data ', data);
    setLoading(true);
    try {
      await updateAdminUserApi(data);
      setNotify(true);
    } catch (error) {
      toast.error(error);
      console.log(error);
    } finally {
      toast.success('User updated successfully');
      setLoading(false);
      onDone();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        borderRadius: '6px',
        minHeight: '26%',
        padding: '20px 20px',
        backgroundColor: '#C6C6C654',
        boxShadow: '1px 2px 3px #00000040',
        marginTop: 20,
        width: 916,
      }}>
      <div
        style={{
          width: 560,
          justifyContent: 'center',
          //    backgroundColor:"red"
        }}>
        <div
          style={{
            width: 560,
            justifyContent: 'center',
            // backgroundColor: "red"
          }}>
          <HeadingComp label={'Modify/Edit Details'} fontSize="20px" />

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                width: '522px',
                display: 'flex',
                gap: 10,
                flexDirection: 'column',
                marginTop: '20px',
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <RHFInputField
                  name="firstname"
                  // @ts-ignore
                  register={register}
                  label="First name"
                  placeholder="First name"
                  width="254px"
                />

                <RHFInputField
                  name="lastname"
                  // @ts-ignore
                  register={register}
                  label="Last name"
                  placeholder="Last name"
                  width="254px"
                />
              </div>
              <RHFInputField
                name="email"
                // @ts-ignore
                register={register}
                label="Email"
                width="522px"
                placeholder="Email"
                disabled={true}
              />

              <RHFPicker
                name="role"
                label="Admin Role"
                options={adminRoles}
                width="522px"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '30px',
                  flexDirection: 'row',
                  gap: 12,
                }}>
                <SecondaryButton
                  buttonLabel={'Discard'}
                  onClick={e => {
                    reset(initial);
                    e?.preventDefault();
                  }}
                  // @ts-ignore
                  type="button"
                  color="#555555"
                  labelColor="white"
                />
                <SecondaryButton
                  isLoading={loading}
                  buttonLabel={'Update'}
                  color="#7729E5"
                  labelColor="white"
                  // @ts-ignore
                  type="submit"
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <div
        style={{
          borderLeft: '1px solid',
          borderColor: '#00000050',
          padding: '10px 20px',
          //    backgroundColor:"green",
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}>
        {notify ? 'Detail updated Succesfully' : null}
      </div>
    </div>
  );
}

export default UpdateAdminForm;
