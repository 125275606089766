import {AgGridReact} from 'ag-grid-react';
import {CircleTick} from 'component/CircleTick';
import {Edit} from 'component/Edit';
import React, {useEffect, useRef, useState} from 'react';
import {getPlaylistApi, updatePlaylistApi} from 'services/Playlist';
import {fetchPlaylists, updatePlaylist} from 'store/slice/masterclassSlice';
import {dispatch, useSelector} from 'store/store';

const AllPlaylist = ({setCurrent}) => {
  const [playlist, setPlaylist] = useState([]);

  const {playlists} = useSelector(state => state?.masterclass);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const gridRef = useRef();

  const [colDefs, setColDefs] = useState([
    {
      headerName: '',
      width: 60,
      valueGetter: '',
      rowDrag: true,

      // renderCell: params =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      headerName: 'S.N',
      width: 60,
      valueGetter: 'node.rowIndex + 1',
      // renderCell: params =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      field: 'title',
      headerClass: 'super-app-theme--header',
      headerName: 'Class Title',
      width: 200,
    },
    {
      field: 'title',
      headerClass: 'super-app-theme--header',
      headerName: 'Total Videos',
      width: 80,
      valueGetter: row => row?.data?.videos?.length,
    },
    {
      field: 'isActive',
      headerName: 'Status',
      width: 120,
      // valueGetter: row => row?.data?.isActive,
      cellRenderer: CircleTick,
      editable: true,
    },

    {
      field: 'title',
      headerClass: 'super-app-theme--header',
      headerName: 'Edit',
      width: 80,
      cellRenderer: Edit,
      onCellClicked: r => {
        setCurrent(r?.data);
      },
    },
  ]);

  useEffect(() => {
    dispatch(fetchPlaylists());
  }, []);

  async function onCellEditRequest(event) {
    const oldData = event.data;
    const field = event.colDef.field;
    const newData = {...oldData};
    newData[field] = event.newValue;
    try {
      const res = await updatePlaylistApi({
        ...newData,
        file: {
          name: '',
          type: '',
        },
      });

      dispatch(updatePlaylist(newData));
    } catch (error) {
      console.log(error);
    }
  }

  const onCellValueChanged = params => {
    const updatedRowData = playlist?.map(row => {
      console.log(row?.id, params?.node?.data?.id);
      if (row?.id === params?.node?.data?.id) {
        row[params?.colDef?.field] = params?.newValue;
      }
      return row;
    });

    setPlaylist(updatedRowData);
  };

  return (
    <div
      style={{
        // display: 'flex',
        // borderRadius: '20px',
        marginTop: 20,
        // minHeight: '26%',
        // padding: '20px 20px',
        // backgroundColor: '#C6C6C654',
        // boxShadow: '1px 2px 3px #00000040',
        // width: '1176px',
      }}>
      <div
        className={'ag-theme-quartz'}
        style={{width: '100%', height: '50vh'}}>
        <AgGridReact
          ref={gridRef}
          defaultColDef={{...defaultColDef}}
          readOnlyEdit={true}
          getRowId={params => params.data.id}
          // onCellValueChanged={onCellValueChanged}
          onCellEditRequest={onCellEditRequest}
          gridOptions={{
            rowDragManaged: true,
          }}
          rowData={playlists || []}
          columnDefs={colDefs}
          //   pagination={true}
          //   paginationPageSize={100}
          rowSelection="multiple"
          scrollbarWidth={9}
          alwaysShowHorizontalScroll={true}
          alwaysShowVerticalScroll={true}
          onRowDragEnd={event => {
            // const {rowNode} = event;
            const newRowData = [...playlist];
            const draggedRowIndex = playlist.findIndex(
              row => row.id === event?.node.id,
            );
            const targetRowIndex = event?.overIndex;

            newRowData.splice(draggedRowIndex, 1);
            newRowData.splice(targetRowIndex, 0, event?.node?.data);

            setPlaylist(newRowData);
            // console.log(newRowData);
          }}
        />
      </div>
    </div>
  );
};

export default AllPlaylist;
