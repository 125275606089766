import {yupResolver} from '@hookform/resolvers/yup';
import {ArrowCircleLeftOutlined} from '@mui/icons-material';
import {LinearProgress} from '@mui/material';
import axios from 'axios';
import {HeadLabel} from 'component/HeadLabel';
import SecondaryButton from 'component/buttons/SecondaryButton';
import {RHFInputField} from 'component/forms';
import DashboardLayout from 'component/templates/DashboardLayout';
import React, {useEffect, useRef, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {getAttahcement, postAttahcement} from 'services/Attachement';
import * as yup from 'yup';

function Attachement() {
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({});

  const [data, setData] = useState({});

  const [fileG, setfileG] = useState(null);
  const [fileF, setfileF] = useState(null);
  const [fileR, setfileR] = useState(null);

  const inputFilesG = useRef(null);

  const handleClickG = () => {
    inputFilesG.current.click();
  };

  const inputFilesF = useRef(null);

  const handleClickF = () => {
    inputFilesF.current.click();
  };
  const inputFilesR = useRef(null);

  const handleClickR = () => {
    inputFilesR.current.click();
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    setValue,
    register,
    control,
    getValues,
    setError,
    reset,
  } = methods;

  const [progress, setProgress] = useState(0);

  const api = async (file, url) => {
    console.log('====================================');
    console.log(file);
    console.log('====================================');
    await axios({
      url: url,
      method: 'put',
      data: file,
      headers: {
        'Content-Type': file.type,
        'Content-Encoding': 'base64',
      },
      onUploadProgress: function (progressEvent) {
        setProgress(prev => prev + progressEvent?.bytes);
      },
      validateStatus: function (status) {
        toast.success('file uploaded');
        return status <= 300; // Resolve only if the status code is less than 500
      },
    });
  };

  const onSubmit = async => {
    let f;
    if (fileF) {
      f = {
        name: fileF?.name,
        type: fileF?.type,
      };
    }

    let r;
    if (fileR) {
      r = {
        name: fileR?.name,
        type: fileR?.type,
      };
    }

    let g;
    if (fileG) {
      g = {
        name: fileG?.name,
        type: fileG?.type,
      };
    }
    postAttahcement({...data, fileF: f, fileG: g, fileR: r})
      .then(async r => {
        console.log(r);

        if (fileF != null) {
          await api(fileF, r?.data?.urlF);
        }

        if (fileG != null) {
          await api(fileG, r?.data?.urlG);
        }

        if (fileR != null) {
          await api(fileR, r?.data?.urlR);
        }

        toast.success('Url Updated Succesfully!');
      })
      .catch(() => {
        toast.error('Url update failed, try again');
      });
  };

  useEffect(() => {
    getAttahcement().then(res => {
      setData(res?.data);
      reset(res?.data);
    });
  }, []);

  return (
    <DashboardLayout>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 15,
          alignItems: 'center',
          paddingBottom: 18,
        }}>
        <ArrowCircleLeftOutlined
          onClick={() => {
            navigate(-1);
          }}
          style={{
            cursor: 'pointer',
          }}
        />
        <HeadLabel label={'Manage Attachments'} />
      </div>
      <div
        style={{
          gap: 24,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              gap: 24,
              display: 'flex',
              flexDirection: 'column',
            }}>
            {progress !== 0 ? (
              <LinearProgress
                variant="determinate"
                value={parseInt(
                  (progress * 100) / (fileG?.size + fileR?.size + fileF?.size),
                )}
                sx={{
                  backgroundColor: '#D9D9D9',
                  span: {
                    background: 'linear-gradient(to right, #FC3CB2, #00C0FB)',
                  },
                  height: '10px',
                  'border-radius': '25px',
                  marginTop: 5,
                  marginBottom: 5,
                }}
              />
            ) : null}
            <div
              style={{
                display: 'flex',
                padding: 10,
                backgroundColor: 'rgba(200, 200, 200, 0.33)',
                borderRadius: 6,
                flexDirection: 'row',
                alignItems: 'center',
                width: '837px',
              }}>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  width: 230,
                }}>
                Shooter Guidebook
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  display: 'flex',
                  flex: 1,
                }}>
                <input
                  style={{
                    width: 320,
                    height: 31,
                    padding: '8px 12px',
                    boxSizing: 'border-box',
                    border: '0px #B0B8C4',
                    borderRadius: '6px',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    resize: 'none',
                    cursor: 'pointer',
                  }}
                  value={fileG?.name || data?.guidebook || 'Upload file'}
                  onClick={handleClickG}
                />
                <input
                  type="file"
                  style={{display: 'none'}}
                  id="uploadG"
                  ref={inputFilesG}
                  multiple={false}
                  onChange={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    var file = event.target.files?.[0];
                    setfileG(file);
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 12,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {
                      setfileG(null);
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                padding: 10,
                backgroundColor: 'rgba(200, 200, 200, 0.33)',
                borderRadius: 6,
                flexDirection: 'row',
                width: '837px',
                alignItems: 'center',
              }}>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  width: 230,
                }}>
                Release Form
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  display: 'flex',
                  flex: 1,
                }}>
                <input
                  style={{
                    width: 320,
                    height: 31,
                    padding: '8px 12px',
                    boxSizing: 'border-box',
                    border: '0px #B0B8C4',
                    borderRadius: '6px',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    resize: 'none',
                    cursor: 'pointer',
                  }}
                  value={fileF?.name || data?.form || 'Upload file'}
                  onClick={handleClickF}
                />
                <input
                  type="file"
                  style={{display: 'none'}}
                  id="uploadF"
                  ref={inputFilesF}
                  multiple={false}
                  onChange={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    var file = event.target.files?.[0];
                    setfileF(file);
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 12,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {
                      setfileF(null);
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                padding: 10,
                backgroundColor: 'rgba(200, 200, 200, 0.33)',
                borderRadius: 6,
                flexDirection: 'row',
                width: '837px',
                alignItems: 'center',
              }}>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  width: 230,
                }}>
                Hero Reel
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  display: 'flex',
                  flex: 1,
                }}>
                <input
                  style={{
                    width: 320,
                    height: 31,
                    padding: '8px 12px',
                    boxSizing: 'border-box',
                    border: '0px #B0B8C4',
                    borderRadius: '6px',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    resize: 'none',
                    cursor: 'pointer',
                  }}
                  value={fileR?.name || data?.reel || 'Upload file'}
                  onClick={handleClickR}
                />
                <input
                  type="file"
                  style={{display: 'none'}}
                  id="uploadR"
                  ref={inputFilesR}
                  multiple={false}
                  onChange={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    var file = event.target.files?.[0];
                    setfileR(file);
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 12,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <SecondaryButton
                    buttonLabel={'Discard'}
                    // @ts-ignore
                    type="button"
                    color={'#736B80'}
                    labelColor="white"
                    width="74px"
                    onClick={() => {
                      setfileR(null);
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: 858,
              }}>
              <SecondaryButton
                buttonLabel={'Save Changes'}
                // @ts-ignore
                type="submit"
                color={'#7729E5'}
                labelColor="white"
                width="130px"
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </DashboardLayout>
  );
}

export default Attachement;
