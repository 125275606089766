import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    adminRoles: [
        {
            id: 1
            , label: 'Super Admin'
            , value: 1
           
        }
        ,
        { 
            id: 2
            , label: 'Admin'
            , value: 2
           
        },
        { 
            id: 3
            , label: 'Brief Manager'
            , value: 3
            
        },
        { 
            id: 4
            , label: 'Asset Manager'
            , value: 4
           
        },
    ],

};

const adminRoleSlice = createSlice({
    name: 'adminRoles',
    initialState,
    reducers: {

        clearAdmins: () => {
            return initialState;
        },
    },

});

const { reducer } = adminRoleSlice

// Reducer
export default reducer;

