import * as React from 'react';
import PropTypes from 'prop-types';
import {Box, styled} from '@mui/system';
import {Button} from '@mui/base/Button';
import {Input as BaseInput, inputClasses} from '@mui/base/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Input = React.forwardRef(function CustomInput(props, ref) {
  const {slots, ...other} = props;
  return (
    <BaseInput
      slots={{
        root: InputRoot,
        input: InputElement,
        ...slots,
      }}
      {...other}
      ref={ref}
    />
  );
});

Input.propTypes = {
  /**
   * The components used for each slot inside the InputBase.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    input: PropTypes.elementType,
    root: PropTypes.elementType,
    textarea: PropTypes.elementType,
  }),
};

export default function PasswordInput({
  password,
  setPassword,
  errorMessage,
  id,
  style,
}) {
  const [showpassword, setshowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setshowPassword(!showpassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <>
      {errorMessage && (
        <p style={{color: '#FC3CB2', marginLeft: 4, fontSize: '12px'}}>
          {errorMessage}
        </p>
      )}
      <Input
        placeholder="Enter Password"
        id={id || 'outlined-adornment-password'}
        type={showpassword ? 'text' : 'password'}
        style={{
          ...style,
        }}
        value={password}
        onChange={e => setPassword(e.target.value)}
        endAdornment={
          <InputAdornment>
            <IconButton
              size="small"
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}>
              {showpassword ? (
                <VisibilityOff fontSize="small" />
              ) : (
                <Visibility
                  fontSize="small"
                  style={
                    {
                      // color: '#3399FF',
                    }
                  }
                />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
}

const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const InputRoot = styled('div')(
  ({theme}) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  width: 320px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  };
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;


  &.${inputClasses.focused} {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 1px ${
      theme.palette.mode === 'dark' ? blue[600] : blue[200]
    };
  }

  &:hover {
    border-color: ${blue[400]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const InputElement = styled('input')(
  ({theme}) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  flex-grow: 1;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 0px 0px;
  outline: 0;
`,
);

const IconButton = styled(Button)(
  ({theme}) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;
  cursor: pointer;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[700]};
  `,
);

const InputAdornment = styled('div')`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
