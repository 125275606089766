import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth.js';
import sidebarMenus from 'component/sidebar/sidebarMenus.jsx';
import {NavConstant} from './NavConstant.jsx';

const isRouteAllowed = (pathname, sidebarMenus, currentUserRole) => {
  for (const menu of sidebarMenus) {
    for (const subMenu of menu?.subMenus) {
      if (
        subMenu?.navTo === pathname &&
        subMenu?.allowedRoles?.includes(currentUserRole)
      ) {
        return true;
      }
    }
  }
  return false;
};

export const ProtectedRoute = ({children}) => {
  const {user, adminRoleId} = useAuth();
  const location = useLocation();

  const isAllowed = isRouteAllowed(
    location.pathname,
    sidebarMenus,
    adminRoleId,
  );
  if (user == null) {
    return <Navigate to="/" />;
  } else if (location.pathname === '/' && user != null) {
    return <Navigate to={NavConstant.HOME} />;
  }
  // else if(!isAllowed && location.pathname !== NavConstant.SETTINGS)
  // {
  //   return  <Navigate to={NavConstant.NOT_AUTHORIZED} />;
  // }

  return children;
};
