import React, {useCallback, useState, useEffect} from 'react';

export const HeadLabel = ({label}) => {
  return (
    <div
      style={{
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '30px',
        letterSpacing: '0em',
        textAlign: 'left',
      }}>
      {label}
    </div>
  );
};
