import Navbar from 'component/navbar/Navbar';
import Sidebar from 'component/sidebar/Sidebar';
import React from 'react';
import './dashboardlayout.scss';
import {useAuth} from 'hooks/useAuth';
import {useSelector} from 'store/store';

export default function DashboardLayout({children}) {
  const {adminRoleId} = useAuth();
  return (
    <div
      className="main"
      style={{
        // backgroundColor: '#FAF6FF',
        // minHeight: '200vh',
        height: '100%',
      }}>
      <Navbar />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 30,
        }}>
        <div
          style={{
            // backgroundColor: "green",
            width: '266px',
          }}>
          <Sidebar adminRoleId={adminRoleId} />
        </div>

        <div
          style={{
            width: '75%',
            padding: '20px 30px',
            // backgroundColor: "red"
          }}>
          {children}
        </div>
      </div>
    </div>
  );
}
