import fetchHandler from './FetchHandler';

export const countAllApplicationsApi = () => {
    return fetchHandler({
        method: 'get',
        url: 'admin/brief/applications/count',
      });
};


export const downloadAllApplicationsApi = () => {
  return fetchHandler({
      method: 'get',
      url: 'admin/brief/applications/download',
    });
};

export const getApprovedApi = (s,l) => {
  return fetchHandler({
      method: 'get',
      url: 'admin/brief/approved?skip='+s+"&limit="+l,
    });
};


export const downloadAllSubmissionsApi = () => {
  return fetchHandler({
      method: 'get',
      url: 'admin/brief/submissions/download',
    });
};

export const downloadAllPaidShootersApi = () => {
  return fetchHandler({
      method: 'get',
      url: 'admin/brief/paidshooters/download',
    });
};