import {CloudSync, CloudUpload, RestartAlt} from '@mui/icons-material';
import React from 'react';

export const ActionAsset = ({id, current, upload, images}) => {
  if (id !== current?.id) {
    return <CloudUpload />;
  }
  if (id == current?.id && images?.length > 0 && !upload) {
    <RestartAlt />;
  }
  if (id == current?.id && images?.length > 0 && upload) {
    <CloudSync />;
  }
  return (
    <>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.52344 11V3.86875L4.24844 6.14375L3.02344 4.875L7.39844 0.5L11.7734 4.875L10.5484 6.14375L8.27344 3.86875V11H6.52344ZM2.14844 14.5C1.66719 14.5 1.25521 14.3286 0.9125 13.9859C0.569792 13.6432 0.398438 13.2312 0.398438 12.75V10.125H2.14844V12.75H12.6484V10.125H14.3984V12.75C14.3984 13.2312 14.2271 13.6432 13.8844 13.9859C13.5417 14.3286 13.1297 14.5 12.6484 14.5H2.14844Z"
          fill="#7729E5"
        />
      </svg>
    </>
  );
};
