import React, {useEffect, useState} from 'react';
import {NavConstant} from 'routes/NavConstant';
import ViewAllButton from './buttons/ViewAllButton';
import {useLocation} from 'react-router-dom';

export default function PageCopiesCard({
  count,
  label,
  navTo = NavConstant.HOME,
  onClick = () => {},
  selected = '#C6C6C654',
  style = {},
}) {
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '20px',
        height: 200,
        width: 200,
        backgroundColor: '#C6C6C654',
        boxShadow: '1px 2px 3px #00000040',
        // backgroundImage: selected,
        fontFamily: 'Poppins-Medium',
        fontSize: 20,
        cursor: 'pointer',
        // ...style,
      }}>
      <span
        style={{
          fontWeight: 500,
          lineHeight: '21px',
          color: 'black',
          fontFamily: 'Poppins',
          fontSize: 20,
          textAlign: 'center',
          whiteSpace: 'pre-line',
        }}>
        {label}
      </span>
    </div>
  );
}
